import { Card } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { FaExclamationTriangle } from 'react-icons/fa';
import {  Link } from "react-router-dom"
const Summary = () => {
  const visFiles = localStorage.getItem('visaRequiredAttachment');
  const visaCategoryState = useSelector((state) => state.visaCategoryInformation.visaCategoryPayload);
  const arrivalState = useSelector((state) => state.arrivalInformation.arrivalPayload);
  const personalState = useSelector((state) => state.personalInformation.personalPayload);
  const passportState = useSelector((state) => state.passportInformation.passportPayload);

  return (
    <div className='row'>
      <h4>
        <div aria-live="polite" className="p-inline-message p-component p-inline-message-info" role="alert">
          <span className="p-inline-message-icon pi pi-info-circle"></span>
          <span>If you haven't received information about your visa status, feel free to revisit this website to check your visa status and download your visa documents.
            <Link to="/status" target="_blank"  rel="noreferrer"  style={{ textDecoration: 'underline' }}>Link to check your visa status.</Link>
          </span>
        </div>
      </h4>
      {/* <div>
      <h5>
        <FaExclamationTriangle style={{ color: 'red', marginRight: '8px' }} />
        <span style={{ color: 'red' }}>
          We are temporarily unable to accept MasterCard due to technical issues.
        </span>
      </h5>
    </div> */}
      <div className='col-md-6'>

        <Card title="Personal Information">
          <p><b>Given Name</b>: {personalState && (personalState.firstName)}</p>
          <p><b>Surname</b>: {personalState && (personalState.surname)}</p>
          <p><b>Citizenship</b>: {personalState && (personalState.citizenship?.name)}</p>
          <p><b>Gender</b>: {personalState && String(personalState.gender).charAt(0).toUpperCase() + String(personalState.gender).slice(1)}</p>
          <p><b>Country Of Birth</b>: {personalState && (personalState.countryOfBirth.name)} </p>
          <p><b>Date Of Birth</b>: {personalState && (personalState.dateOfBirth)}</p>
          <p><b>Place Of Birth</b>: {personalState && (personalState.placeOfBirth)}</p>
          <p><b>Email Address</b>: {personalState && (personalState.emailAddress)}</p>
          <p><b>Phone Number</b>: {personalState && (personalState.phoneNumber)}</p>
          <p><b>Occupation</b>: {personalState && (personalState.occupation)}</p>
          <p><b>Street Address</b>: {personalState && (personalState.streetAddress)}</p>
          <p><b>Address City</b>: {personalState && (personalState.addressCity)}</p>
          <p><b>Address Country</b>: {personalState && (personalState.addressCountry.name)} </p>
        </Card>
        <br />
        <Card title="Passport Information">
          <p><b>Passport Type</b>: {passportState && (passportState.passportType.name)} </p>
          <p><b>Passport Number</b>: {passportState && (passportState.passportNumber)}</p>
          <p><b>Passport Issue Date</b>: {passportState && (passportState.passportIssueDate)}</p>
          <p><b>Passport Expire Date</b>: {passportState && (passportState.passportExpireDate)}</p>
          <p><b>Passport Issuing Country</b>: {passportState && (passportState.issuingCountry.name)}</p>
          <p><b>Passport Issuing Authority</b>: {passportState && (passportState.passportIssuingAuthority)}</p>
        </Card>
      </div>

      <div className='col-md-6'>
        <Card title="Visa Category" >
          <p><b>Visa Type</b>: {visaCategoryState && (visaCategoryState.visaType?.name)} </p>
          <p><b>Visa Validity</b>: {visaCategoryState && (visaCategoryState.visaValidity?.name)}</p>
        </Card>
        <br />
        <Card title="Arrival Information">
          <p><b>Arrival Date</b>: {arrivalState && (arrivalState?.arrivalDate)} </p>
          <p><b>Departure Country</b>: {arrivalState && (arrivalState.departureCountry?.name)}</p>
          <p><b>Departure City</b>: {arrivalState && (arrivalState?.departureCity)}</p>
          <p><b>Airline</b>: {arrivalState && (arrivalState?.airline)}</p>
          <p><b>Flight Number</b>: {arrivalState && (arrivalState?.flightNumber)}</p>
          <p><b>Accommodation Type</b>: {arrivalState && (arrivalState.accommodationType?.name)}</p>
          <p><b>Accommodation Name</b>: {arrivalState && (arrivalState?.accommodationName)}</p>
          <p><b>Accommodation City</b>: {arrivalState && (arrivalState?.accommodationCity)}</p>
          <p><b>Accommodation Street Address</b>: {arrivalState && (arrivalState?.accommodationStreetAddress)}</p>
          <p><b>Accommodation Telephone</b>: {arrivalState && (arrivalState?.accommodationTelephone)}</p>
        </Card>
      </div>
    </div>
  );
};

export default Summary;