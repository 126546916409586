import axiosInstance from "../../utils/axios";
class HttpService {

  static postService = (requestData, relativePath,clientClaim) => {
    return axiosInstance.post(relativePath, requestData,{ headers: { clientClaim: clientClaim, idToken: localStorage.getItem("idToken") } });
  };
  static putService = (payload, relativePath,clientClaim) => {
    return axiosInstance.put(relativePath, payload,{ headers: { clientClaim: clientClaim, idToken: localStorage.getItem("idToken") } });
  };
  static activateDeactivateService = (activateDeactivateBody, relativePath, queryString = "",clientClaim) => {
     ;
    return axiosInstance.put(relativePath + queryString, activateDeactivateBody,{ headers: { clientClaim: clientClaim, idToken: localStorage.getItem("idToken") } });
  };
  static getService = (relativePath,clientClaim ) => {
    return axiosInstance.get(   relativePath, { headers: { clientClaim: clientClaim, idToken: localStorage.getItem("idToken") } });
  };
  static deleteService = (relativePath, queryString = "",clientClaim) => {
    return axiosInstance.delete(relativePath, { params: queryString, headers: { clientClaim: clientClaim, idToken: localStorage.getItem("idToken") } });
  };
}

export default HttpService;
