import React from "react";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import "./evisaStyle.css";
import { Link } from "react-router-dom";
export default function FBusinessVisa() {
  return (
    <div className="text-left">
      <h4 className="evisa-title">
        Foreign Business Firm Employment Visa - WV
      </h4>
      <div className="divider"></div>
      <p className="text-left">
        Foreign Business Firm Employment Visa is issued to foreign nationals who
        are entering Ethiopia to be employed by business firms owned/operated by
        foreigners.
      </p>
      <h5 className="evisa-title">Requirements</h5>
      <ul>
        <li>Recent passport-size photo of the applicant.</li>

        <li>
          Passport of the visa applicant which is valid for at least 6 months
          from the intended date of entry to Ethiopia.
        </li>

        <li>
          A letter of support requesting services from the organization he was
          invited to that has not expired more than one week ago.
        </li>
        <li>
          A letter of support from the minister of labor and social affairs
          stating that he has granted a work permit
        </li>
        <li>Business license of the inviting company.</li>
        <li>
          A copy of the WV foreign investor employee visa that was recently
          extended and the last extension if applicable
        </li>
        <li>
          TIN(Taxpayer Identification Number) Certificate of the inviting
          company.
        </li>
      </ul>
      <h5 className="evisa-title">Processing Time</h5>
      <p className="text-left">
        Under normal circumstances, the processing time for{" "}
        <em>Foreign Business Firm Employment Visa - WV</em> is 3 days. So,
        travelers should submit their application at least 3 days before their
        estimated date of arrival.
      </p>
      <h5 className="evisa-title">Duration and Validity</h5>
      <p className="text-left">
        The Validity of the Visa starts counting from the date intended to enter
        Ethiopia.
      </p>
      <h5 className="evisa-title">Fee</h5>
      <div className="striped bordered text-center">
        <Table bordered responsive size="md">
          <thead className="evisa-color">
            <tr>
              <th>Entry Type</th>
              <th>Maximum Duration</th>
              <th>Application Visa Fee (in USD)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Single Entry</td>
              <td>30 Days</td>
              <td>402</td>
            </tr>
            <tr>
              <td>Multiple Entry</td>
              <td>90 Days</td>
              <td>1002</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <h5 className="evisa-title">Extension</h5>
      <p className="text-left">
        Travelers can request for extension before the visa expires in which
        expired date 1-15 days extend by online and visa expired date above 15
        extended by going in person to the Head Office of the Immigration And
        Citizenship Service (Addis Ababa, Ethiopia). Travelers who stay beyond
        the validity period without extending are subject to fines and legal
        penalties.
      </p>
      <div className="striped bordered text-center">
        <Table bordered responsive size="md">
          <thead className="evisa-color">
            <tr>
              <th>Entry Type</th>
              <th>Maximum Duration</th>
              <th>Extension Visa Fee (in USD)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Single Entry</td>
              <td>30 Days</td>
              <td>502</td>
            </tr>
            <tr>
              <td>Multiple Entry</td>
              <td>90 Days</td>
              <td>1502</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <Link to="/visa">
        <Button className="mt-5 evisa-button">
          Apply for Foreign Business Firm Employment Visa now
        </Button>
      </Link>
    </div>
  );
}
