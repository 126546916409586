import React from 'react';

const PaymentCancel = () => {
    return (
        <div>
            <br/>
<div class="containers">

    <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-4 paymentstatusbody">
            <div class="login-box-body text-Color">
                <br />
                <div class="paymentcancel">
                    <br />
                    <center><span class="text-white fa fa-remove fa-3x"></span></center>
                    <center>
                        <h3 class="text-white">Payment Order canceled</h3>
                    </center>
                </div>

                <div class="form-group">
                    <h6>
                        <br /> Your payment has been cancelled. <br /><br />Note: your e-Visa request will not be processed until you make payment successfully.
                    </h6>
                </div>
            </div>
        </div>
        <div class="col-md-4"></div>
    </div>


</div><br/><br/>
        </div>
    );
};

export default PaymentCancel;