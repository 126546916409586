import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import CompanyService from "../../../service/company/CompanyService"
import { useState } from "react"
import SnipperModal from "../../common/modal/snipperModal"
import { useEffect } from "react"

export default function MyApplications({ setTitle }) {
    setTitle("List of Applications")
    localStorage.setItem("selectedmenu", 3)

    const id = JSON.parse(localStorage.getItem("userLoginRes"))?.id
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const companyService = new CompanyService();

    useEffect(() => {
        companyService.getCompanyApplicationList()
            .then((res) => {
                setData(res.data?.payload?.companyApplications)
            }).catch((error) => {
                // console.log(error)
            }).finally(() => {
                setLoading(false);
            })
    }, [])


    const statusBody = (rowdata) => {
        // console.log(rowdata?.approvalStatus)
        let status;
        switch (rowdata?.approvalStatus) {
            case 1:
                status = "New"
                break;
            case 2:
                status = "Approved"
                break;
            case 3:
                status = "Incomplete"
                break;
            case 4:
                status = "Denied"
                break;
                case 5:
                    status = "Replied"
                    break;
            default:
                break;
        }
        return <span style={{ backgroundColor: "#C8E6C9", color: "#256029", padding: "0.25em 0.5rem", borderRadius: "2px", fontWeight: 700, fontSize: "12px", letterSpacing: ".3px" }} >{String(status).toUpperCase()}</span>
    }
    const registedDate = (rowdata) => {
        return <> {rowdata?.registeredDate?.split("T")[0]}</>
    }

    return (
        <div>
            {loading ? <SnipperModal /> : <> {null}</>}
            <div className="card">
                <DataTable value={data} paginator className="p-datatable-customers" rows={5}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[5, 10, 15]}
                    dataKey="id" rowHover responsiveLayout="stack"
                    globalFilterFields={['name', 'country.name', 'representative.name', 'balance', 'status']} emptyMessage="No applications found."
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} applications">

                    <Column field="approvalStatus" header="Approval Status" body={statusBody} sortable style={{ minWidth: '14rem' }} />
                    <Column field="companyReferenceCode" header="Company Reference Code" sortable style={{ minWidth: '14rem' }} />
                    <Column field="applicationNumber" header="Application Number" sortable filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '10rem' }} />
                    <Column field="registeredDate" header="Registered Date" body={registedDate} sortable showFilterMatchModes={false} style={{ minWidth: '10rem' }} />
                </DataTable>
            </div>
        </div>
    )
}