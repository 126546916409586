import React from 'react';
import ReactDOM from 'react-dom/client';
import './../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './index.css';
import { Provider } from "react-redux";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import App from './App';
import Store from './redux/Store';
import './../node_modules/bootstrap/dist/js/bootstrap.js'
import { ErrorBoundary } from './components/common/ErrorBoundary';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={Store}>
        <App />
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
