import React from "react";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import "./evisaStyle.css";
import { Link } from "react-router-dom";
export default function GIVisa() {
  return (
    <div className="text-left">
      <h4 className="evisa-title">
        Ethiopian Government Institutions Short Task Visa - GIV
      </h4>
      <div className="divider"></div>
      <p className="text-left">
        Government Institutions Short Task Visa is issued to foreign nationals
        who wish to enter Ethiopia to accomplish certain tasks for government
        institutions for a short period of time.
      </p>
      <h5 className="evisa-title">Requirements</h5>
      <p className="text-left">Copy of the following documents is mandatory.</p>
      <ul>
        <li>Recent passport-size photo of the applicant.</li>

        <li>
          Passport of the visa applicant which is valid for at least 6 months
          from the intended date of entry to Ethiopia.
        </li>
        <li>
          A letter of support requesting services from government offices or
          institutions that has not expired more than one week ago.
        </li>
        <li>
          A copy of the GIV foreign investor employee visa that was recently
          extended and the last extension if applicable
        </li>
      </ul>
      <h5 className="evisa-title">Processing Time</h5>
      <p className="text-left">
        Under normal circumstances, the processing time for{" "}
        <em>Government Institutions Short Task Visa - GIV</em> is 3 days. So,
        travelers should submit their application at least 3 days before their
        estimated date of arrival
      </p>
      <h5 className="evisa-title">Duration and Validity</h5>
      <p>
        The Validity of the Visa starts counting from the date intended to enter
        Ethiopia.
      </p>
      <h5 className="evisa-title">Fee</h5>
      <div className="striped bordered text-center">
        <Table bordered>
          <thead className="evisa-color">
            <tr>
              <th>Entry Type</th>
              <th>Maximum Duration</th>
              <th>Application Visa Fee (in USD)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Single Entry</td>
              <td>30 Days</td>
              <td>102</td>
            </tr>
            <tr>
              <td>Multiple Entry</td>
              <td>90 Days</td>
              <td>152</td>
            </tr>
            <tr>
              <td>Multiple Entry</td>
              <td>6 month</td>
              <td>402</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <h5 className="evisa-title">Extension</h5>
      <p className="text-left">
        Travelers can request for extension before the visa expires in which
        expired date 1-15 days extend by online and visa expired date above 15
        extended by going in person to the Head Office of the Immigration And
        Citizenship Service (Addis Ababa, Ethiopia). Travelers who stay beyond
        the validity period without extending are subject to fines and legal
        penalties.
      </p>
      <div className="striped bordered text-center">
        <Table bordered>
          <thead className="evisa-color">
            <tr>
              <th>Entry Type</th>
              <th>Maximum Duration</th>
              <th>Extension Visa Fee (in USD)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Single Entry</td>
              <td>30 Days</td>
              <td>152</td>
            </tr>

            <tr>
              <td>Multiple Entry</td>
              <td>90 Days</td>
              <td>252</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <Link to="/visa">
        <Button className="mt-5 evisa-button">
          Apply for Government Institutions Short Task Visa now
        </Button>
      </Link>
    </div>
  );
}
