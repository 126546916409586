import React from "react";
import  "./evisaStyle.css"
export default function Requirement() {
  return (
    <div >
      <div>
        <h1 className="evisa-title">Welcome to Ethiopian e-Visa</h1>

        <h2 className="evisa-title">
          <span>THINGS YOU SHOULD KNOW ABOUT ETHIOPIAN e-VISA</span>
        </h2>
        <br></br>
        <br></br>
        <p  className="text-left">Please check the requirements listed below for your e-VISA for Ethiopia</p>
        <ul  className="text-left">
          <li>
          An e-visa is a formal document that allows one to enter Ethiopia and travel around it.
          </li>
          <li>
          You should carefully read and abide by the important notices and instructions at each stage of the application process. 
          </li>
      
          <li>
          Ethiopian e-visas are only valid as of the applicant's intended arrival date specified on the application, not as of the date of issuance. 
          </li>
     
      
          <li>
          You must have at least six months left on your passport after the date you plan to enter Ethiopia. 
          </li>
        
      
          <li>Application fees for e-Visas are not refundable. </li>
     
       
          <li>
          A scanned color copy of the passport and a color passport-sized photo are required.
          </li>
          <li>Applicants who intend to visit for business, joint ventures, or related activities should only apply for the Business and Related Studies Visa (BRV). </li>
          <li>Individuals who hold multiple nationalities ought to select their citizenship based on the passport they intend to use for travel. </li>
          <li>Note that a business visa is not issued to individuals under the age of 18. Parents who come to Ethiopia with business visas along with their children can apply for tourist visas for their children. </li>
          <li>As in the case of other visas, respective Ethiopian officials at the ports of entry reserve the right to deny entry into Ethiopia to a holder of an e-Visa without any explanation.</li>
          <li>Applicants and holders of tourist visas should not be found at any organization in Ethiopia. </li>
          <li>Holders with expired residence IDs (RIDs) should only apply for the respective business visas they applied for before. (NGO Work Visa - NV, Government Institutions Short Task Visa - GIV, Foreign Business Firm Employment Visa – WV)</li>
          <li>Applicants for business visas should first be sure that the organization or company has registered and approved a reference code related to each type on this portal. </li>
          <li>Please note that we do not accept credit card payment transactions from applicants originating from the below countries effective November 18, 2020.</li>
          <p><strong>Cuba, Iraq, Sudan, Yemen, Somalia, North Korea , Afghanistan, South Sudan ,The crimea region of Ukraine,Syrian and Arab Republic</strong></p>
        </ul>
      </div>
      <div>
      <br></br>
        <h4>
          <span class="fa fa-warning text-warning"></span>
          <strong>Important Notice!</strong>
        </h4>
        <p  className="text-left">
          We have come to know that there are some fraud websites which have
          similar look and feel with our official{" "}
          <a class="text-primary" href="/">
            e-Visa website.{" "}
          </a>{" "}
          Please note that the Ethiopian Government doesn't have any agreement
          with the owners of those websites. Any visa application submitted or
          payment made through these websites is not acceptable. Please do not
          submit visa application on{" "}
          <a
            href="http://www.ethiopiaevisa.com"
            class="text-primary"target="_blank" rel="noreferrer"
          >
            www.ethiopiaevisa.com ,
          </a>{" "}
          <a
            data-sf-ec-immutable=""
            href="https://www.ethiopiaimmigration.org/"
            class="text-primary" target="_blank" rel="noreferrer"
          >
            www.ethiopiaimmigration.org ,
          </a>
          <a
            data-sf-ec-immutable=""
            href="http://www.ethiopiaonlinevisa.com"
            class="text-primary" target="_blank" rel="noreferrer"
          >
            www.ethiopiaonlinevisa.com ,
          </a>{" "}
          <a
            data-sf-ec-immutable=""
            href="http://www.evisaforethiopia.com"
            class="text-primary" target="_blank" rel="noreferrer"
          >
            www.evisaforethiopia.com,
          </a>{" "}
          <a
            data-sf-ec-immutable=""
            href="https://ethiopian-visa.com/"
            class="text-primary" target="_blank" rel="noreferrer"
          >
            www.ethiopian-visa.com,
          </a>{" "}
          <a
            data-sf-ec-immutable=""
            href="https://www.ethiopia-evisa.com"
            class="text-primary" target="_blank" rel="noreferrer"
          >
            www.ethiopia-evisa.com
          </a>{" "}
          or others. The Government of Ethiopia is not responsible for any
          payments made or for the inconveniences this might create on the
          applicants.
        </p>
      </div>
    </div>
  );
}
