import React, { useRef, useState } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Controller, useForm } from "react-hook-form";
import { Divider } from "primereact/divider";
import { Password } from "primereact/password";
import { InputText } from "primereact/inputtext";
import CompanyService from "../../../service/company/CompanyService";
import { useEffect } from "react";

export default function ChangePassword({ setTitle }) {
    setTitle("Change Password")
    localStorage.setItem("selectedmenu", 4)

    const username = JSON.parse(localStorage.userLoginRes)?.username;
    const companySerivce = new CompanyService();
    const toast = useRef(null);
    const [performingActions, setPerformingActions] = useState(false);
    const [isMobile, setIsMobile] = useState(true);

    const forgotPasswordObj = {
        Username: username,
        OldPassword: "",
        NewPassword: "",
        repeatnewpassword: "",
    };

    const { control, getValues, formState: { errors }, handleSubmit, reset } = useForm({
        defaultValues: { ...forgotPasswordObj }
    })

    const validateConfirmPassword = () => {
        return getValues("NewPassword") === getValues("repeatnewpassword")
    }

    const handlePasswordValidation = password => {
        return /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})./.test(
            password
        )
    }

    const submitChangePassword = (data) => {
        setPerformingActions(true);
        // console.log(data)
        companySerivce.changeCompanyPassword(data)
            .then((res) => {
                reset();
                toast.current.show({ severity: 'success', summary: 'Successfull', detail: 'Successfully changed password.', life: 4000 });
            }).catch((err) => {
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: "Error while updating password", life: 3000 });
            }).finally(() => {
                setPerformingActions(false);
            });
    }

    const passwordHeader = <h6>Pick a strong password</h6>;
    const passwordFooter = (
        <React.Fragment>
            <Divider />
            <p className="mt-2">Suggestions</p>
            <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: '1.5' }}>
                <li>At least one lowercase</li>
                <li>At least one uppercase</li>
                <li>At least one numeric</li>
                <li>Minimum 8 characters</li>
            </ul>
        </React.Fragment>
    );

    useEffect(() => {
        setIsMobile((window.innerWidth < 500) ? true : false);
    }, [window.innerWidth])


    return (<div>
        <Toast ref={toast} />
        <div className="grid">
            <div className={isMobile ? "col-12 p-1 card" : "col-12 p-5 card"}>
                <div className={isMobile ? "grid" : "grid p-4"}>
                    <h4 className="col-12">Change password</h4>
                    <Divider />
                    <div className="p-fluid">
                        <div className={isMobile ? "col-12 field" : "col-4 field"}>
                            <label htmlFor="oldPassword">Old Password</label>
                            <Controller
                                name="OldPassword"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) =>
                                    <InputText value={field.value} type="password" id="oldPassword" placeholder="********" onChange={field.onChange} header={passwordHeader} footer={passwordFooter} toggleMask />
                                }
                            />
                            {errors.OldPassword?.type === 'required' && <small className="p-error">Old password is required.</small>}
                        </div>
                        <div className={isMobile ? "col-12 field mt-3" : "col-4 field mt-3"}>
                            <label htmlFor="newPassword">New Password</label>
                            <Controller
                                name="NewPassword"
                                control={control}
                                rules={{ required: true, minLength: 8, validate: handlePasswordValidation }}
                                render={({ field }) =>
                                    <InputText value={field.value} type="password" id="newPassword" placeholder="********" onChange={field.onChange} header={passwordHeader} footer={passwordFooter} toggleMask />
                                }
                            />
                            {errors.NewPassword?.type === 'required' && <small className="p-error">New password is required.</small>}
                            {errors.NewPassword?.type === 'validate' && <small className="p-error">Weak password is not allowed.</small>}
                            {errors.NewPassword?.type === 'minLength' && <small className="p-error">Password should be minimum of length 8.</small>}
                        </div>
                        <div className={isMobile ? "col-12 field mt-3" : "col-4 field mt-3"} >
                            <label htmlFor="repeatnewpassword">Confirm Password</label>
                            <Controller
                                name="repeatnewpassword"
                                control={control}
                                rules={{ required: true, validate: validateConfirmPassword }}
                                render={({ field }) =>
                                    <InputText value={field.value} type="password" id="repeatnewpassword" placeholder="********" onChange={field.onChange} header={passwordHeader} footer={passwordFooter} toggleMask />
                                }
                            />
                            {errors.repeatnewpassword?.type === 'required' && <small className="p-error">Confirm password is required.</small>}
                            {errors.repeatnewpassword?.type === 'validate' && <small className="p-error">Password didn't match.</small>}
                        </div>
                    </div>

                    <div className={isMobile ? "col-12 text-center field mt-3" : "col-4 field mt-3"} >
                        {performingActions ? <Button label="Change Password" icon="pi pi-spin pi-spinner" disabled /> :
                            <Button label="Change Password" onClick={handleSubmit(submitChangePassword)} icon="pi pi-check" />}
                    </div>
                </div>
            </div>
            <div className="col-3"></div>
        </div>
    </div>)
}