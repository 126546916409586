import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";

import { FaExclamationTriangle } from 'react-icons/fa';
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import "./visaCategory.css";
import VisaService from "../../../service/visa/VisaService";
import { useDispatch, useSelector } from "react-redux";
import { setVisaCategory } from "../../../redux/actions/VisaActions";
import SnipperModal from "../../common/modal/snipperModal";
import { json, Link } from "react-router-dom";
import CAPTCHA from "react-google-recaptcha";

const VisaCategory = forwardRef((props, ref) => {
  const [dataSaved, setDataSaved] = useState(false);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [isVisaBusiness, setIsVisaBusiness] = useState(false);
  const [isVisaTypeSelected, setIsVisaTypeSelected] = useState(false);
  const [visaValidities, setVisaValidities] = useState([]);
  const [visaTypes, setVisaTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const captchaRef = useRef(null);
  const [recaptchaSelected, setRecaptchaSelected] = useState(true);

  const [visaCategoryInput, setVisaCategoryInput] = useState({
    visaType: null,
    visaValidity: null,
    companyReferenceNumber: "",
    dataSaved: false,
    formCompleted: false,
  });
  const [notCompleted, setNotCompleted] = useState({
    visaType: true,
    visaValidity: true,
    companyReferenceNumber: true,
  });
  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    saveData() {
      setDataSaved(true);
      setVisaCategoryInput((prevState) => ({
        ...prevState,
        dataSaved: true,
      }));
      dispatch(setVisaCategory(visaCategoryInput));
    },
    Validate() {
      // const token = captchaRef.current.getValue();
      // if (token === "") {
      //   setRecaptchaSelected(false);
      //   return false;
      // } else setRecaptchaSelected(true);

      if (
        notCompleted.visaType === true ||
        notCompleted.visaValidity === true ||
        !termsAgreed ||
        (isVisaBusiness && notCompleted.companyReferenceNumber === true)
      )
        return false;
      else return true;
    },
  }));
  const isRequired = "is required!";
  useEffect(() => {
    let mData= localStorage.getItem("mDataObgj");
    const visaDataService = new VisaService();
    visaDataService
      .getBulkMaster()
      .then((res) => {
        localStorage.setItem("mDataObgj", JSON.stringify(res.data));
        if(!mData){
          window.location.reload();
        }
        setVisaTypes(res?.data?.visaTypes);
      })
      .catch((error) => {
      });
    setNotCompleted({
      visaType: visaCategoryInput.visaType === null ? true : false,
      visaValidity: visaCategoryInput.visaValidity === null ? true : false,
      companyReferenceNumber:
        visaCategoryInput.companyReferenceNumber === "" ? true : false,
    });
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setVisaCategoryInput((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === "visaType") {
      if (value != null) {
        setIsVisaBusiness(value.isBusiness);
        setVisaValidities(value.visaValidities);
        setIsVisaTypeSelected(true);
        setNotCompleted({
          visaValidity: true,
          companyReferenceNumber: true,
        });
      }
    }
    if (value !== "") {
      setNotCompleted((prevState) => ({
        ...prevState,

        [name]: false,
      }));
    } else {
      setNotCompleted((prevState) => ({
        ...prevState,

        [name]: true,
      }));
    }
  };
  const handlecheck = (event) => {
    setTermsAgreed(!termsAgreed);
  };

  return (
    <>
      {loading ? <SnipperModal /> : <> {null}</>}

      <div className="flex justify-content-center">
        <h4>
          <div aria-live="polite" className="p-inline-message p-component p-inline-message-info" role="alert">
            <span className="p-inline-message-icon pi pi-info-circle"></span>
            <span>If you haven't received information about your visa status, feel free to revisit this website to check your visa status and download your visa documents.
              <Link to="/status" target="_blank" rel="noreferrer" style={{ textDecoration: 'underline' }}>Link to check your visa status. </Link> 
              
            </span>
            
          </div>
         
        </h4>
        {/* <div>
      <h5>
        <FaExclamationTriangle style={{ color: 'red', marginRight: '8px' }} />
        <span style={{ color: 'red' }}>
          We are temporarily unable to accept MasterCard due to technical issues.
        </span>
      </h5>
    </div> */}
        <div className="grid">
          <div className="col-12">
            <div className="card p-5 pb-2">
              <form className="p-fluid row mb-3">
                <h5 className="align-left">VISA SELECTION</h5>
                <hr />
                <div className="field col-md-4 mt-4">
                  <span className="p-float-label">
                    <Dropdown
                      name="visaType"
                      value={visaCategoryInput.visaType}
                      onChange={handleChange}
                      options={visaTypes}
                      optionLabel="name"
                      className="p-inputtext-sm block"
                    />
                    <label htmlFor="country">
                      Visa Type<span className="text-danger">*</span>
                    </label>
                  </span>
                  <span style={{ color: "red" }}>
                    {" "}
                    {notCompleted.visaType === true &&
                    visaCategoryInput.dataSaved === true
                      ? "Visa Type " + isRequired
                      : null}
                  </span>{" "}
                </div>

                {isVisaTypeSelected ? (
                  <div className="field col-md-4 mt-4">
                    <span className="p-float-label">
                      <Dropdown
                        name="visaValidity"
                        onChange={handleChange}
                        options={visaValidities}
                        value={visaCategoryInput.visaValidity}
                        optionLabel="name"
                        className="p-inputtext-sm block"
                      />
                      <label htmlFor="country" className="align-left">
                        Visa Validity<span className="text-danger">*</span>
                      </label>
                    </span>
                    <span style={{ color: "red" }}>
                      {" "}
                      {notCompleted.visaValidity === true &&
                      visaCategoryInput.dataSaved === true
                        ? "Visa Validity " + isRequired
                        : null}
                    </span>{" "}
                    {!notCompleted.visaValidity ? (
                      <h5
                        className="align-left text-success"
                        style={{
                          marginBottom: "-40px!important",
                          marginLeft: "2px!important",
                        }}
                      >
                        <br />
                        Visa Fee : ${visaCategoryInput?.visaValidity?.totalFee}
                      </h5>
                    ) : null}
                  </div>
                ) : null}

                {isVisaBusiness ? (
                  <div className="field col-md-4">
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title="Company Reference number is a reference given to the visa applicant by the sponsor company only, please do not try another reference!."
                      style={{
                        backgroundColor: "white",
                        borderRadious: "10px",
                      }}
                    >
                      {" "}
                      <i
                        className="pi pi-info-circle text-primary"
                        style={{ fontSize: "1.1em" }}
                      ></i>
                    </span>
                    <span className="p-float-label">
                      <InputText
                        onBlur={handleChange}
                        name="companyReferenceNumber"
                        className="p-inputtext-sm block"
                        id="inputtext"
                      />
                      <label htmlFor="country" style={{ marginLeft: "12px" }}>
                        Company Reference Number
                        <span className="text-danger">*</span>
                      </label>
                    </span>
                    <span style={{ color: "red" }}>
                      {" "}
                      {notCompleted.companyReferenceNumber === true &&
                      visaCategoryInput.dataSaved === true
                        ? " Company Reference Number " + isRequired
                        : null}
                    </span>{" "}
                  </div>
                ) : null}
                {/* <div className="my-1">
                  <CAPTCHA
                    sitekey={window["env"]["EVISA_GOOGLERC_SITE_KEY"]}
                    ref={captchaRef}
                  />{" "}
                  {recaptchaSelected ? null : (
                    <span className="text-danger">
                      Please select the recaptcha!
                    </span>
                  )}
                </div> */}
                <div
                  className="field-checkbox mt-5 col-md-12"
                  onClick={handlecheck}
                >
                  <Checkbox name="accept" checked={termsAgreed} />
                  &nbsp;
                  <label htmlFor="accept" className="mr-2">
                    I agree to the
                  </label>
                  <Link to="/termsandconditions" target="_blank">
                    {" "}
                    terms and conditions*
                  </Link>
                  <br />
                  <span className="text text-danger">
                    {dataSaved && !termsAgreed
                      ? "Please agree terms and conditions"
                      : null}
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
export default VisaCategory;
