import React from "react";
import Button from "react-bootstrap/Button";
import "./evisaStyle.css";
import { Link } from "react-router-dom";
export default function JournalistVisa() {
  return (
    <div className="text-left">
      <h4 className="evisa-title">Journalist Visa - JV</h4>
      <div className="divider"></div>
      <p className="text-left">
        Journalist visa is issued to foreign Journalists, media professionals or
        film makers, coming to cover events, report for media organizations or
        produce films, TV or written material of various kinds.
      </p>
      <h5 className="evisa-title">Requirements</h5>
      <p className="text-left">Copy of the following documents is mandatory.</p>
      <ul>
        <li>Recent passport-size photo of the applicant.</li>
        <li>
          Passport of the visa applicant which is valid for at least 6 months
          from the intended date of entry to Ethiopia.
        </li>
        <li>
          A letter of support not expired more than one week from the media
          institution of the country to which the person was invited
        </li>
      </ul>
      <h5 className="evisa-title">Processing Time</h5>
      <p className="text-left">
        Under normal circumstances, the processing time for{" "}
        <em>Journalist Visa - JV</em> is 3 days. So, travelers should submit
        their application at least 3 days before their estimated date of arrival
      </p>
      <h5 className="evisa-title">Duration and Validity</h5>
      <p className="text-left">
        The Validity of the Visa starts counting from the date intended to enter
        Ethiopia.
      </p>
      <h5 className="evisa-title">Fee</h5>
      <p className="text-left">
        For single-entry 30-days Journalist Visa, the application fee is 152
        USD.
      </p>
      <h5 className="evisa-title">Extension</h5>
      <p className="text-left">Journalist visa can't be extended.</p>

      <Link to="/visa">
        <Button className="mt-5 evisa-button">Apply for Journalist Now</Button>
      </Link>
    </div>
  );
}
