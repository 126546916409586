import axios from "axios";
import HttpService from "../shared/HttpService";


export default class VisaService {

    getVisaTypes = (id) => {

        let relativePath = "visaservice/api/v1/VisaType/GetAll?recordStatus=" + id;
        return HttpService.getService(relativePath,"VisaType-GetAll")
    }
    getCountries = () => {
        let relativePath = "masterdataservice/api/v1/Country/GetAll?status=2";
        return HttpService.getService(relativePath,"Country-GetAll")
    }
    getBulkMaster = () => {
        let relativePath = "masterdataservice/api/v1/BulkMasterData/ApplyVisaBulkData";
        return HttpService.getService(relativePath,"BulkMasterData-ApplyVisaBulkData")
    }
    getNationalities = () => {

        let relativePath = "masterdataservice/api/v1/Nationality/GetAll?status=2";
        return HttpService.getService(relativePath,"Nationality-GetAll")
    }
    getPassportTypes = () => {

        let relativePath = "masterdataservice/api/v1/PassportType/GetAll?status=2";
        return HttpService.getService(relativePath,"PassportType-GetAll")
    }
    getVisaValidity = () => {

        let relativePath = "visaservice/api/v1/VisaValidity/GetAll?recordStatus=2";
        return HttpService.getService(relativePath,"VisaValidity-GetAll")
    }
    getAccomodationType = () => {

        let relativePath = "masterdataservice/api/v1/AccommodationType/GetAll?status=2";
        return HttpService.getService(relativePath,"AccommodationType-GetAll")
    }

    getVisaRequiredAttachment = (visaTypeId) => {

        let relativePath = "visaservice/api/v1/RequiredAttachement/GetByVisaType?visaTypeId=" + visaTypeId + "&visaMode=0";
        return HttpService.getService(relativePath,"RequiredAttachement-GetByVisaType")
    }
    applyForVisa = (payload) => {
        let relativePath = "visaservice/api/v1/VisaRequest/Apply";
        return HttpService.postService(payload, relativePath,"VisaRequest-Apply")
    }
    createPayment = (paymentPayload) => {

        let relativePath = "paymentservice/api/v1/PaymentRequests/RequestOrder";
        return HttpService.postService(paymentPayload, relativePath,"PaymentRequests-RequestOrder")
    }
    getVisa = (visaPayload) => {

        let relativePath = "visaservice/api/v1/VisaExtensionRequest/GetVisaDetailForExtension?referenceNumber=" + visaPayload.referenceNumber + "&passportNumber=" + visaPayload.passportNumber;
        return HttpService.getService(relativePath,"VisaExtensionRequest-GetVisaDetailForExtension")
    }
    getVisaForStatus = (visaPayload) => {
        let relativePath = "visaservice/api/v1/VisaRequest/GetVisaRequest?passportNumber=" + visaPayload.passportNumber + "&referenceNumber=" + visaPayload.referenceNumber;
        return HttpService.getService(relativePath,"VisaRequest-GetVisaRequest")
    }
    getPaymentDetail = (visaPayload) => {
        let relativePath = "visaservice/api/v1/VisaRequest/GetPendingVisaRequest?passportNumber=" + visaPayload.passportNumber + "&referenceNumber=" + visaPayload.referenceNumber;
        return HttpService.getService(relativePath,"VisaRequest-GetPendingVisaRequest")
    }
    downloadVisa = (visaPayload) => {
        let relativePath = "visaservice/api/v1/VisaRequest/DownloadVisa?passportNumber=" + visaPayload.passportNumber + "&referenceNumber=" + visaPayload.referenceNumber;
        return HttpService.getService(relativePath,"VisaRequest-DownloadVisa")
    }
    createVisaExtension = (payload) => {
        let relativePath = "visaservice/api/v1/VisaExtensionRequest/Apply";
        return HttpService.postService(payload, relativePath,"VisaExtensionRequest-Apply")
    }
    getVisaDetailForUpdate = (payload) => {
        let relativePath = "visaservice/api/v1/VisaRequest/GetDetailForUpdate?passportNumber=" + payload.passportNumber + "&referenceNumber=" + payload.referenceNumber + "&token=" + payload.token;
        return HttpService.getService(relativePath,"VisaRequest-GetDetailForUpdate")
    }
    getExtensionDetailForUpdate = (payload) => {
        let relativePath = "visaservice/api/v1/VisaExtensionRequest/GetDetailForUpdate?referenceNumber=" + payload.referenceNumber + "&token=" + payload.token;
        return HttpService.getService(relativePath,"VisaExtensionRequest-GetDetailForUpdate")
    }
    updateVisaRequiredAttachment = (payload, visaId) => {
        let relativePath = "visaservice/api/v1/VisaRequest/UpdateByApplicant?id=" + visaId;
        return HttpService.putService(payload, relativePath,"VisaRequest-UpdateByApplicant")
    }
    updateExtensionRequiredAttachment = (payload) => {
        let relativePath = "visaservice/api/v1/VisaExtensionRequest/UpdateVisaExtension";
        return HttpService.postService(payload, relativePath,"ExtensionRequest-UpdateByApplicant")
    }
    getFaq = () => {
        let relativePath = "masterdataservice/api/v1/Faq/GetAll?status=2"
        return HttpService.getService(relativePath)
    }
    //Get incomplete detrails for update
    getIncompleteVisaDetail = (payload) => {
        let relativePath = "visaservice/api/v1/VisaRequest/GetDetailForIncompleteRequests?passportNumber=" + payload.passportNumber + "&referenceNumber=" + payload.referenceNumber;
        return HttpService.getService(relativePath,"VisaRequest-GetDetailForIncompleteRequests")
    }
}



