import { ActionTypes } from "../types/ActionTypes";
export const setVisaCategory = (visaCategoryPayload) => {
  return {
    type: ActionTypes.SET_VISA_CATEGORY,
    payload: visaCategoryPayload,
  };
};
export const setArrivalInformation = (arrivalPayload) => {
  return {
    type: ActionTypes.SET_ARRIVAL_INFORMATION,
    payload: arrivalPayload,
  };
};

export const setPersonalInformation = (personalPayload) => {
  return {
    type: ActionTypes.SET_PERSONAL_INFORMATION,
    payload: personalPayload,
  };
};
export const setPassportInformation = (passportPayload) => {
  return {
    type: ActionTypes.SET_PASSPORT_INFORMATION,
    payload: passportPayload,
  };
};
export const setSupportingDocument = (documentPayload) => {
  return {
    type: ActionTypes.SET_SUPPORTING_DOCUMENT,
    payload: documentPayload,
  };
};
