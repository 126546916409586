import React from "react";
import "./evisaStyle.css";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
export default function NGOVisa() {
  return (
    <div className="text-left">
      <h4 className="evisa-title">
        Non-Governmental Organizations(NGO) Work Visa - NV
      </h4>
      <div className="divider"></div>
      <p className="text-left">
        An NGO visa is a visa issued to those foreign nationals who are invited
        by NGOs operating in Ethiopia for Employment or short term assignments.
      </p>
      <h5 className="evisa-title">Requirements</h5>
      <p className="text-left">Copy of the following documents is mandatory.</p>
      <ul>
        <li>Recent passport-size photo of the applicant.</li>
        <li>
          Passport of the visa applicant which is valid for at least 6 months
          from the intended date of entry to Ethiopia.
        </li>
        <li>
          Copy of NV (Non-Governmental Entity) visa/last extended if recently
          extended.
        </li>
        <li>
          A letter of support not expired more than one week from the
          international or continental organizations to which the person was
          invited.
        </li>
        <li>
          If it is a relief organization, the renewed recognition certificate of
          the organization.
        </li>

        <li>
          TIN (Taxpayer Identification Number) Certificate of the inviting
          company.
        </li>
      </ul>
      <h5 className="evisa-title">Processing Time</h5>
      <p className="text-left">
        Under normal circumstances, the processing time for{" "}
        <em>Non-Governmental Organizations(NGO) Work Visa - NV</em> is 3 days.
        So, travelers should submit their application at least 3 days before
        their estimated date of arrival.
      </p>

      <h5 className="evisa-title">Duration and Validity</h5>
      <p className="text-left">
        The Validity of the Visa starts counting from the date intended to enter
        Ethiopia.
      </p>

      <h5 className="evisa-title">Fee</h5>
      <div className="striped bordered text-center">
        <Table bordered responsive size="md">
          <thead className="evisa-color">
            <tr>
              <th>Entry Type</th>
              <th>Maximum Duration</th>
              <th>Application Visa Fee (in USD)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Single Entry</td>
              <td>30 Days</td>
              <td>202</td>
            </tr>

            <tr>
              <td>Multiple Entry</td>
              <td>90 Days</td>
              <td>602</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <h5 className="evisa-title">Extension</h5>
      <p className="text-left">
        Travelers can request for extension before the visa expires in which
        expired date 1-15 days extend by online and visa expired date above 15
        extended by going in person to the Head Office of the Immigration And
        Citizenship Service (Addis Ababa, Ethiopia). Travelers who stay beyond
        the validity period without extending are subject to fines and legal
        penalties.
      </p>
      <div className="striped bordered text-center">
        <Table bordered responsive size="md">
          <thead className="evisa-color">
            <tr>
              <th>Entry Type</th>
              <th>Maximum Duration</th>
              <th>Extension Visa Fee (in USD)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Single Entry</td>
              <td>30 Days</td>
              <td>302</td>
            </tr>
          </tbody>
        </Table>
      </div>

      <Link to="/visa">
        <Button className="mt-5 evisa-button">
          Apply for NGO Work Visa Now
        </Button>
      </Link>
    </div>
  );
}
