import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import "./evisaStyle.css";
import { Link } from "react-router-dom";
// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
  return (
    <div className="text-left">
      <h4 className="evisa-title">VISA STATUS INFORMATION</h4>
      <div className="divider"></div>
      <p>
      If you haven't received information about your visa status,Please follow the simple steps below to check the status of your visa application.
      </p>
      <Link to="/status">
      <h6>Click Here to Check Your Visa Status</h6>  
      </Link>
      <p>Fill Your Passport Number and Reference Number</p>
      <h5>Click "Submit"</h5>
      <p>After filling in your details, you will see the latest update on your visa application</p>
      <p>If payment is pending,  <strong>complete the payment.</strong> </p>
      <p>If your visa request is incomplete, <strong>complete your application </strong> </p>
      <p>If your visa request is approved,<strong>download your visa.</strong> </p>
   
    </div>
  );
}
