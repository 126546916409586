export const VisaonArrivalService = {
  getData() {
    const countries = [
      'Algeria', 'Angola', 'Argentina', 'Austria', 'Australia', 'Bahrain', 'Belarus',
      'Belgium', 'Benin', 'Bosnia and Herzegovina', 'Botswana', 'Brazil', 'Bulgaria',
      'Burkina Faso', 'Burundi', 'Cameroon', 'Canada', 'Cape Verde', 'Central African Republic (CAR)',
      'Chad', 'China', 'Comoros', 'Côte d\'Ivoire', 'Croatia', 'Cyprus', 'Czech Republic',
      'Democratic Republic of the Congo', 'Denmark', 'Djibouti', 'Equatorial Guinea', 'Estonia',
      'Finland', 'France', 'Gabon', 'Georgia', 'Germany', 'Ghana', 'Greece', 'Guinea',
      'Guinea-Bissau', 'Hong Kong', 'Hungary', 'Iceland', 'India', 'Indonesia', 'Ireland',
      'Israel', 'Italy', 'Japan', 'Jordan', 'Kenya', 'Kosovo', 'Kuwait', 'Latvia', 'Lebanon',
      'Lesetho', 'Liberia', 'Liechtenstein', 'Lithuania', 'Luxembourg', 'Madagascar', 'Malawi',
      'Malaysia', 'Mali', 'Malta', 'Mauritania', 'Mauritius', 'Mexico', 'Monaco', 'Montenegro',
      'Morocco', 'Mozambique', 'Namibia', 'New Zealand', 'Niger', 'North Korea', 'North Macedonia',
      'Norway', 'Oman', 'Philippines', 'Poland', 'Portugal', 'Qatar', 'Republic of the Congo',
      'Romania', 'Russia', 'Rwanda', 'Sao Tome and Principe', 'Saudi Arabia', 'Senegal', 'Serbia',
      'Seychelles', 'Sierra Leone', 'Singapore', 'Slovakia', 'Slovenia', 'Somalia', 'South Africa',
      'South Korea', 'South Sudan', 'Spain', 'Swatini', 'Sweden', 'Switzerland', 'Taiwan,China', 'Tanzania',
      'Thailand', 'The Gambia', 'The Netherlands', 'Togo', 'Tunisia', 'Turkey', 'UAE', 'Uganda',
      'Ukraine', 'United Kingdom', 'United States', 'Zambia', 'Zimbabwe'
    ];
    const visaOnArrivalCountries = countries.map((country, index) => {
      return {
        country: {
          name: country
        },
        id: 1000 + index, 
        name: 'Visa on Arrival',
        counter: index + 1 
      };
    });
    return visaOnArrivalCountries;
  },
};

