import React, { useState, useEffect } from "react";
// import "./applicationList.css";

import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UnorderedListOutlined,
  AppstoreOutlined,
  SendOutlined,
  EditOutlined,
  LockOutlined
} from '@ant-design/icons';
import { Layout, Menu, theme } from 'antd';
import DashboardComponent from "./components/dashboard";
import EditProfile from "./components/edit-profile";
import MyApplications from "./components/my-applications";
import ChangePassword from "./components/change-password";
import CompanyService from "../../service/company/CompanyService";
const { Header, Sider, Content } = Layout;

const Dashboard = () => {
  const [detailData, setDetailData] = useState();

  const selected = parseInt(localStorage?.getItem("selectedmenu"))
  const [selectedMenu, setSelectedMenu] = useState(selected ? selected : 1);
  const [title, setTitle] = useState("Dashboard");
  const [collapsed, setCollapsed] = useState(false);
  const [isMobile, setIsMobile] = useState(true);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight
    })
  }

  useEffect(() => {
    window.addEventListener('resize', setDimension);

    return (() => {
      window.removeEventListener('resize', setDimension);
    })
  }, [screenSize])

  useEffect(() => {
    setIsMobile(screenSize.dynamicWidth < 500 ? true : false)
  })

  useEffect(() => {
    setCollapsed(screenSize.dynamicWidth < 500 ? true : false)
  }, [])

  const items = [
    {
      key: '1',
      icon: <AppstoreOutlined />,
      label: 'Dashboard',
    },
    {
      key: '2',
      icon: <EditOutlined />,
      label: 'Edit Profile',
    },
    {
      key: '3',
      icon: <UnorderedListOutlined />,
      label: 'My Applications',
    },
    {
      key: '4',
      icon: <LockOutlined />,
      label: 'Change Password'
    }
  ]

  return (
    <Layout>
      
      <Layout style={{ textAlign: "start" }}>
        <Sider
          trigger={null}
          breakpoint="lg"
          collapsible collapsed={collapsed}
          theme="light"
          className={isMobile ? "mt-4" : "ml-4 mt-4"}
          style={{ marginLeft: isMobile ? "" : "3rem" }}>
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={[selected.toString()]}
            onSelect={(e) => {
              setSelectedMenu(parseInt(e.key))
            }}
            items={items}
          />
        </Sider>
        <Layout className="site-layout">
          <Header className={isMobile ? "mt-4" : "ml-4 mt-4"} style={{ padding: 0, background: colorBgContainer }}>
            {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'trigger',
              onClick: () => setCollapsed(!collapsed),
            })} <span style={{ fontSize: "1rem" }}>{title}</span>
          </Header>
          <Content
            style={{
              padding: 24,
              minHeight: 480,
              background: "#f5f5f5",
            }}
          >
            {selectedMenu === 1 ? <DashboardComponent data={detailData} setTitle={setTitle} /> :
              (selectedMenu === 2 ? <EditProfile setTitle={setTitle} /> :
                (selectedMenu === 3 ? <MyApplications setTitle={setTitle} /> :
                  <ChangePassword setTitle={setTitle} />)
              )}

          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};
export default Dashboard;
