import axios from 'axios';
import { useEffect } from 'react';
import Cookies from "universal-cookie";
import { Toast } from 'primereact/toast';

const cookies = new Cookies();

const baseTemp = window['env']['API_SERVICE_BASE_URL'] + '/';
// const baseURL = baseTemp ? baseTemp : "https://api.dev.evisa.gov.et/";
const baseURL = baseTemp ? baseTemp : "https://api.dev.evisa.gov.et/";
export const SystemToken = () => {
    useEffect(() => {
        getSystemToken();
    }, []);
 const getSystemToken = () => {
        const Url = baseURL + "identity-server/api/v1/Client/login";
        const accessToken = localStorage.getItem("accessToken");
            axios
                .post(Url, {
                    clientId: window['env']['API_SERVICE_CLIENT_ID'],
                    clientsecret: window['env']['API_SERVICE_CLIENT_SECRECT'],
                })
                .then(function (response) {
                    console.log("data",response.data)
                    localStorage.setItem("accessToken", response.data.accessToken);
                    cookies.set("refreshToken", response.data.refreshToken);
                    cookies.set("idToken", response.data.idToken);
                })
                .catch(function (error) {
                    // window.location.pathname = "/login"
                });
    };
};

export const getSystemToken = () => {
    const Url = baseURL + "identity-server/api/v1/Client/login";
    const accessToken = localStorage.getItem("accessToken");
   // if (accessToken === null || accessToken === "") {
        axios
            .post(Url, {
                clientId: window['env']['API_SERVICE_CLIENT_ID'],
                clientsecret: window['env']['API_SERVICE_CLIENT_SECRECT'],
            })
            .then(function (response) {
                localStorage.removeItem("accessToken");
                localStorage.setItem("accessToken", response.data.accessToken);
                localStorage.setItem("idToken", response?.data?.userLoginRes?.idToken);
                cookies.set("idToken", response.data?.userLoginRes?.idToken);
                cookies.set("refreshToken", response.data.refreshToken);
                window.location.reload();
            })
            .catch(function (error) {
                // window.location.pathname = "/login"
                console.log(error)
            });
    
};
export const refreshToken = () => {
    const access_Token = localStorage.getItem('accessToken');
    const headers = {
        accessToken: access_Token,
        "Access-Control-Allow-Credentials": true,
        "access-control-allow-origin": "*",
        "Access-Control-Allow-Headers": "accesstoken, clientclaim"
    };
    axios
        .get(`${baseURL}identity-server/api/v1/User/RefreshUserToken?refreshToken=${localStorage.getItem("refreashToken")}`,{headers})
        .then((response) => {
            localStorage.setItem("refreashToken", response.data?.payload.refreshToken)
            const tokenCollection = {
                idToken: response.data?.payload?.userLoginRes?.idToken,
                clientToken: localStorage.getItem("accessToken")
            }
            localStorage.setItem("tokenCollection", JSON.stringify(tokenCollection));
            cookies.set("refreshToken", response.data?.payload.refreshToken);
        })
        .catch((error) => {
            console.log(error);
        });
};

const accessToken = localStorage.getItem('accessToken');
const idToken = localStorage.getItem("idToken");
if (accessToken === null || accessToken === "") {
    getSystemToken(); 
}
const axiosInstance = axios.create({
    baseURL: baseURL,
    headers: {
        
        accessToken: accessToken,
        idToken: idToken,
        // Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Credentials": true,
        "access-control-allow-origin": "*",
        "Access-Control-Allow-Headers": "accesstoken, ,idToken ,clientclaim"
    },
});

axiosInstance.interceptors.response.use(
    (response) =>
        new Promise((resolve, reject) => {
            resolve(response);
        }),
    (error) => {
        if (!error.response) {
            return new Promise((resolve, reject) => {
                reject(error);
            });
        }
      if (error.response.data?.message === "103") {
            refreshToken();
        }else if (error.response.data?.message === "101") {
            getSystemToken();
        } else if (error.response.data?.message === "102") {
            getSystemToken();
        }else if (error.response.data?.message === "104") {
        } 
         else {
            return new Promise((resolve, reject) => {
                reject(error);
            });
        }
    }
);

export default axiosInstance;
