import React, { useEffect, useState, useRef } from 'react';
import { useFormik } from 'formik';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import CompanyService from "../../service/company/CompanyService";
import SnipperModal from "../../components/common/modal/snipperModal";
import { Toast } from 'primereact/toast';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

export const ResetPassword = () => {
    const toast = useRef(null);
    const [showMessage, setShowMessage] = useState(false);
    const [formData, setFormData] = useState({});
    const [visaDetail, setVisaDetail] = useState({});
    const [isgetSucces, setIsgetSucces] = useState(false);
    let [loading, setLoading] = useState(false);
    const [errorResponse, setErrorResponse] = useState('');
    const [submitError, setSubmitError] = useState(false);
    const [recaptchaSelected, setRecaptchaSelected] = useState(true);
    const [files, setfiles] = useState({});
    const captchaRef = useRef(null)
    const params = new URLSearchParams(window.location.search);

     const navigate= useNavigate();
    const showSuccess = () => {
        toast.current.show({severity:'success', summary: 'Password Reset Successfully', detail:'Your password is Reset Successfully. redirecting to login page...', life: 3000});
    }
    useEffect(() => {
    }, []);
    const formik = useFormik({
        initialValues: {
            password:'',
            confirmPassword:'',
            userName: 'nulll',
            token: params.get("activation_token"),
        },
        validate: (data) => {
            let errors = {};

            if (!data.password) {
                errors.password = 'New password is required.';
            }
            else if (data.password.length < 8) {
                errors.password = 'Password is too short - password length should be 8 minimum.';
              }
              else if (!data.password.match(/[A-Z]/)) {
                errors.password = 'Password should include at least one Uppercase';
              }
              else if (!data.password.match(/[a-z]/)) {
                errors.password = 'Password should include at least one Lowercase';
              }
              else if (!data.password.match(/[0-9]/)) {
                errors.password = 'Password should include at least one numeric or number digit';
              }
              else if (!data.password.match(/[^\w]/)) {
                errors.password = 'Password should include at least one Special Character';
              }
            
            if (!data.confirmPassword) {
                errors.confirmPassword = 'Please confirm new password.';
            }
            
            if (data.confirmPassword&&data.password!==data.confirmPassword) {
                errors.confirmPassword = 'password do not match.';
            }
            return errors;
        },
        onSubmit: (data) => {
            setLoading(true)
            const companyDataService = new CompanyService();
            companyDataService
                .resetPassword(data)
                .then((res) => {
                    setVisaDetail(res.data);
                    setIsgetSucces(true);
                    formik.resetForm();
                    setLoading(false)
                    showSuccess();
                    setTimeout(function() {
                        navigate("/login")
                    }, 3000);

                })
                .catch((err) => {
                    setErrorResponse(err.response.data?.errors[0]);
                    setSubmitError(true);
                    setLoading(false)
                })

            
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    const dialogFooter = <div className="flex justify-content-center"><Button label="OK" className="p-button-text"
        autoFocus onClick={() => setShowMessage(false)} /></div>;
    return (
        <div className='m-2'>
                        <Toast ref={toast} />
            {loading ? <SnipperModal /> : <> {null}</>}

          <div className='row'>
                <div className='col-md-4'></div>
                <div className='col-md-4' style={{ marginBottom: "2rem" }}>
                        <div className="flex justify-content-center">
                            <div className="card mt-3">
                                <div className='evisa-color div-height '> <h5 className="text-center mt-4 ">Reset Password</h5></div>
                                <h5 className='text-danger'> {submitError ? errorResponse : null}</h5>
                                <br/>
                                <form onSubmit={formik.handleSubmit} className="p-fluid">
                                    <div className="field mb-4 mx-2">
                                        <span className="p-float-label">
                                            <Password type="password" id="password" name="password" value={formik.values.password} onChange={formik.handleChange}
                                                autoFocus className={classNames({ 'p-invalid': isFormFieldValid('password') })} toggleMask  />
                                            <label htmlFor="password" className={classNames({ 'p-error': isFormFieldValid('password') })}>New Password*</label>
                                        </span>
                                        {getFormErrorMessage('password')}
                                    </div>
                                    <div className="field mb-4 mx-2">
                                        <span className="p-float-label">
                                            <Password id="confirmPassword" name="confirmPassword" value={formik.values.confirmPassword} onChange={formik.handleChange}
                                                autoFocus className={classNames({ 'p-invalid': isFormFieldValid('confirmPassword') })} toggleMask />
                                            <label htmlFor="confirmPassword" className={classNames({ 'p-error': isFormFieldValid('confirmPassword') })}>Confirm New Password*</label>
                                        </span>
                                        {getFormErrorMessage('confirmPassword')}
                                    </div>
                                    <div className="field mb-4 mx-2">
                                        <Button type="submit" label="Submit" className='evisa-button' />
                                    </div>
                                </form>
                            </div>
                            <div className='col-md-4'></div>
                        </div>

                    </div>
            </div>
        </div>
    );
}
