import React,{useState} from 'react';
import PulseLoader from "react-spinners/PulseLoader";
import Modal from "react-modal";
import './snipperModal.css';

const SnipperModal=()=> {

    const [modalIsOpen, setIsOpen] = React.useState(true);
    let [color, setColor] = useState("#fff");
    return (
        <div >
        <Modal isOpen={modalIsOpen} className="Modal" overlayClassName="Overlay">
            <PulseLoader color={color} size={27} speedMultiplier={1} />
        </Modal>
    </div>
    );
}

export default SnipperModal;