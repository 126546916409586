import React from 'react';

const PaymentFailure = () => {
    return (
        <div>
            <br/>


<div class="container">

    <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-4 paymentstatusbody">
            <div class="login-box-body text-Color">
                <br />
                <div class="paymentcancel">
                    <br />
                    <center><span class="text-white fa fa-exclamation-circle fa-3x"></span></center>
                    <center>
                        <h3 class="text-white">Payment Order Refused</h3>
                    </center>
                </div>

                <div class="form-group">
                    <h6>
                        <br /> Your payment order has been refused. Please make sure you have enough balance on your account and you have provided correct card details and try again. <br /><br />Note: your application will not be processed until the payment
                        is made successfully.<br />
                        <br /> Please contact your bank to check the status of your payment. If there is a payment made, please do not make a second payment with a new application unless indicated otherwise. You will be responsible for the additional
                        payment transaction and it cannot be refunded.
                    </h6>
                </div>
            </div>
        </div>
        <div class="col-md-4"></div>
    </div>

</div>

<br/><br/>
        </div>
    );
};

export default PaymentFailure;