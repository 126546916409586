import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import "./evisaStyle.css";
import { Link } from "react-router-dom";

export default function TouristVisa() {
  return (
    <div className="text-left">
      <h4 className="evisa-title">Tourist Visa-VT</h4>
      <div className="divider"></div>
      <p>
        A tourist visa is an electronic single-entry visa issued by the
        Ethiopian Government for visitors who wish to travel to the country for
        <strong> tourism</strong> or other{" "}
        <strong>non-business-related purposes </strong> only.
      </p>
      <h5 className="evisa-title">Eligibility</h5>
      <p>
        Citizens of all countries in the globe are eligible for tourist visa
        provided that the below requirements are fulfilled.
      </p>
      <h5 className="evisa-title">Requirements</h5>
      <p className="text-left">Copy of the following documents is mandatory.</p>
      <ul>
        <li>Recent passport-size photo of the applicant.</li>
        <li>
          Passport of the visa applicant which is valid for at least 6 months
          from the intended date of entry to Ethiopia.
        </li>
      </ul>
      <h5 className="evisa-title">Processing Time</h5>
      <p className="text-left">
        Under normal circumstances, the processing time for a{" "}
        <em>Tourist Visa</em> is 3 days. So, travelers should submit their
        application at least 3 days before their estimated date of arrival
      </p>
      <h5 className="evisa-title">Duration and Validity</h5>
      <p className="text-left">
        The Validity of the Visa starts counting from the date intended to enter
        Ethiopia.
      </p>

      <h5 className="evisa-title">Fee</h5>
      <div className="striped bordered text-center">
        <Table bordered responsive size="md">
          <thead className="evisa-color">
            <tr>
              <th>Entry Type</th>
              <th>Maximum Duration</th>
              <th>Visa Fee (in USD)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Single Entry</td>
              <td>30 Days</td>
              <td>62</td>
            </tr>
            <tr>
              <td>Single Entry</td>
              <td>90 Days</td>
              <td>152</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <h5 className="evisa-title">Extension</h5>
      <p className="text-left">
        Travelers can request for an extension before the visa expires in which
        expired date 1-15 days extend by online and visa expired date above 15
        extended by going in person to the Head Office of the Immigration And
        Citizenship Service (Addis Ababa, Ethiopia). Travelers who stay beyond
        the validity period without extending are subject to fines and legal
        penalties.
      </p>
      <div className="striped bordered text-center">
        <Table bordered responsive size="md">
          <thead className="evisa-color">
            <tr>
              <th>Entry Type</th>
              <th>Maximum Duration</th>
              <th>Extension Visa Fee (in USD)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Single Entry</td>
              <td>30 Days</td>
              <td>102</td>
            </tr>
            <tr>
              <td>Single Entry</td>
              <td>90 Days</td>
              <td>302</td>
            </tr>
          </tbody>
        </Table>
      </div>

      <Link to="/visa">
        <Button className="mt-5 evisa-button">Apply for Tourist Now</Button>
      </Link>
    </div>
  );
}
