import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import { FaExclamationTriangle } from 'react-icons/fa';
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import VisaService from "../../../service/visa/VisaService";
import { useDispatch, useSelector } from "react-redux";
import { setPersonalInformation } from "../../../redux/actions/VisaActions";
import { RadioButton } from "primereact/radiobutton";
import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import {  Link } from "react-router-dom"

const PersonalInfo = forwardRef((props, ref) => {
  const [countries, setCountries] = useState([]);
  const [nationalities, setNationalities] = useState([]);
  const visaDataService = new VisaService();
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isfNameValid, setIsfNameValid] = useState(true);
  const [islNameValid, setIslNameValid] = useState(true);
  const [isEmailConfirmValid, setIsEmailConfirmValid] = useState(true);
  const digitPattern = new RegExp(/^\+?[0-9][0-9]{9,15}$/);
  const [invalidPhone, setInvalidPhone] = useState(false);
  const disabledDate = (current) => {
    return current && current > dayjs().endOf('day');
  };
  const [personalInfo, setPersonalInfo] = useState({
    firstName: "",
    surname: "",
    gender: "",
    countryOfBirth: "",
    citizenship: "",
    dateOfBirth: null,
    placeOfBirth: "",
    emailAddress: "",
    emailAddressConfirmation: "",
    phoneNumber: "",
    occupation: "",
    streetAddress: "",
    addressCity: "",
    addressCountry: "",
    dataSaved: false,
    formCompleted: false,
  });
  const [notCompleted, setNotCompleted] = useState({
    firstName: true,
    surname: true,
    gender: true,
    countryOfBirth: true,
    citizenship: true,
    dateOfBirth: true,
    placeOfBirth: true,
    emailAddress: true,
    emailAddressConfirmation: true,
    phoneNumber: true,
    occupation: true,
    streetAddress: true,
    addressCity: true,
    addressCountry: true,
  });

  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    saveData() {
      setPersonalInfo((prevState) => ({
        ...prevState,
        dataSaved: true,
      }));
      dispatch(setPersonalInformation(personalInfo));
    },
    Validate() {
      if (personalInfo.dateOfBirth === null) {
        notCompleted.dateOfBirth = true;
      } else notCompleted.dateOfBirth = false;
      if (
        notCompleted.firstName === true ||
        notCompleted.surname === true ||
        notCompleted.gender === true ||
        notCompleted.countryOfBirth === true ||
        notCompleted.dateOfBirth === true ||
        notCompleted.placeOfBirth === true ||
        notCompleted.emailAddress === true ||
        notCompleted.emailAddressConfirmation === true ||
        notCompleted.phoneNumber === true ||
        notCompleted.occupation === true ||
        notCompleted.citizenship === true ||
        notCompleted.streetAddress === true ||
        notCompleted.addressCity === true ||
        notCompleted.addressCountry === true ||
        invalidPhone === true ||
        isEmailValid === false ||
        isEmailConfirmValid === false ||
        isfNameValid === false ||
        islNameValid === false
      )
        return false;
      else return true;
    },
  }));
  const isRequired = "is required!";
  useEffect(() => {
    let masterDataObj=localStorage.getItem("mDataObgj");
    if(masterDataObj!==null||masterDataObj!==""){
      let masterDataParsed= JSON.parse(masterDataObj)
      setNationalities(masterDataParsed?.nationalities);
      setCountries(masterDataParsed?.countries);
    }

    setNotCompleted({
      firstName: personalInfo.firstName === "" ? true : false,
      surname: personalInfo.surname === "" ? true : false,
      gender: personalInfo.gender === "" ? true : false,
      countryOfBirth: personalInfo.countryOfBirth === "" ? true : false,
      citizenship: personalInfo.citizenship === "" ? true : false,
      placeOfBirth: personalInfo.placeOfBirth === "" ? true : false,
      emailAddress: personalInfo.emailAddress === "" ? true : false,
      emailAddressConfirmation: personalInfo.emailAddressConfirmation === "" ? true : false,
      phoneNumber: personalInfo.phoneNumber === "" ? true : false,
      occupation: personalInfo.occupation === "" ? true : false,
      streetAddress: personalInfo.streetAddress === "" ? true : false,
      addressCity: personalInfo.addressCity === "" ? true : false,
      addressCountry: personalInfo.addressCountry === "" ? true : false,

    });

  }, []); 



  const handleChange = (event) => {
    const { name, value } = event.target;
    setPersonalInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === 'emailAddress') {
      if (value.length === 0 || value === "") {
        setIsEmailValid(false);
      } else {
        let emailChecker = new RegExp(/[A-Za-z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}/g).test(value);
        setIsEmailValid(emailChecker);
        if (value !== personalInfo.emailAddress) {
          setIsEmailConfirmValid(false);
        }
        else setIsEmailConfirmValid(true);
      }
    }
    if (name === 'firstName') {

      let letters = new RegExp(/^[A-Za-z\s]*$/g).test(value);
      setIsfNameValid(letters);
    }
    if (name === 'surname') {
      let letters = new RegExp(/^[A-Za-z\s]*$/g).test(value);
      setIslNameValid(letters);
    }

    if (name === 'emailAddressConfirmation') {
      if (value !== personalInfo.emailAddress) {
        setIsEmailConfirmValid(false);
      }
      else setIsEmailConfirmValid(true);
    }
    if (name === 'emailAddress') {
      if(personalInfo.emailAddressConfirmation !== "" || personalInfo.emailAddressConfirmation !== null){
      if (value !== personalInfo.emailAddressConfirmation) {
        setIsEmailConfirmValid(false);
      }
      else setIsEmailConfirmValid(true);
    }
  }
    if (name === 'phoneNumber') {
      if (!digitPattern.test(value)) {
        setInvalidPhone(true);
      }
      else {
        setInvalidPhone(false);
      }
    }


    if (value !== "") {
      setNotCompleted((prevState) => ({
        ...prevState,

        [name]: false,
      }));
    } else {
      setNotCompleted((prevState) => ({
        ...prevState,

        [name]: true,
      }));
    }
  };
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  const handleDateChange = (date, dateString) => {
    setPersonalInfo((prevState) => ({
      ...prevState,
      "dateOfBirth": dateString,
    }))
  }
  return (
    <div className="flex justify-content-center">
      <h4>
        <div aria-live="polite" className="p-inline-message p-component p-inline-message-info" role="alert">
          <span className="p-inline-message-icon pi pi-info-circle"></span>
          <span>If you haven't received information about your visa status, feel free to revisit this website to check your visa status and download your visa documents.
            <Link to="/status" target="_blank"  rel="noreferrer" style={{ textDecoration: 'underline' }}>Link to check your visa status.</Link>
          </span>
        </div>
      </h4>
      {/* <div>
      <h5>
        <FaExclamationTriangle style={{ color: 'red', marginRight: '8px' }} />
        <span style={{ color: 'red' }}>
          We are temporarily unable to accept MasterCard due to technical issues.
        </span>
      </h5>
    </div> */}
      <div className="grid">
        <div className="col-12">
          <div className="card p-5">
            <form className="p-fluid row">
              <h5 className="align-left">PERSONAL INFORMATION</h5>
              <hr />

              <div className="field col-md-6 mb-4 mt-2">
                <span className="p-float-label">
                  <InputText
                    type="text"
                    onBlur={handleChange}
                    name="firstName"
                    className="p-inputtext-sm block"
                  />
                  <label htmlFor="icon" className="align-left">
                    Given Name<span className="text-danger">*</span>
                  </label>
                </span>
                <span style={{ color: "red" }}>
                  {" "}
                  {notCompleted.firstName === true &&
                    personalInfo.dataSaved === true
                    ? "First Name " + isRequired
                    : (isfNameValid ? null : 'First name should must alphabets.')}
                </span>{" "}
              </div>
              <div className="field col-md-6 mb-4 mt-2">
                <span className="p-float-label">
                  <InputText
                    type="text"
                    name="surname"
                    onBlur={handleChange}
                    className="p-inputtext-sm block"
                  />
                  <label htmlFor="icon" className="align-left">
                    Surname<span className="text-danger">*</span>
                  </label>
                </span>
                <span style={{ color: "red" }}>
                  {" "}
                  {notCompleted.surname === true &&
                    personalInfo.dataSaved === true
                    ? "surname " + isRequired
                    : (islNameValid ? null : 'Surname should must alphabets.')}
                </span>{" "}
              </div>
              <div className="field col-md-6 mb-4">
                <span className="p-float-label">
                  <Dropdown
                    id="citizenship"
                    name="citizenship"
                    value={personalInfo.citizenship}
                    onChange={handleChange}
                    options={nationalities}
                    optionLabel="name"
                    className="p-inputtext-sm block"
                  />
                  <label htmlFor="citizenship">
                    Citizenship<span className="text-danger">*</span>
                  </label>
                </span>
                <span style={{ color: "red" }}>
                  {" "}
                  {notCompleted.citizenship === true &&
                    personalInfo.dataSaved === true
                    ? "Citizenship" + isRequired
                    : null}
                </span>{" "}
              </div>

              <div className="field col-md-6 mb-4">
                <label className="align-left px-4" htmlFor="country">
                <span style={{color:"#737373",fontSize:"13px"}}>   Gender<span className="text-danger">*</span></span>
                </label>
                <br />
                <span style={{color:"#4d4d4d",fontSize:"15px"}} className="p-float-label px-4">
                  <RadioButton
                    inputId="male"
                    name="gender"
                    value="male"
                    onChange={handleChange}
                    checked={personalInfo.gender === "male"}
                  />{" "}
                  Male
                  <span className="mx-3">
                    <RadioButton
                      inputId="female"
                      className="mr-4"
                      name="gender"
                      value="female"
                      onChange={handleChange}
                      checked={personalInfo.gender === "female"}
                    />{" "}
                    Female
                  </span>
                </span>
                <span style={{ color: "red" }}>
                  {" "}
                  {notCompleted.gender === true &&
                    personalInfo.dataSaved === true
                    ? "Gender " + isRequired
                    : null}
                </span>{" "}
              </div>
              <div className="field col-md-6 mb-4">
                <br />

                <span className="p-float-label">
                  <Dropdown
                    name="countryOfBirth"
                    onChange={handleChange}
                    value={personalInfo.countryOfBirth}
                    options={countries}
                    className="p-inputtext-sm block"
                    optionLabel="name"
                  />
                  <label className="align-left" htmlFor="country">
                    Country of Birth<span className="text-danger">*</span>
                  </label>
                </span>
                <span style={{ color: "red" }}>
                  {" "}
                  {notCompleted.countryOfBirth === true &&
                    personalInfo.dataSaved === true
                    ? "Country Of Birth " + isRequired
                    : null}
                </span>{" "}
              </div>
              <div className="field col-md-6 mb-4">
                <span>
                  Date of Birth<span className="text-danger">*</span>
                </span>

                <DatePicker
                  name="dateOfBirth"
                  disabledDate={disabledDate}
                  onChange={handleDateChange}
                  style={{ width: '100%', height: "40px" }}
                />

                <span style={{ color: "red" }}>
                  {" "}
                  {notCompleted.dateOfBirth === true &&
                    personalInfo.dataSaved === true
                    ? "date Of Birth " + isRequired
                    : null}
                </span>{" "}
              </div>

              <div className="field col-md-6 mb-4">
                <span className="p-float-label">
                  <InputText
                    type="text"
                    name="placeOfBirth"
                    onBlur={handleChange}
                    className="p-inputtext-sm block"
                  />
                  <label htmlFor="icon" className="align-left">
                    Place of Birth<span className="text-danger">*</span>
                  </label>
                </span>
                <span style={{ color: "red" }}>
                  {" "}
                  {notCompleted.placeOfBirth === true &&
                    personalInfo.dataSaved === true
                    ? "Place Of Birth " + isRequired
                    : null}
                </span>{" "}
              </div>
              <div className="field col-md-6 mb-4">
                <span className="p-float-label">
                  <InputText
                    type="text"
                    name="emailAddress"
                    onBlur={handleChange}
                    className="p-inputtext-sm block"
                  />
                  <label htmlFor="icon" style={{ float: "left" }}>
                    Email Address<span className="text-danger">*</span>
                  </label>
                </span>
                <span style={{ color: "red" }}>
                  {" "}
                  {notCompleted.emailAddress === true &&
                    personalInfo.dataSaved === true
                    ? "Email Address " + isRequired
                    : (isEmailValid === false
                      ? 'Invalid email address'
                      : null)}
                </span>

              </div>

              <div className="field col-md-6 mb-4">
                <span className="p-float-label">
                  <InputText
                    type="text"
                    name="emailAddressConfirmation"
                    onBlur={handleChange}
                    className="p-inputtext-sm block" />
                  <label htmlFor="icon" className="align-left">
                    Confirm Email Address<span className="text-danger">*</span>
                  </label>
                </span>
                <span style={{ color: "red" }}>
                  {" "}

                  {notCompleted.emailAddressConfirmation === true &&
                    personalInfo.dataSaved === true
                    ? "Email Address  Confirmation " + isRequired
                    : (isEmailConfirmValid === false
                      ? 'Email Address is not match.'
                      : null)}
                </span>{" "}
              </div>

              <div className="field col-md-6 mb-4">
                <span className="p-float-label">
                  <InputText
                    className="p-inputtext-sm block"
                    name="phoneNumber"
                    id="inputtext"
                    onBlur={handleChange}
                  />
                  <label htmlFor="country">
                    Phone Number<span className="text-danger">*</span>
                  </label>
                </span>
                <span style={{ color: "red" }}>
                  {" "}
                  {notCompleted.phoneNumber === true &&
                    personalInfo.dataSaved === true
                    ? "Phone Number " + isRequired
                    : (invalidPhone === true
                      ? 'Invalid phone number'
                      : null)}
                </span>
              </div>
              <div className="field col-md-6 mb-4">
                <span className="p-float-label">
                  <InputText
                    className="p-inputtext-sm block"
                    name="occupation"
                    onBlur={handleChange}
                    id="inputtext"
                  />
                  <label htmlFor="inputtext">
                    Occupation<span className="text-danger">*</span>
                  </label>
                </span>
                <span style={{ color: "red" }}>
                  {" "}
                  {notCompleted.occupation === true &&
                    personalInfo.dataSaved === true
                    ? "Occupation " + isRequired
                    : null}
                </span>{" "}
              </div>
              <div className="field col-md-6 mb-4">
                <span className="p-float-label">
                  <InputText
                    className="p-inputtext-sm block"
                    name="streetAddress"
                    onBlur={handleChange}
                    id="inputtext"
                  />
                  <label htmlFor="inputtext">
                    Street Address<span className="text-danger">*</span>
                  </label>
                </span>
                <span style={{ color: "red" }}>
                  {" "}
                  {notCompleted.streetAddress === true &&
                    personalInfo.dataSaved === true
                    ? "Street Address " + isRequired
                    : null}
                </span>{" "}
              </div>
              <div className="field col-md-6 mb-4">
                <span className="p-float-label">
                  <InputText
                    name="addressCity"
                    onBlur={handleChange}
                    className="p-inputtext-sm block"
                    id="inputtext"
                  />
                  <label htmlFor="inputtext">
                    Address City<span className="text-danger">*</span>
                  </label>
                </span>
                <span style={{ color: "red" }}>
                  {" "}
                  {notCompleted.addressCity === true &&
                    personalInfo.dataSaved === true
                    ? "Address City " + isRequired
                    : null}
                </span>{" "}
              </div>

              <div className="field col-md-6 mb-4">
                <span className="p-float-label">
                  <Dropdown
                    name="addressCountry"
                    onChange={handleChange}
                    value={personalInfo.addressCountry}
                    options={countries}
                    className="p-inputtext-sm block"
                    optionLabel="name"
                  />
                  <label className="align-left" htmlFor="country">
                    Address Country<span className="text-danger">*</span>
                  </label>
                </span>
                <span style={{ color: "red" }}>
                  {" "}
                  {notCompleted.addressCountry === true &&
                    personalInfo.dataSaved === true
                    ? "Address Country " + isRequired
                    : null}
                </span>{" "}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
});
export default PersonalInfo;
