import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Password } from "primereact/password";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { classNames } from "primereact/utils";
import CompanyService from "../../service/company/CompanyService";
import RequiredAttachment from "./RequiredAttachment ";
import SnipperModal from "../../components/common/modal/snipperModal";
import { MDBCol, MDBRow, MDBBadge, MDBTypography } from "mdbreact";
import { useNavigate } from 'react-router-dom';
import "./Company.css";
import { Divider } from "antd";

const Create = () => {
  const [companyCategories, setCompanyCategories] = useState([]);
  const [regions, setRegions] = useState([]);
  const [successPage, setSuccessPage] = useState(false);
  const [errorPopUp, setErrorPopUp] = useState(false);
  const [confirmSubmission, setConfirmSubmission] = useState(false);
  const [formData, setFormData] = useState({});
  const companyService = new CompanyService();
  const [personalInfoCompleted, setPersonalInfoCompleted] = useState(false);
  const [companyInfoCompleted, setCompanyInfoCompleted] = useState(false);
  const [regionValid, setRegionValid] = useState(true);
  const [compCategoryValid, setCompCategoryValid] = useState(true);
  const [registErrorMessage, setRegistErrorMessage] = useState("");
  const [passportValid, setPassportValid] = useState(true);
  const [businessLicIssueDateValid, setBusinessLicIssueDateValid] = useState(true);
  const [businessLicExpiryDateValid, setBusinessLicExpiryDateValid] = useState(true);
  const [tinIssueDateValid, setTinIssueDateValid] = useState(true);
  const [tinExpiryDateValid, setTinExpiryDateValid] = useState(true);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [files, setfiles] = useState({});
  const [loading, setLoading] = useState(false);
  const inputs = [];
  const [requiredAttachements, setRequiredAttachements] = useState([]);
  const [attachmentNames, setattachmentNames] = useState([]);
  const [requiredFile, setrequiredFile] = useState("");
  const [errorMessage, seterrorMessage] = useState([]);
  const navigate = useNavigate();
  const regex = /^(\+?0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;

  let fileError = [];
  const [companyInfoParam, setCompanyInfoParam] = useState({
    companyCategoryId: 0,
    companyProfileId: 0,
  })
  const confirmation = () => {

    setConfirmSubmission(false)
  }
  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
  function formatDateToDisplay(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [month, day, year].join('/');
  }

  const handleRedirectToLoginPage = () => {
    navigate('/login');
  }
  const getCompanyCategoryList = async () => {
    await companyService
      .getCompanyCategory()
      .then((resp) => {
        setCompanyCategories(resp.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const getRegionList = async () => {
    await companyService
      .getRegion()
      .then((resp) => {
        setRegions(resp.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  useEffect(() => {
    getRegionList();
    getCompanyCategoryList();
  }, []);

  const onChange = (e) => {
    const { id, value } = e.target;

    const fileValue = e.target.files[0];
    setfiles((prevState) => ({
      ...prevState,
      [id]: fileValue,
    }));
  };

  for (let i = 0; i < requiredAttachements.length; i++) {
    inputs.push(
      <div class="col-md-6 my-1" id="attachmentmargin">
        <div class="col-md-12 passport-text-right">
          <MDBBadge color="primary smallPadding ">
            {" "}
            {attachmentNames[i]}{" "}
          </MDBBadge>
        </div>
        <div class="col-md-12">
          <div className="input-group">
            <div className="custom-file row">
              <label
                className="custom-file-label col-md-12"
                htmlFor="inputGroupFile01"
              >
                {requiredAttachements[i] ? (<strong>{
                  requiredAttachements[i].attachementType?.name} </strong>
                ) : (
                  <div class="smallFont"> <strong>Choose file </strong></div>
                )}
              </label>

              <input
                name={requiredAttachements[i].attachementType?.name}
                type="file"
                id={requiredAttachements[i].attachementType?.id}
                className="custom-file-input form-control col-md-12"
                aria-describedby="inputGroupFileAddon01"
                accept="image/png,image/gif,image/jpeg,image/jpg,application/pdf" //application/pdf
                onChange={(e) => onChange(e)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
  const validateAttachments = (files) => {
    console.log(files);
    var fileCount = 0;
    var i;

    for (i in files) {
      if (files.hasOwnProperty(i)) {
        fileCount++;
      }
    }
    if (fileCount < requiredAttachements.length) {
      fileError.push(`You Should have to Choose All`);
    } else {
      for (var key in files) {
        if (
          !files[key].name.match(
            /\.(jpg|jpeg|png||pdf|JPG|JPEG|PNG||PDF)$/
          )
        ) {
          fileError.push(`  
                ${files[key].name ? (
              files[key].name +
              " is Invalid format, Please upload correct file type!"
            ) : (
              <div class="smallFont">
                Invalid format, Please upload correct file type!
              </div>
            )
            }`);
        }

        if (files[key].size > 2000000) {
          fileError.push(`  
            ${
              files[key].name ? (
                files[key].name +
                " is Invalid size, Please upload file size of maximum 2MB!"
              ) : (
                <div class="smallFont">
                  Invalid size, Please upload file size of maximum 2M!
                </div>
              )
            }`);
        }
      }
    }

    seterrorMessage(fileError);

    if (fileError.length > 0) {
      return false;
    }
    return true;
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      telephone: "",
      city: "",
      regionId: 2147483647,
      zone: "",
      subcity: "",
      woreda: "",
      kebele: "",
      companyManagerEmailAddress: "",
      taxPayerIdentificationNumber: "",
      businessLicenseIssuedDate: null,
      businessLicenseExpiryDate: null,
      tinCertificateIssuedDate: null,
      tinCertificateExpiryDate: null,
      passportExpiryDate: null,
      // "visaExpiryDate": "2022-10-12T14:10:52.790Z",
      companyCategoryId: 0,
      username: "",
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      confirmPassword: "",
      phoneNumber: ""
    },

    validate: (data) => {
      let errors = {};
      if (!personalInfoCompleted && !companyInfoCompleted) {

        if (!data.firstName) {
          errors.firstName = 'First name is required.';
        }

        if (!data.lastName) {
          errors.lastName = 'Last name is required.';
        }
        if (!data.email) {
          errors.email = 'Email address is required.';
        }
        if (data.email && !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data.email))) {
          errors.email = 'Email address is invalid.';
        }
        if (!data.username) {
          errors.username = 'username is required.';
        }
        if (!data.password) {
          errors.password = 'Password is required.';
        }
        else if (data.password.length < 8) {
          errors.password = 'Password is too short - password length should be 8 minimum.';
        }
        else if (!data.password.match(/[A-Z]/)) {
          errors.password = 'Password should include at least one Uppercase';
        }
        else if (!data.password.match(/[a-z]/)) {
          errors.password = 'Password should include at least one Lowercase';
        }
        else if (!data.password.match(/[0-9]/)) {
          errors.password = 'Password should include at least one numeric or number digit';
        }
        else if (!data.password.match(/[^\w]/)) {
          errors.password = 'Password should include at least one Special Character';
        }
        if (data.confirmPassword !== data.password) {
          errors.confirmPassword = 'Confirm Password should match the password'
        }

        if (!data.telephone) {
          errors.telephone = 'Telephone is required.';
        }

        else if (!regex.test(data.telephone.toString())) {
          errors.telephone = 'The telephone number should only be numeric or number digit.';

        }
        else if (data.telephone.length < 10) {
          errors.telephone = 'The telephone number should be 10 digit minimium.';
        }
        // console.log(formik.dirty.valueOf('passportExpiryDate'))

        if (data.passportExpiryDate === null && formik.dirty.valueOf('passportExpiryDate')) {
          setPassportValid(false);
          errors.passportExpiryDate = 'Select passport expired date';
        }
        else setPassportValid(true);
      }
      else if (personalInfoCompleted && !companyInfoCompleted) {
        if (!data.name) {
          errors.name = 'Company Name is required.';
        }
        if (!data.companyManagerEmailAddress) {
          errors.companyManagerEmailAddress = 'Company Manager Email Address is required.';
        }
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.companyManagerEmailAddress)) {
          errors.companyManagerEmailAddress = 'Invalid email address. Please enter valid email address';
        }

        if (!data.phoneNumber) {
          errors.phoneNumber = 'Phone Number is required.';
        }
        else if (data.phoneNumber.length < 10) {
          errors.phoneNumber = 'The Phone number should be 10 digit minimium.';
        }

        if (data.businessLicenseIssuedDate === null) {
          setBusinessLicIssueDateValid(false);
          errors.businessLicenseIssuedDate = 'Select business License issued date';
        }
        else setBusinessLicIssueDateValid(true);

        if (data.businessLicenseExpiryDate === null) {
          setBusinessLicExpiryDateValid(false);
          errors.businessLicenseExpiryDate = 'Select business License expired date';
        }
        else setBusinessLicExpiryDateValid(true);

        if (data.tinCertificateIssuedDate === null) {
          setTinIssueDateValid(false);
          errors.tinCertificateIssuedDate = 'Please select Tin Certificate Issued Date';
        }
        else setTinIssueDateValid(true);

        if (data.passportExpiryDate === null) {
          setPassportValid(false);
          errors.passportExpiryDate = 'Select passport expired date';
        }
        else setPassportValid(true);

        if (data.tinCertificateExpiryDate === null) {
          setTinExpiryDateValid(false);
          errors.tinCertificateExpiryDate = 'Select Tin Certificate Issued Date'
        }
        else setTinExpiryDateValid(true);

        if (!data.taxPayerIdentificationNumber) {
          errors.taxPayerIdentificationNumber = 'Tax payer identification number is required'
        }

      }
      else if (personalInfoCompleted && companyInfoCompleted) {

        if (data.regionId === 2147483647) {
          errors.regionId = 'Please Select region';
          setRegionValid(false);
        }
        else setRegionValid(true);
        if (!data.city) {
          errors.city = 'City is required.';
        }
        else if (data.city.match(/[0-9]/)) {
          errors.city = 'City should not include numeric or number.';
        }
        // else if(data.city.match(/[^\w]/)) {
        //   errors.city = 'City should  not include Special Character';
        // }

        if (!data.woreda) {
          errors.woreda = 'Woreda is required.';
        }
      }
      return errors;
    },
    onSubmit: (data) => {
      debugger;
      if (personalInfoCompleted && companyInfoCompleted) {
        setLoading(true)
        data.companyCategoryId = selectedCompany?.id;
        let formData = new FormData();
        const isValid = validateAttachments(files);
        if (isValid) {
          for (var key in files) {
            formData.append(key, files[key]);
          }
        }
        else {
          setLoading(false);
          return
        }
        formData.append("firstName", data.firstName);
        formData.append("lastName", data.lastName);
        formData.append("username", data.username);
        formData.append("email", data.email);
        formData.append("password", data.password);
        formData.append("phoneNumber", data.phoneNumber);
        formData.append("name", data.name);
        formData.append("telephone", data.telephone);
        formData.append("city", data.city);
        formData.append("regionId", data.regionId.id);
        formData.append("zone", data.zone);
        formData.append("subcity", data.subcity);
        formData.append("woreda", data.woreda);
        formData.append("kebele", data.kebele);
        formData.append("companyManagerEmailAddress", data.companyManagerEmailAddress);
        formData.append("taxPayerIdentificationNumber", data.taxPayerIdentificationNumber);
        formData.append("companyCategoryId", data.companyCategoryId);
        formData.append("businessLicenseIssuedDate", formatDate(data.businessLicenseIssuedDate));
        formData.append("businessLicenseExpiryDate", formatDate(data.businessLicenseExpiryDate));
        formData.append("tinCertificateIssuedDate", formatDate(data.tinCertificateIssuedDate));
        formData.append("tinCertificateExpiryDate", formatDate(data.tinCertificateExpiryDate));
        formData.append("passportExpiryDate", formatDate(data.passportExpiryDate));
        // formData.append("visaExpiryDate", data.visaExpiryDate);
        companyService
          .createCompany(formData)
          .then((res) => {
            formik.resetForm();
            setFormData(data);
            setLoading(false)
            setSuccessPage(true);
          })
          .catch((err) => {
            setFormData(data);
            setRegistErrorMessage(err.response.data.title?err.response.data.title:err.response.data.messageList[0])
            setLoading(false);
            setErrorPopUp(true);

          });
      }
      else if (personalInfoCompleted && !companyInfoCompleted) {
        if (selectedCompany === null) {
          setCompCategoryValid(false)
        } else {
          setCompanyInfoCompleted(true);
          setCompCategoryValid(true)
          window.scrollTo(0, document.body.scrollHeight);
        }
      }
      else {
        window.scrollTo(0, document.body.scrollHeight);
        setPersonalInfoCompleted(true)
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  const handleErrorPopUp = () => {
    setErrorPopUp(false);
  }

  const getRequiredAttachment = (id) => {
    setfiles({});
    setRequiredAttachements([]);
    companyService
      .getCompanyRequiredAttachment(id)
      .then((response) => {
        if (response.data.length > 0) {
          setRequiredAttachements(response.data);
        }
        else setRequiredAttachements([]);
      })
      .catch((error) => {
        // console.log("error" + error.message);
        setRequiredAttachements([])
      });
  };

  const handleChangeCompany = (value) => {
    setSelectedCompany(value)
    getRequiredAttachment(value?.id)
  }

  let today = new Date();
  let month = today.getMonth();
  let year = today.getFullYear();
  let prevMonth = (month === 0) ? 11 : month - 1;
  let prevYear = (prevMonth === 11) ? year - 1 : year;
  let nextMonth = (month === 11) ? 0 : month + 1;
  let nextYear = (nextMonth === 0) ? year + 1 : year;

  let minDate = new Date();
  // minDate.setMonth(prevMonth);
  // minDate.setFullYear(prevYear);

  let maxDate = new Date();
  // maxDate.setMonth(nextMonth);
  // maxDate.setFullYear(nextYear);
  const seccussDialogFooter = (
    <div className="flex justify-content-center text-center">
      <Button
        label="Login"
        className="p-button-success p-button-sm"
        autoFocus
        onClick={() => handleRedirectToLoginPage()}
      />
    </div>
  );

  const errorPopUpFooter = (
    <div className="flex justify-content-center text-center">
      <Button
        label="Ok"
        className="p-button-primary p-button-sm"
        autoFocus
        onClick={() => handleErrorPopUp()}
      />
    </div>
  );

  const confirmDialogFooter = (
    <div className="flex justify-content-center text-center">
      <Button
        label="Confirm"
        className="p-button-success p-button-sm"
        autoFocus
        onClick={() => confirmation()}
      />
      <Button
        label="Cancel"
        className="p-button-warning p-button-sm"
        autoFocus
        onClick={() => setConfirmSubmission(false)}
      />
    </div>
  );
  return (
    <div>
      {loading ? <SnipperModal /> : <> {null}</>}

      <div className="row">
        <div className="col-md-12 row mt-4">
          <div className="col-md-2"></div>
          <div
            className="col-md-8"
            style={{ float: "left", textAlign: "left" }}
          >
            <h2 className="m-2 mx-5 my-3">Company Registration</h2>

            <p className="mx-5">
              You must log in and apply to legalize your company
              registration after you register the company successfully.
            </p>
            <p className="mx-5">
              After company successfully registered, the confirmation
              message will send to the registered company email.
            </p>
            <p className="mx-5">
              <strong>Note</strong>: The person who registers the company is
              responsible for supplying the accuracy and correctness of the
              information.
            </p>
            <div className="col-md-2"></div>
          </div>
        </div>
        <div className="col-md-2"></div>
        <div className="col-md-8">

          <div className="form-demo m-5">
            <Dialog
              visible={successPage}
              onHide={() => setSuccessPage(false)}
              position="top"
              footer={seccussDialogFooter}
              showHeader={false}
              breakpoints={{ "960px": "80vw" }}
              style={{ width: "30vw" }}
            >
              <div className="flex align-items-center flex-column text-center pt-6 px-3 mt-2">
                <i
                  className="pi pi-check-circle"
                  style={{ fontSize: "4rem", color: "var(--green-500)" }}
                ></i>
                <h5 className='text-center text-dark'><b>Congratulations!</b></h5>
                <p style={{ lineHeight: 1.5, textIndent: "1rem", textAlign: "left" }}>
                  You have successfully registered your company.
                  <br /> Click <b>Login </b>button to login to your dashboard.
                  <br /> After successfully login, you can edit your company related information as well as apply for company legalization.
                </p>
              </div>
            </Dialog>

            <Dialog
              visible={errorPopUp}
              onHide={() => setErrorPopUp(false)}
              position="top"
              footer={errorPopUpFooter}
              showHeader={false}
              breakpoints={{ "960px": "80vw" }}
              style={{ width: "30vw" }}
            >
              <div className="flex align-items-center flex-column text-center pt-6 px-3 mt-2">
                <i
                  className="pi pi-times-circle"
                  style={{ fontSize: "4rem", color: "var(--red-500)" }}
                ></i>
                <h5 className='text-center text-danger'><b>Error!</b></h5>
                <p style={{ lineHeight: 1.5, textIndent: "1rem", textAlign: "center", color: 'red' }}>
                  {registErrorMessage ? registErrorMessage : "Something went wrong."}
                </p>
              </div>
            </Dialog>

            <Dialog
              visible={confirmSubmission}
              onHide={() => setConfirmSubmission(false)}
              position="top"
              footer={confirmDialogFooter}
              showHeader={false}
              breakpoints={{ "960px": "80vw" }}
              style={{ width: "30vw" }}
            >
              <div className="flex align-items-center text-center flex-column pt-6 px-3">
                <i
                  className="pi pi-exclamation-triangle"
                  style={{ fontSize: "4rem", color: "var(--yellow-500)" }}
                ></i>
                <h5 className='text-center text-dark'><b>Submission Confirmation!</b></h5>
                <p style={{ lineHeight: 1.5, textIndent: "1rem" }}>
                  are you sure the information you entered about <b>{formData.name}</b> is correct?
                </p>
              </div>
            </Dialog>

            <div className="flex justify-content-center">
              <div className="card">
                <h4 className="m-3 mt-4 ml-4" style={{ float: "left", textAlign: "left" }}>Company Representative Detail</h4>
                <Divider />
                <form onSubmit={formik.handleSubmit} className="p-fluid m-2 row">
                  <div className="field col-md-6">
                    <span className="p-float-label">
                      <InputText
                        id="firstName"
                        name="firstName"
                        keyfilter={"alpha"}
                        autoComplete="off"
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        autoFocus
                        className={classNames({
                          "p-invalid": isFormFieldValid("firstName"),
                        })}
                      />
                      <label
                        htmlFor="firstName"
                        className={classNames({
                          "p-error": isFormFieldValid("firstName"),
                        })}
                      >
                        First Name *
                      </label>
                    </span>
                    {getFormErrorMessage("firstName")}
                  </div>
                  <div className="field col-md-6">
                    <span className="p-float-label">
                      <InputText
                        id="lastName"
                        name="lastName"
                        keyfilter={"alpha"}
                        autoComplete="off"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        className={classNames({
                          "p-invalid": isFormFieldValid("lastName"),
                        })}
                      />
                      <label
                        htmlFor="lastName"
                        className={classNames({
                          "p-error": isFormFieldValid("lastName"),
                        })}
                      >
                        Last Name *
                      </label>
                    </span>
                    {getFormErrorMessage("lastName")}
                  </div>

                  <div className="field col-md-6">
                    <span className="p-float-label">
                      <InputText
                        id="username"
                        name="username"
                        autoComplete="off"
                        value={formik.values.username}
                        onChange={formik.handleChange}
                        className={classNames({
                          "p-invalid": isFormFieldValid("username"),
                        })}
                      />
                      <label
                        htmlFor="username"
                        className={classNames({
                          "p-error": isFormFieldValid("username"),
                        })}
                      >
                        Username *
                      </label>
                    </span>
                    {getFormErrorMessage("username")}
                  </div>
                  <div className="field col-md-6">
                    <span className="p-float-label">
                      <InputText
                        id="email"
                        name="email"
                        keyfilter={"email"}
                        autoComplete="off"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        className={classNames({
                          "p-invalid": isFormFieldValid("email"),
                        })}
                      />
                      <label
                        htmlFor="email"
                        className={classNames({
                          "p-error": isFormFieldValid("email"),
                        })}
                      >
                        Email *
                      </label>
                    </span>
                    {getFormErrorMessage("email")}
                  </div>
                  <div className="field col-md-6">
                    <span className="p-float-label">
                      <Password
                        id="password"
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        toggleMask
                        className={classNames({
                          "p-invalid": isFormFieldValid("password"),
                        })}
                      />

                      <label
                        htmlFor="password"
                        className={classNames({
                          "p-error": isFormFieldValid("password"),
                        })}
                      >
                        Password *
                      </label>
                    </span>
                    {getFormErrorMessage("password")}
                  </div>
                  <div className="field col-md-6">
                    <span className="p-float-label">
                      <Password
                        id="confirmPassword"
                        name="confirmPassword"
                        value={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                        toggleMask
                        className={classNames({
                          "p-invalid": isFormFieldValid("confirmPassword"),
                        })}
                      />

                      <label
                        htmlFor="password"
                        className={classNames({
                          "p-error": isFormFieldValid("confirmPassword"),
                        })}
                      >
                        Confirm Password *
                      </label>
                    </span>
                    {getFormErrorMessage("confirmPassword")}
                  </div>
                  <div className="field col-md-6">
                    <span className="p-float-label p-input-icon-right">
                      <InputText
                        id="telephone"
                        name="telephone"
                        maxLength="15"
                        autoComplete="off"
                        value={formik.values.telephone}
                        onChange={formik.handleChange}
                        className={classNames({
                          "p-invalid": isFormFieldValid("telephone"),
                        })}
                      />
                      <label
                        htmlFor="telephone"
                        className={classNames({
                          "p-error": isFormFieldValid("telephone"),
                        })}
                      >
                        Telephone*
                      </label>
                    </span>
                    {getFormErrorMessage("telephone")}
                  </div>

                  <div className="field col-md-6">
                    <span className="p-float-label">
                      <Calendar
                        id="passportExpiryDate"
                        name="passportExpiryDate"
                        value={
                          formik.values.passportExpiryDate
                        }
                        onChange={formik.handleChange}
                        dateFormat="yy-mm-dd"
                        mask="9999/99/99"
                        showIcon
                        minDate={minDate}
                        className={classNames({
                          "p-invalid": isFormFieldValid("passportExpiryDate"),
                        })}
                      />

                      <label htmlFor="passportExpiryDate"
                        className={classNames({
                          "p-error": isFormFieldValid(
                            "passportExpiryDate"
                          ),
                        })}>
                        Passport Expiry Date
                      </label>
                      {getFormErrorMessage("passportExpiryDate")}
                      {/* {<><span className="text-danger">Please select Passport Expiry Date</span></>} */}
                    </span>
                  </div>

                  {personalInfoCompleted ? <>

                    <h4 className="m-3 mb-4" style={{ float: "left", textAlign: "left" }}>Company Detail</h4>
                    <Divider />
                    <div className="field col-md-6">
                      <span className="p-float-label">
                        <InputText
                          id="name"
                          name="name"
                          autoComplete="off"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          autoFocus
                          className={classNames({
                            "p-invalid": isFormFieldValid("name"),
                          })}
                        />
                        <label
                          htmlFor="name"
                          className={classNames({
                            "p-error": isFormFieldValid("name"),
                          })}
                        >
                          Company Name*
                        </label>
                      </span>
                      {getFormErrorMessage("name")}
                    </div>

                    <div className="field col-md-6">
                      <span className="p-float-label">
                        <InputText
                          id="phoneNumber"
                          name="phoneNumber"
                          value={formik.values.phoneNumber}
                          onChange={formik.handleChange}
                          autoComplete="off"
                          className={classNames({
                            "p-invalid": isFormFieldValid("phoneNumber"),
                          })}
                        />
                        <label
                          htmlFor="phoneNumber"
                          className={classNames({
                            "p-error": isFormFieldValid("phoneNumber"),
                          })}
                        >
                          Phone Number *
                        </label>
                      </span>
                      {getFormErrorMessage("phoneNumber")}
                    </div>
                    <div className="field col-md-6">
                      <br />
                      <span className="p-float-label p-input-icon-right">
                        <InputText
                          id="companyManagerEmailAddress"
                          name="companyManagerEmailAddress"
                          autoComplete="off"
                          value={
                            formik.values
                              .companyManagerEmailAddress
                          }
                          onChange={formik.handleChange}
                          className={classNames({
                            "p-invalid": isFormFieldValid(
                              "companyManagerEmailAddress"
                            ),
                          })}
                        />
                        <label
                          htmlFor="companyManagerEmailAddress"
                          className={classNames({
                            "p-error": isFormFieldValid(
                              "companyManagerEmailAddress"
                            ),
                          })}
                        >
                          Company Manager Email Address *
                        </label>
                      </span>
                      {getFormErrorMessage("companyManagerEmailAddress")}
                    </div>

                    <div className="field col-md-6">
                      <label className="align-left">Company Category</label><br />
                      <Dropdown className="p-inputtext-sm block" value={selectedCompany} options={companyCategories}
                        onChange={(e) => handleChangeCompany(e.value)} optionLabel="category" placeholder="Select company Category" />

                      {!compCategoryValid ? <><span className="text-danger">Please select company category</span></> : null}
                    </div>
                    <div className="field col-md-6">
                      <span className="p-float-label">
                        <Calendar
                          id="tinCertificateIssuedDate"
                          name="tinCertificateIssuedDate"
                          value={
                            formik.values.tinCertificateIssuedDate
                          }
                          onChange={formik.handleChange}
                          dateFormat="yy-mm-dd"
                          mask="9999/99/99"
                          maxDate={maxDate}
                          showIcon
                          className={classNames({
                            "p-invalid": isFormFieldValid("tinCertificateIssuedDate"),
                          })}
                        />
                        <label htmlFor="tinCertificateIssuedDate"
                          className={classNames({
                            "p-error": isFormFieldValid(
                              "tinCertificateIssuedDate"),
                          })}>
                          Tin Certificate Issued Date
                        </label>
                        {getFormErrorMessage("tinCertificateIssuedDate")}
                      </span>
                    </div>
                    <div className="field col-md-6">
                      <span className="p-float-label">

                        <Calendar
                          id="tinCertificateExpiryDate"
                          name="tinCertificateExpiryDate"
                          value={
                            formik.values.tinCertificateExpiryDate
                          }
                          onChange={formik.handleChange}
                          dateFormat="yy-mm-dd"
                          mask="9999/99/99"
                          minDate={minDate}
                          showIcon
                          className={classNames({
                            "p-invalid": isFormFieldValid("tinCertificateExpiryDate"),
                          })}
                        />
                        <label htmlFor="tinCertificateExpiryDate"
                          className={classNames({
                            "p-error": isFormFieldValid(
                              "tinCertificateExpiryDate"),
                          })}>
                          Tin Certificate Expiry Date
                        </label>
                        {getFormErrorMessage("tinCertificateExpiryDate")}
                      </span>
                    </div>
                    <div className="field col-md-6">
                      <span className="p-float-label">
                        <Calendar
                          id="businessLicenseIssuedDate"
                          name="businessLicenseIssuedDate"
                          value={
                            formik.values.businessLicenseIssuedDate
                          }
                          onChange={formik.handleChange}
                          dateFormat="yy-mm-dd"
                          mask="9999/99/99"
                          maxDate={maxDate}
                          showIcon
                          className={classNames({
                            "p-invalid": isFormFieldValid("businessLicenseIssuedDate"),
                          })}
                        />
                        <label htmlFor="businessLicenseIssuedDate"
                          className={classNames({
                            "p-error": isFormFieldValid(
                              "businessLicenseIssuedDate"
                            ),
                          })}>
                          Business License Issued Date
                        </label>
                        {!businessLicIssueDateValid ? <><span className="text-danger">Please select business license expiry date</span></> : null}
                      </span>
                    </div>
                    <div className="field col-md-6">
                      <span className="p-float-label">
                        <Calendar
                          id="businessLicenseExpiryDate"
                          name="businessLicenseExpiryDate"
                          value={
                            formik.values
                              .businessLicenseExpiryDate
                          }
                          onChange={formik.handleChange}
                          dateFormat="yy-mm-dd"
                          mask="9999/99/99"
                          minDate={minDate}
                          showIcon
                          className={classNames({
                            "p-invalid": isFormFieldValid("businessLicenseExpiryDate"),
                          })}
                        />
                        <label htmlFor="businessLicenseExpiryDate"
                          className={classNames({
                            "p-error": isFormFieldValid(
                              "businessLicenseExpiryDate"
                            ),
                          })}>
                          Business License Expiry Date
                        </label>
                        {!businessLicExpiryDateValid ? <><span className="text-danger">Please select business license expiry date</span></> : null}
                      </span>
                    </div>

                    <div className="field col-md-6">
                      <span className="p-float-label">
                        <InputText
                          id="taxPayerIdentificationNumber"
                          name="taxPayerIdentificationNumber"
                          autoComplete="off"
                          value={formik.values.taxPayerIdentificationNumber}
                          onChange={formik.handleChange}
                          className={classNames({
                            "p-invalid": isFormFieldValid("taxPayerIdentificationNumber"),
                          })}
                        />
                        <label
                          htmlFor="taxPayerIdentificationNumber"
                          className={classNames({
                            "p-error": isFormFieldValid("taxPayerIdentificationNumber"),
                          })}
                        >
                          Tax Payer Identification Number *
                        </label>
                      </span>
                      {getFormErrorMessage("taxPayerIdentificationNumber")}
                    </div>

                  </> : null}
                  {companyInfoCompleted ?
                    <>
                      <h4 className="m-3 mb-4" style={{ float: "left", textAlign: "left" }}>Address</h4>
                      <Divider />
                      <div className="field col-md-6">
                        <label htmlFor="regionId"
                          className={classNames("align-left", {
                            "p-error": isFormFieldValid("regionId"),
                          })}
                        >
                          Region
                        </label><br />
                        <Dropdown
                          id="regionId"
                          name="regionId"
                          value={formik.values.regionId}
                          onChange={formik.handleChange}
                          options={regions}
                          optionLabel="name"
                          placeholder="Select Region"
                          className={classNames({
                            "p-invalid": isFormFieldValid("regionId p-inputtext-sm block"),
                          })}
                        />

                        {!regionValid ? <><span className="text-danger">Please select region</span></> : null}
                      </div>
                      <div className="field col-md-6">
                        <br />
                        <span className="p-float-label p-input-icon-right">
                          <InputText
                            id="zone"
                            name="zone"
                            autoComplete="off"
                            value={formik.values.zone}
                            onChange={formik.handleChange}
                          />
                          <label
                            htmlFor="zone">
                            Zone
                          </label>
                        </span>
                        {getFormErrorMessage("zone")}
                      </div>
                      <div className="field col-md-6">
                        <span className="p-float-label p-input-icon-right">
                          <InputText
                            id="city"
                            name="city"
                            autoComplete="off"
                            value={formik.values.city}
                            onChange={formik.handleChange}
                            className={classNames({
                              "p-invalid": isFormFieldValid("city"),
                            })}
                          />
                          <label
                            htmlFor="city"
                            className={classNames({
                              "p-error": isFormFieldValid("city"),
                            })}
                          >
                            City*
                          </label>
                        </span>
                        {getFormErrorMessage("city")}
                      </div>
                      <div className="field col-md-6">
                        <span className="p-float-label p-input-icon-right">
                          <InputText
                            id="subcity"
                            name="subcity"
                            autoComplete="off"
                            value={formik.values.subcity}
                            onChange={formik.handleChange}
                            className={classNames({
                              "p-invalid": isFormFieldValid("subcity"),
                            })}
                          />
                          <label
                            htmlFor="subcity"
                            className={classNames({
                              "p-error": isFormFieldValid("subcity"),
                            })}
                          >
                            Sub City
                          </label>
                        </span>
                        {getFormErrorMessage("subcity")}
                      </div>
                      <div className="field col-md-6">
                        <span className="p-float-label p-input-icon-right">
                          <InputText
                            id="woreda"
                            name="woreda"
                            autoComplete="off"
                            value={formik.values.woreda}
                            onChange={formik.handleChange}
                            className={classNames({
                              "p-invalid": isFormFieldValid("woreda"),
                            })}
                          />
                          <label
                            htmlFor="woreda"
                            className={classNames({
                              "p-error": isFormFieldValid("woreda"),
                            })}
                          >
                            Woreda*
                          </label>
                        </span>
                        {getFormErrorMessage("woreda")}
                      </div>
                      <div className="field col-md-6">
                        <span className="p-float-label p-input-icon-right">
                          <InputText
                            id="kebele"
                            name="kebele"
                            autoComplete="off"
                            value={formik.values.kebele}
                            onChange={formik.handleChange}
                            className={classNames({
                              "p-invalid": isFormFieldValid("kebele"),
                            })}
                          />
                          <label
                            htmlFor="kebele"
                            className={classNames({
                              "p-error": isFormFieldValid("kebele"),
                            })}
                          >
                            Kebele
                          </label>
                        </span>
                        {getFormErrorMessage("kebele")}
                      </div>

                      <div className="row">
                        {requiredAttachements.length>0?
                      <div className="text-left m-0">
                  <ul style={{ listStyleType: "square", textAlign: "left" }}>
                    <h4 className="m-3 text-left">Note info:</h4>
                    <li>Size of the attachment should be less than 2MB.</li>
                    <li>
                      Allowed documents file type extensions are JPEG,
                      JPG, PNG and PDF format only.
                    </li>
                    <li>The attachment should be clear and readable.</li>
                    <li>
                      An attachment with blurred or unclean background is
                      not acceptable.
                    </li>
                  </ul>
                </div>:null }

            {errorMessage.length
                  ? errorMessage.map((error) => (
                      <div class="alert alert-danger text-center" role="alert">
                        {error}
                      </div>
                    ))
                  : null}
                        {inputs}
                      </div>

                    </> : null}

                  <Button id="submit" type="submit" label={!companyInfoCompleted ? "Next" : "Submit"} className="mt-2" />
                </form>
              </div>
            </div>
          </div>

        </div>
        <div className="col-md-2"></div>
      </div>

    </div>
  );
};

export default Create;
