import './App.css';
import EvisaRouter from './components/common/header/EvisaRouter';
import { SystemToken } from "./utils/axios";
import React, { useEffect,useState }  from 'react';
import { useIdleTimer } from 'react-idle-timer'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';

function App() {
  // const navigate = useNavigate();
  // const cookies = new Cookies();
  // let sessionExpiredDialog=false;


  // const timeout = 1000000
  // const [remaining, setRemaining] = useState(timeout)
  // const [elapsed, setElapsed] = useState(0)
  // const [lastActive, setLastActive] = useState(+new Date())
  // const [isIdle, setIsIdle] = useState(false)

  // const handleOnActive = () => setIsIdle(true)
  // const handleOnIdle = () => setIsIdle(true)

  // const {getRemainingTime, getLastActiveTime, getElapsedTime} = useIdleTimer({ timeout,  onActive: handleOnActive, onIdle: handleOnIdle})

  // useEffect(() => {
  //   setRemaining(getRemainingTime())
  //   setLastActive(getLastActiveTime())
  //   setElapsed(getElapsedTime())

  //   setInterval(() => {
  //     setRemaining(getRemainingTime())
  //     setLastActive(getLastActiveTime())
  //     setElapsed(getElapsedTime())
  //   }, 36000)
  // }, []);

  // if(isIdle.toString()==="true"){
  //     sessionExpiredDialog=true;
  //        }
  //        const logoutUser=()=>{
          
  //         localStorage.setItem("accessToken", "");
  //         cookies.set("refreshToken", "");
  //         localStorage.setItem("tokenCollection", "");
  //         // navigate("/");
  //        };

  //        const sessionExpiredDialogFooter = (
  //         <div  className="flex align-items-center justify-content-center">
  //             <Button label="Ok" icon="pi pi-check" onClick={logoutUser} />
  //         </div>
  //     );

  return (
    <div className="App">

      
      <EvisaRouter />
      <SystemToken />
      {/* <Dialog visible={sessionExpiredDialog} style={{ width: "450px" }}
             modal footer={sessionExpiredDialogFooter}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} /> 
                            <h2>Your Session is Expired!</h2> 
                        </div>

                    </Dialog> */}
    </div>
  );
}

export default App;
