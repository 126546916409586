import React, { useEffect, useState, useRef } from 'react';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import CompanyService from "../../service/company/CompanyService";
import SnipperModal from "../../components/common/modal/snipperModal";
import { Toast } from 'primereact/toast';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

export const ForgotPassword = () => {
    const toast = useRef(null);
    const [showMessage, setShowMessage] = useState(false);
    const [formData, setFormData] = useState({});
    const [visaDetail, setVisaDetail] = useState({});
    const [isgetSucces, setIsgetSucces] = useState(false);
    let [loading, setLoading] = useState(false);
    const [errorResponse, setErrorResponse] = useState('');
    const [submitError, setSubmitError] = useState(false);
    const [recaptchaSelected, setRecaptchaSelected] = useState(true);
    const [files, setfiles] = useState({});
    const captchaRef = useRef(null)


     const navigate= useNavigate();
    const showSuccess = () => {
        toast.current.show({severity:'success', summary: 'Password Reset Link Sent', detail:'Password reset link is sent to your email. Please check your email and change the password.', life: 3000});
    }
    useEffect(() => {
    }, []);
    const formik = useFormik({
        initialValues: {
            userName: '',
            isCompany:true
        },
        validate: (data) => {
            let errors = {};

            if (!data.userName) {
                errors.userName = 'Email address number is required.';
            }
            return errors;
        },
        onSubmit: (data) => {
            setLoading(true)
            const companyDataService = new CompanyService();
            companyDataService
                .forgetPassword(data)
                .then((res) => {

                    setVisaDetail(res.data);
                    setIsgetSucces(true);
                    formik.resetForm();
                    setLoading(false)
                    showSuccess();
                    setTimeout(function() {
                        navigate("/")
                    }, 3000);
                })
                .catch((err) => {
                    setErrorResponse(err.response.data?.errors[0]);
                    setSubmitError(true);
                    setLoading(false)
                })

            
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    const dialogFooter = <div className="flex justify-content-center"><Button label="OK" className="p-button-text"
        autoFocus onClick={() => setShowMessage(false)} /></div>;
    return (
        <div className='m-2'>
                        <Toast ref={toast} />
            {loading ? <SnipperModal /> : <> {null}</>}

          <div className='row'>
                <div className='col-md-4'></div>
                <div className='col-md-4' style={{ marginBottom: "2rem" }}>
                        <div className="flex justify-content-center">
                            <div className="card mt-3">
                                <div className='evisa-color div-height '> <h5 className="text-center mt-4 ">Forgot Password</h5></div>
                                <h5 className='text-danger'> {submitError ? errorResponse : null}</h5>
                                <br/>
                                <form onSubmit={formik.handleSubmit} className="p-fluid">
                                    <div className="field mb-4 mx-2">
                                        <span className="p-float-label">
                                            <InputText id="userName" name="userName" value={formik.values.userName} onChange={formik.handleChange}
                                                autoFocus className={classNames({ 'p-invalid': isFormFieldValid('userName') })} />
                                            <label htmlFor="userName" className={classNames({ 'p-error': isFormFieldValid('userName') })}>Email Address*</label>
                                        </span>
                                        {getFormErrorMessage('userName')}
                                    </div>
                                    <div className="field mb-4 mx-2">
                                        <Button type="submit" label="Submit" className='evisa-button' />
                                    </div>
                                </form>
                            </div>
                            <div className='col-md-4'></div>
                        </div>

                    </div>
            </div>
        </div>
    );
}
