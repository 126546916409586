import React from 'react';

const PaymentPending = () => {
    return (
        <div>
            <br/>


<div class="container">

    <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-4 paymentstatusbody">
            <div class="login-box-body text-Color">
                <br />
                <div class="evisa-pending">
                    <br />
                    <center><span class="text-white fa fa-clock-o fa-3x"></span></center>
                    <center>
                        <h3 class="text-white">Payment Status Pending</h3>
                    </center>
                </div>

                <div class="form-group">
                    <h6>
                        <br /> Your payment has been pending and is not successful. <br /><br />Note: your application will not be processed until you make the payment successfully.
                    </h6>
                </div>
            </div>
        </div>
        <div class="col-md-4"></div>
    </div>

</div>

<br/><br/>
        </div>
    );
};

export default PaymentPending;