

import React, { useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import VisaService from '../../service/visa/VisaService';
import axiosInstance from '../../utils/axios';
import { useState } from 'react';
import SnipperModal from "../../components/common/modal/snipperModal";
import { useParams } from "react-router-dom";
import { Col, Row, Image, Card } from 'antd';
import { CreditCardOutlined } from '@ant-design/icons';

import { Button } from "primereact/button"

import { redirect } from "react-router-dom";

import worldPay from "./../../assets/Images/credit-card.jpg";
import aliPay from "./../../assets/Images/alipay-wechat.webp";


const DisplayingErrorMessagesSchema = Yup.object().shape({
  PaymentOption: Yup.string().required("Please select payment option"),
  isAreed: Yup.bool().oneOf(
    [true],
    "You have to agree with the above condition"
  ),
});

export const VisaPayment = () => {
  const searchParams = useParams();
  const params = new URLSearchParams(window.location.search);
  const [visaGetParam, setVisaGetParam] = useState({
    orderCode: params.get("order"),
    visaFee: params.get("val"),
  });
  const [loading, setLoading] = useState(false);
  const visaService = new VisaService()

  return (
    <>
      {loading ? <SnipperModal /> : <> {null}</>}
      <div className='row'>
        <div className='col-md-3'></div>
        <div className='col-md-6'>
          <Card title="Payment Confirmation" style={{ border: "10px solid #f0f0f0", marginBottom: "3rem" }}>
            <br />
            <p className='align-left' style={{ fontSize: "1rem" }}>Your request for e-Visa has been submitted successfully. You can proceed to payment now. Please note that your request will not be processed until you make the payment successfully.</p>
            <br />
            <br />
            <br />
            <br />
            <br />
            <Formik
              initialValues={{
                Ordercode: visaGetParam?.orderCode,
                PaymentOption: '',
                isAreed: false,
              }}
              validationSchema={DisplayingErrorMessagesSchema}
              onSubmit={values => {

                setLoading(true);
                values.PaymentOption = parseInt(values.PaymentOption);
                visaService.createPayment(values)
                  .then((resp) => {

                    // console.log(resp?.data.payload.url)
                    // console.log(resp)
                    window.location.assign(resp?.data.payload.url);
                    // window.location.href = resp?.data.payload.url;
                    setLoading(false)
                  })
                  .catch((error => {
                    // console.log(error)
                    setLoading(false)
                  }))

              }}
            >
              {({ errors, touched, setFieldValue, values }) => (
                <Form className='align-left'>
                  <h5 className='align-left'>Payment Option<span className='text-danger'>*</span></h5>
                  <br />
                  <br />
                  <Row>
                    <Col onClick={() => setFieldValue("PaymentOption", '1')} md={10} sm={24} style={{ border: values.PaymentOption === '1' ? "1px solid gray" : "", padding: "1rem" }}>
                      <Image src={worldPay} preview={false} height={105} />
                      <br />
                      <label className='mb-3 ml-6'>
                        <Field type="radio" value='1' className='form-check-input' name="PaymentOption" />
                        <span>&nbsp; <b>Credit Card/Google Pay</b></span>
                      </label>
                    </Col>
                    <Col sm={4} md={4} />
                    <Col onClick={() => setFieldValue("PaymentOption", '2')} md={10} sm={24} style={{ border: values.PaymentOption === '2' ? "1px solid gray" : "", padding: "1rem" }}>
                      <Image src={aliPay} preview={false} height={105} />
                      <br />
                      <label className='mb-3 ml-6'>
                        <Field type="radio" value='2' className='form-check-input' name="PaymentOption" />
                        &nbsp;  <b> Alipay/Wechat Pay</b>
                      </label>
                    </Col>
                  </Row>

                  {touched.PaymentOption && errors.PaymentOption && <span className='text-danger'><br />{errors.PaymentOption}<br /></span>}
                  <br />
                  <h5>Total amount : USD <b style={{ fontSize: "1.5rem" }}>{visaGetParam?.visaFee}.00$</b></h5>
                  <br />
                  <Field type="checkbox" name="isAreed" />
                  &nbsp; I agree and acknowledge that all payments are NOT REFUNDABLE and all payment shall be handled by Ethiopian Airlines Group and the airline shall be responsible for handling payment and for providing all payment support needed.
                  {touched.isAreed && errors.isAreed && <span className='text-danger'><br />{errors.isAreed}</span>}

                  <br />
                  <br />
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button disabled={!values.isAreed || values.PaymentOption === '' ? true : false} label="Continue Payment" icon="pi pi-credit-card" style={{ width: "15rem", backgroundColor: "#0D4D99" }} />
                  </div>
                </Form>
              )}
            </Formik>
          </Card>
        </div>
        <div className='col-md-3'></div>
      </div>

    </>
  );
};