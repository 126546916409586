import React, { useState } from "react";
import { OrderList } from "primereact/orderlist";
import "./applicationList.css";

const ApplicationList = () => {
  const [products, setProducts] = useState(
    JSON.parse(localStorage.companyLoginResponse).companyApplications
  );

  const itemTemplate = (item) => {
    return (
      <div className="product-item">
        <div className="image-container">
          <h5 className="mb-2">{item?.applicationNumber}</h5>
          <i className="pi pi-tag product-category-icon"></i>
          <span className="product-category">{item?.approvalRemark}</span>
        </div>

        <div className="product-list-detail">
          <h6 className="mb-2">{item?.registeredBy}</h6>
          <span className={`product-badge status`}>{item?.registeredDate}</span>
        </div>

        <div className="product-list-action">
          <h6 className="mb-2">{item?.companyReferenceCode}</h6>
          <span className={`product-badge status`}>
            {item?.approvalStatusDescription}
          </span>
        </div>
      </div>
    );
  };
  return (
    <div className="orderlist-demo">
      <div className="card">
        <OrderList
          value={products}
          header="List of Applications"
          dragdrop
          listStyle={{ height: "auto" }}
          dataKey="id"
          itemTemplate={itemTemplate}
          onChange={(e) => setProducts(e.value)}
        ></OrderList>
      </div>
    </div>
  );
};

export default ApplicationList;
