import React, { useEffect, useState } from "react";
import { MDBCol, MDBRow, MDBBadge, MDBTypography } from "mdbreact";
import { ScrollPanel } from "primereact/scrollpanel";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import SnipperModal from "../../components/common/modal/snipperModal";
import { useFormik } from "formik";
import { classNames } from "primereact/utils";
import VisaService from "../../service/visa/VisaService";
import { Viewer } from '@react-pdf-viewer/core';
import { NavLink, Link, useHistory } from "react-router-dom";
import download from "./../../assets/Images/download-visa.svg";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Image } from "primereact/image"
import { Card, Descriptions } from "antd";
import { green } from "@mui/material/colors";
import { debounce } from "@mui/material";

export const StatusView = (props) => {
  const [performingAction, setPerformingAction] = useState(false);
  const [loading, setloading] = useState(true);
  const [inCompleteVisa, setCompleteVisa] = useState({});
  const [existingAttachemnts, setExistingAttachemnts] = useState([]);
  const [visaObj, setVisaObj] = useState({});
  const [ispayment, setIspayment] = useState(false);
  const [isdownload, setIsdownload] = useState(false);
  const [isincomplete, setIsincomplete] = useState(false);
  const navigate = useNavigate();
  const currentDate = new Date();
  console.log(currentDate)


  useEffect(() => {
    setVisaObj(props.visaDetail);
    if (props.visaDetail.visaStatusDescription === "Incompelete") {
      setIsincomplete(true);
    }
   const arrivalDate= props.visaDetail.arrivalDate ? formatDate(props.visaDetail.arrivalDate):null;

   const today= currentDate ? formatDate(currentDate):null;

   console.log("gg",arrivalDate)
   console.log(today)
    if (props.visaDetail.paymentStatusDescription === "Pending" && arrivalDate >= today) {
     
      setIspayment(true);
    }
    if (props.visaDetail.visaStatusDescription === "Approved") {
      setIsdownload(true);
     // downloadPDF();
    }
    setloading(false);
  }, []);

  const redirectToHome = () => {
    navigate("/");
  };
  function completeButton() {
    return (
      <div className="p-button-info m-2">
        {isincomplete ? (
          <>
           <span className="text-danger"><i>Your Application is Incomplete, Please complete your application by clicking here</i></span>
           <br />
          <Button
            onClick={completeApplication}
            label="Complete Application"
            className="p-button-info m-1"
          />
          </>
        ) :
          (<div></div>)
        }
      </div>
    );
  }
  function PaymentButton() {

    return (
      <div className="p-button-info m-2">
        {ispayment ?
          (
            <>
              <span className="text-danger"><i>Your payment is still pending</i></span>
              <br />
              <Button
                onClick={continuePayment}
                label="Continue Payment"
                className="p-button-info m-1"
                
              /></>
          ) :
          (<div></div>)
        }
      </div>
    );
  }
  
  function downloadButton() {
    return (
      <div className="p-button-info m-2">
        {isdownload ?
          (
            <>
            {
              performingAction ? (
                
              <Button label="Downloading" icon="pi pi-spin pi-spinner"></Button>
              ) :(<>
              < Button
              
                onClick={downloadPDF}
                label="  Download Visa"
                className="p-button-info m-1  "
                style={{ fontFamily: 'Arial' }}

              />
              </>)}

            </>
          ) :
          (<div></div>)
        }
      </div>
    );
  }
  function downloadPDF() {
    setPerformingAction(true);
    const visaService = new VisaService();
    console.log("download id in d="+props.visaDetail.id);


    visaService
        .downloadVisa(props.visaDetail)
        .then((res) => {
            const linkSource = "data:application/pdf;base64," + res.data.payload;
            const downloadLink = document.createElement("a");
            const fileName = "Visa_" + props.visaDetail.passportNumber + "_" + props.visaDetail.referenceNumber + ".pdf";
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
           
            setPerformingAction(false);
        })
        .catch((error) => {})
        .finally(() => {
            setPerformingAction(false);
           
        });
}
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }
  // const downloadVisa = () => {
  //    downloadPDF();

  //  // navigate('/download-visa?ptn=' + props.visaDetail?.passportNumber + "&rfn=" + props.visaDetail?.referenceNumber);
  // };
  const completeApplication = () => {
    navigate('/visa-CompleteVisa?ptn=' + props.visaDetail?.passportNumber + "&rfn=" + props.visaDetail?.referenceNumber);
  };
  const continuePayment = () => {
    navigate('/visa-payment?order=' + props.visaDetail?.orderCode + "&val=" + props.visaDetail?.visaFee);
  };
  const dialogFooter = (
    <div className="flex justify-content-center">
      <Button
        label="OK"
        className="p-button-text"
        autoFocus
        onClick={redirectToHome}
      />
    </div>
  );
  function checkStatus() { 
    if (visaObj?.visaStatusDescription === 'Incompelete') {
      return <span style={{ background: "#f07959", height: "16px" ,fontWeight:'bold' }}>INCOMPLETE</span>;
    } else if (visaObj?.visaStatusDescription ==="New") {
      return <span style={{ background: "#f3f781", height: "16px",fontWeight:'bold' }}>NEW</span>;
    } else if (visaObj?.visaStatusDescription ==="Replied") {
      return <span style={{ background: "#49e7f2", height: "16px" ,fontWeight:'bold'}}>REPLIED</span>;
    } else if(visaObj?.visaStatusDescription ==="Approved"){
      return <span style={{ background: "#5fde81",fontWeight:'bold' }}>APPROVED</span>;
    }
    else if(visaObj?.visaStatusDescription ==="Validated"){
      return <span style={{ background: "#a4dbed",fontWeight:'bold' }}>Waiting for Approval</span>;
    }
    
    else if(visaObj?.visaStatusDescription ==="Denied"){
      return <span style={{ background: "red",fontWeight:'bold' }}>DENIED</span>;
    }
  }
  
  
  return (
    <div className="form-demo m-2">
      {loading ? <SnipperModal /> : <> {null}</>}
      <Dialog
      //visible={showMessage}
      // onHide={() => setShowMessage(false)}
      // position="top"
      //footer={dialogFooter}
      //showHeader={false}
      //breakpoints={{ "960px": "80vw" }}
      //style={{ width: "30vw" }}
      >
        <div className="flex justify-content-center flex-column pt-6 px-3">
          <i
            className="pi pi-check-circle"
            style={{ fontSize: "5rem", color: "var(--green-500)" }}
          ></i>

        </div>
      </Dialog>
      <div className="row">
        <div className="col-md-3">
        </div>
        <div className="col-md-6">
          <div className="card my-3">
            <br />
            <h5 className="text-left ml-4" style={{ textAlign: "center", textTransform: 'uppercase' }}> Visa Status</h5>
            <hr style={{ maxWidth: "100%", marginLeft: "15px", marginTop: "0px" }} />
            <div className="row">
              <div className="col-md-6">
              <Card title="Personal Information">
                  <br />
                  <div className="align-left m-1">
                    <div className="formgrid grid">
                      {visaObj?.attachments?.map((attach) =>
                        attach?.isProfilePhoto ? (
                          <div className="field lg:col-2 md:col-4 sm:col-12" key={attach?.attachmentPath}>
                            <Image className="pp" src={attach?.attachmentPath} width="60%" alt="Profile Photo" preview />
                          </div>
                        ) : null
                      )}
                    </div>
                  </div>
                  <div className="align-left m-2">
                    <p className="m-2 p-0">
                      <b>Given Name: </b>
                      <span> {visaObj?.firstName} </span>
                    </p>
                    <p className="m-2 p-0">
                      <b> Surname: </b>
                      <span>{visaObj?.surName}</span>
                    </p>
                    <p className="m-2 p-0">
                      <b> Gender: </b>
                      <span>{visaObj?.gender}</span>
                    </p>
                    <p className="m-2 p-0">
                      <b>Birth Country: </b>
                      <span>{visaObj?.birthCountryDescription}</span>
                    </p>
                    <p className="m-2 p-0">
                      <b>Citizenship  : </b>
                      <span>{visaObj?.citizenshipDescription}</span>
                    </p>
                  </div>
                </Card>
              </div>
              <div className="col-md-6">
                <Card title="Visa Information">
                  <div className="align-left m-2" >

                    <p className="m-2 p-0">
                      <b>Visa Status: </b>
                      <span style={{width:"150px", height: "50px"}}> {checkStatus()}</span>
                    </p>
                    <p className="m-2 p-0">
                      <b> Payment Status: </b>
                      <span>{visaObj?.paymentStatusDescription}</span>
                    </p>
                    <p className="m-2 p-0">
                      <b>Request Date: </b>
                      <span>  {visaObj?.startDate
                        ? formatDate(visaObj?.startDate)
                        : ""}{" "} </span>
                    </p>
                    <p className="m-2 p-0">
                      <b>Reference Number: </b>
                      <span> {visaObj?.referenceNumber}</span>
                    </p>
                    <p className="m-2 p-0">
                      <b>Arrival Date: </b>
                      <span> {visaObj?.arrivalDate
                        ? formatDate(visaObj?.arrivalDate)
                        : ""}{" "} </span>
                    </p>
                    <br />
                    <br />
                    <hr style={{ maxWidth: "100%", marginLeft: "15px", marginTop: "0px" }} />
                 <br />
                    <p>
                      {completeButton()}
                      {PaymentButton()}
                      {downloadButton()}
                    </p>
                  </div>
                </Card>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
              <Card title="Passport Information">
                  <div className="align-left m-2" >
                  <p className="m-2 p-0">
                      <b>Passport Number: </b>
                      <span>  {visaObj?.passportNumber}
                        </span>
                    </p>
                    
                    <p className="m-2 p-0">
                      <b>Passport Issue Date: </b>
                      <span>  {visaObj?.passportIssueDate
                        ? formatDate(visaObj?.passportIssueDate)
                        : ""}{" "} </span>
                    </p>
                    <p className="m-2 p-0">
                      <b>Passport Expiry Date: </b>
                      {/* //<span> {visaObj?.passportExpireDate} </span> */}
                      <span>
                        {visaObj?.passportExpireDate
                          ? formatDate(visaObj?.passportExpireDate)
                          : ""}{" "}
                      </span>
                    </p>
                    <p className="m-2 p-0">
                      <b>Passport Type: </b>
                      <span> {visaObj?.passportTypeDescription} </span>
                    </p>
                    <p className="m-2 p-0">
                      <b>Passport Issuing Country</b>
                      <span> {visaObj?.issuingCountryDescription} </span>
                    </p>
                    <p className="m-2 p-0">
                      <b>Passport Issuing Authority:</b>
                      <span> {visaObj?.issuingAuthority} </span>
                    </p>
                    <p className="m-2 p-0">
                      <b>Departure Country : </b>
                      <span> {visaObj?.departureCountryDescription}</span>
                    </p>
                  </div>
                </Card>
              </div>
              <div className="col-md-6">
                <Card title="Address Information">
                  <div className="align-left m-2">
                    <p className="m-2 p-0">
                      <b>Departure Country</b>
                      <span> {visaObj?.departureCountryDescription} </span>
                    </p>
                    <p className="m-2 p-0">
                      <b>Departure City: </b>
                      <span>{visaObj?.departureCity}</span>
                    </p>
                    <p className="m-2 p-0">
                      <b>Airlines: </b>
                      <span>{visaObj?.airlines}</span>
                    </p>
                    <p className="m-2 p-0">
                      <b>FlightN umber: </b>
                      <span> {visaObj?.flightNumber} </span>
                    </p>
                    <p className="m-2 p-0">
                      <b>Address Country:</b>
                      <span> {visaObj?.countryAdressDescription} </span>
                    </p>
                    <p className="m-2 p-0">
                      <b>City  Address</b>
                      <span> {visaObj?.cityAddress} </span>
                    </p>
                  </div>
                </Card>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
                <Card title="Accommodation Information">
                  <div className="align-left m-2" >
                    <p className="m-2 p-0">
                      <b>Accommodation Name: </b>
                      <span> {visaObj?.accommodationName} </span>
                    </p>
                    <p className="m-2 p-0">
                      <b>Accommodation City: </b>
                      <span> {visaObj.accommodationCity} </span>
                    </p>
                    <p className="m-2 p-0">
                      <b>Phone Number: </b>
                      <span> {visaObj?.accommodationPhonenumber} </span>
                    </p>
                    <p className="m-2 p-0">
                      <b>Accommodation Adress: </b>
                      <span>
                        {visaObj?.accommodationAdress}
                      </span>
                    </p>
                  </div>
                </Card>
              </div>
              <div className="col-md-6">
              <div className="scrollpanel-demo">
              <Card title="Attachements">
              <ScrollPanel style={{ height: "300px" }} className="custombar1">
                                {visaObj?.attachments?.map((attach) =>
                                    attach?.isProfilePhoto ? null : (
                                        <div className="col-12" key={attach?.attachmentPath}>
                                            <div className="mb-4">
                                                {attach.fileType === ".pdf" ? (
                                                    <div >
                                                        <h6>{attach?.attachmentType}</h6>
                                                        <a href={attach.attachmentPath} download="passport">
                                                            <Button className="bg-pink-700" icon="pi pi-file-pdf  " iconPos="left" tooltip={attach.attachmentType}></Button>
                                                        </a>
                                                    </div>
                                                ) : (
                                                    <>
                                                        <div >
                                                            <h6>{attach?.attachmentType}</h6>
                                                            <Image src={attach?.attachmentPath} alt="attachment" width={250} preview />{" "}
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    )
                                )}
                            </ScrollPanel>
                            </Card>
                            </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class='row'>
        <div className="col-md-4"></div>
        <div className="text-center m-0">
          <Button
            onClick={redirectToHome}
            label="Back"
            className="p-button-info m-3"
          />
        </div>
      </div>
    </div>
  );

};
