import React from "react";
import { Link } from "react-router-dom";
import "./../../assets/css/footer.css";
import expres from './../../assets/Images/footer-logo/express.png'
import diners from './../../assets/Images/footer-logo/diners.png'
import alipay from './../../assets/Images/footer-logo/alipay.png'
import gpay from './../../assets/Images/footer-logo/gpay.png'
import mastercard from './../../assets/Images/footer-logo/mastercard.png'
import masterpass from './../../assets/Images/footer-logo/masterpass.png'
import unionpay from './../../assets/Images/footer-logo/unionpay.png'
import visa from './../../assets/Images/footer-logo/visa.png'
import wechat from './../../assets/Images/footer-logo/wechat.png'

const Footer = () => {
  return (
    <>
      <div className="m-t-3"></div>
      <footer className="main-footer" role="contentinfo">
        <div className="footer-top p-y-2">
          <div className="container-fluid"></div>
        </div>
        <div className="footer-middle">
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-sm-6">
                <div className="footer-pad">
                  <h4 className="footer-title">Important Links</h4>
                  <address>
                    <ul className="list-unstyled evisa-external-link">
                      <li>
                        <a href="https://www.ethiopianairlines.com" target="_blanck">Ethiopian Airlines</a>
                      </li>
                      <li>
                      <a href="https://www.ethiopianholidays.com" target="_blanck">Ethiopian Holidays</a>

                      </li>
                      <li>
                      <a href="https://www.ethiopianskylighthotel.com" target="_blanck">Ethiopian Skylight Hotel</a>
                      </li>
                    </ul>
                  </address>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="footer-pad">
                  <h4 className="footer-title">Services</h4>
                  <ul className="list-unstyled">
                    <li>
                      <Link className="footer-link" to="/visa">
                        E-Visa Application
                      </Link>
                    </li>
                    <li>
                      <Link className="footer-link" to="/visa-extension">
                        E-Visa Extension
                      </Link>
                    </li>
                    <li>
                      <Link className="footer-link" to="/status">
                        Status Check
                      </Link>
                    </li>
                    <li>
                      <Link className="footer-link" to="/Company">
                        {" "}
                        Company Registration
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="footer-pad">
                  <h4 className="footer-title">Information</h4>
                  <ul className="list-unstyled">
                    <li>
                      <Link className="footer-link" to="/information">
                        Visa Requirments
                      </Link>
                    </li>
                    <li>
                      <Link className="footer-link" to="/information/tourist">
                        Tourist Visa
                      </Link>
                    </li>
                    <li>
                      <Link className="footer-link" to="/information/InvestmentVisa">
                        Business Visa
                      </Link>
                    </li>
                    <li>
                      <Link className="footer-link" to="/faq">
                        FAQ
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="footer-pad">
                  <h4 className="footer-title">Contact</h4>
                  <ul className="list-unstyled">
                  <li>
                      <Link className="footer-link" to="/">
                      <i className="pi pi-map-marker" style={{'fontSize': '1em'}}></i>  address: Addis Ababa
                      </Link>
                    </li>
                    <li>
                      <Link className="footer-link" to="/">
                      <a href="tel:+251111262635" >

                      <i className="pi pi-phone" style={{'fontSize': '1em'}}></i> +251 (11) 1262635</a>
                      </Link>
                    </li>
                    <li>
                      <Link className="footer-link" to="/">
                      <Link onClick={() => window.location = 'mailto:support@evisa.gov.et'}>
                      <i className="pi pi-envelope" style={{'fontSize': '1em'}}></i>support@evisa.gov.et
                      </Link>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="evisa-footer-logo text-center justify-content-center">
          <img src={expres} alt="American Express" />
            <img src={diners} alt="Diners Club International" />
            <img src={mastercard} alt="MasterCard" />
            <img src={masterpass} alt="Masters pass" />
            <img src={visa} alt="VISA" />
            <img src={unionpay} alt="Union Pay" />
            <img src={gpay} alt="Google Pay" />
            <img src={alipay} alt="AliPay" />
            <img src={wechat} alt="WeChat Pay" />
        </div>

        <div className="footer-bottom">
          <hr className="footer-break"/>
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-xs-12">
              <p className="text-xs-center d-inline">
              &copy; 2017 - {new Date().getFullYear()} Immigration And Citizenship Service.
             </p>
              </div>
              <div className="col-md-4 col-xs-12">
              <p className="d-inline">  <Link  to="/termsandconditions">
                        Terms &amp; Conditions
                      </Link></p>
                </div>
                <div className="col-md-4 col-xs-12">
                <p>Powered by:<b> Ethiopian Airlines</b></p>
                </div>


            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
