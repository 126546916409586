import React from "react";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import "./evisaStyle.css";
export default function InvestmentVisa() {
  return (
    <div className="text-left">
      <h4 className="evisa-title">Religion Visa (RV)</h4>
      <div className="divider"></div>
      <p className="text-left">
      Applicants who apply for an RV are foreign nationals who are entering Ethiopia to take part in religious activities.        </p>
      <h5 className="evisa-title">Requirements</h5>
      <h6 className="text-left">The applicant is required to present the following:</h6>
      <ul>
        <li>
        Recent passport-sized photograph
        </li>
        <li>
        	A passport which is valid for at least six (6) months from the intended entry date
        </li>
        <li>
        	A copy of the RV Religion visa, whether recently extended or last extended.          </li>
          <li>Invitation letter from the religious entity which the applicant was invited from.</li>
      </ul>
      <h5 className="evisa-title">Duration and Validity</h5>
      <p className="text-left">
      The validity of the visa starts from the date of intended entry. Applicants can apply for one of the following two RVs:        </p>
      <ul>
      <li> 	Single entry, 30-day RV        </li>
      <li>  Multiple entry 90-day RV        </li>
      </ul>
      <h5 className="evisa-title">Fee</h5>
      <div className="striped bordered text-center">
        <Table bordered responsive>
          <thead className="evisa-color">
            <tr>
              <th>Entry Type</th>
              <th>Maximum Duration</th>
              <th>Application Visa Fee (in USD)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Single Entry</td>
              <td>30 Days</td>
              <td>152</td>
            </tr>
            <tr>
              <td>Single Entry</td>
              <td>90 Days</td>
              <td>302</td>
            </tr>

          </tbody>
        </Table>
        <h5 className="evisa-title text-left">Extension</h5>
        <p className="text-left">
        Applicants can request an extension before the visa expires. The applicant is then eligible to visit the ICS Head Office in Addis Ababa to extend the RV.              </p>
        <p className="text-left">
        For individuals found of overstaying, they will be subject to fines and legal penalties.           </p>
        <Table bordered responsive>
          <thead className="evisa-color">
            <tr>
              <th>Entry Type</th>
              <th>Maximum Duration</th>
              <th>Extension Visa Fee (in USD)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Single Entry</td>
              <td>30 Days</td>
              <td>252</td>
            </tr>
            <tr>
              <td>Single Entry</td>
              <td>90 Days</td>
              <td>352</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <Link to="/visa">
        <Button className="mt-5 evisa-button">
          Apply for Religion Visa Now
        </Button>
      </Link>
    </div>
  );
}
