import React, { useState, useRef, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Link, NavLink } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axiosInstance, { SystemToken } from "./../../utils/axios";
import SnipperModal from "../../components/common/modal/snipperModal";
import { json, useNavigate } from "react-router-dom";
import axios from "axios";
import { InputText } from "primereact/inputtext";
import "./login.css";
import { Toast } from "primereact/toast";
import CompanyService from "../../service/company/CompanyService";
import Cookies from "universal-cookie";

const theme = createTheme();
export const Login = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const toast = useRef(null);
  const cookies = new Cookies();
  const baseTemp = window['env']['API_SERVICE_BASE_URL'] + '/';
  const baseURL = baseTemp ? baseTemp : "https://api.dev.evisa.gov.et/";

  const clientLogin=()=>{
    const Url = baseURL + "identity-server/api/v1/Client/login";
    axios
    .post(Url, {
        clientId: window['env']['API_SERVICE_CLIENT_ID'],
        clientsecret: window['env']['API_SERVICE_CLIENT_SECRECT'],
    })
    .then(function (response) {
        localStorage.setItem("accessToken", response.data.accessToken);
        cookies.set("refreshToken", response.data.refreshToken);
    })
    .catch(function (error) {
        //  window.location.reload();
    });
  }

useEffect(()=>{
  clientLogin();
},[]);

  let [loading, setLoading] = useState(false);
  const [loginObj, setLoginObj] = useState({
    userName: "",
    password: "",
  });
  // const navigate = useNavigate();
  const handleLoginSubmit = async () => {
    setLoading(true);
    const companyservice = new CompanyService();
    if (loginObj.password !== "" && loginObj.userName !== "") {
      companyservice.signInCompany({
        userName: loginObj.userName,
        password: loginObj.password,
      }).then((resp) => {
        console.log(resp);
        localStorage.setItem(
          "companyLoginResponse",
          JSON.stringify(resp.data.payload)
        );
        localStorage.setItem("idToken", resp.data?.payload?.userLoginRes?.idToken)

        localStorage.setItem(
          "companyProfileRes",
          JSON.stringify(resp.data?.payload?.companyProfileRes)
        )
        localStorage.setItem(
          "userLoginRes",
          JSON.stringify(resp.data?.payload?.userLoginRes)
        )
        localStorage.setItem("isUserLogedIn", "true")
        // navigate("/dashboard");
        //window.location.reload();
        window.location.replace("/dashboard");
        setLoading(false);
      })
        .catch((err) => {
          setLoading(false);
          toast.current.show({ severity: 'error', summary: 'Error Message', detail: "Invalid Username or Password", life: 4000 })
        });
    } else {
      setErrorMessage("Please enter Username and Password");
      setLoading(false);
    }
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setErrorMessage("");
    setLoginObj((prevState) => ({
      ...prevState,

      [name]: value,
    }));
  };
  return (
    <>
      <Toast ref={toast} />
      <div className="container">
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <div className="card my-3">
              <div className="card-body">
                {loading ? <SnipperModal /> : <> {null}</>}
                <ThemeProvider theme={theme}>
                  <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        sx={{
                          m: 0,
                          bgcolor: "primary.main",
                          width: "70px",
                          height: "70px",
                        }}
                      >
                        <LockOutlinedIcon
                          style={{ width: "50px", height: "50px" }}
                        />
                      </Avatar>

                      <Typography component="h1" variant="h5" className="m-3">
                        Please Sign in here
                      </Typography>
                      {errorMessage === "" ? null : (
                        <Typography
                          component="p"
                          variant="p"
                          style={{ color: "#F55938" }}
                        >
                          {errorMessage}
                        </Typography>
                      )}

                      <Box noValidate>

                        <InputText
                          required
                          id="email"
                          name="userName"
                          autoComplete="username"
                          placeholder="Username"
                          size="small"
                          className="login-input"
                          autoFocus
                          onInput={(e) => handleChange(e)}
                        />

                        <InputText
                          required
                          name="password"
                          placeholder="Password"
                          type="password"
                          size="small"
                          id="password"
                          className="login-input"
                          autoComplete="current-password"
                          onInput={(e) => handleChange(e)}
                        />
                        <br />
                        <br />
                        <FormControlLabel
                          control={<Checkbox value="remember" color="primary" />}
                          label="Remember me"
                        />
                        <Button
                          type="button"
                          fullWidth
                          variant="contained"
                          onClick={handleLoginSubmit}
                        >
                          Sign In
                        </Button>
                        <Grid container>
                          <Grid item>
                            <Link to="/company" variant="body2">
                              {"Don't have an account? Sign Up"}
                            </Link>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item>
                            <Link to="/forgot-password" variant="body2">
                              {"Forgot Password?"}
                            </Link>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Container>
                </ThemeProvider>
              </div>
            </div>
          </div>
          <div className="col-md-4"></div>
        </div>
      </div>
    </>
  );
};

export default Login;
