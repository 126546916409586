import React from "react";
import { Link } from "react-router-dom";

function Visa() {
  return (
    <>
      <div
        className="container passport-container pt-3"
        id="request-an-appointment"
        fluid
      >
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8 text-left">
            <div className="multistep-form__step ">
              <h5 className="float-left mb-3 text-center">
                {" "}
                Select the application option you want to proceed with:{" "}
              </h5>

              <div className="row align-center vertical-margin-2">
                <div className="small-11 column">
                  <div class="request-card card card--small-gutters card--shadow text-center row">
                    <div class="small-12 column">
                      <Link
                        class="card card-link card-teal card-tile"
                        to="/visa"
                      >
                        <div class="card__content text-white">
                          <h2 class="text-center">
                            New Visa Application
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <i class="start-application-icon pi pi-arrow-circle-right"></i>
                          </h2>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row align-center vertical-margin-2">
              <div className="small-11 column">
                <div class="request-card card card--small-gutters card--shadow text-center row">
                  <div class="small-12 column">
                    <Link
                      class="card card--link card--teal card--tile"
                      to="/visa-extension"
                    >
                      <div class="card__content text-white">
                        <i class="fas fa-user fa-7x"></i>
                        <p class="vertical-margin-1">
                          <h2 class="text-link--more-big text-link--light">
                            Visa Extension
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <i class="start-application-icon pi pi-arrow-circle-right"></i>
                          </h2>
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-2"></div>
        </div>
      </div>
    </>
  );
}
export default Visa;
