import React from "react";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import "./evisaStyle.css";
export default function InvestmentVisa() {
  return (
    <div className="text-left">
      <h4 className="evisa-title">Student Visa (SV)</h4>
      <div className="divider"></div>
      <p className="text-left">
      Applicants who apply for an SV are foreign nationals who are entering Ethiopia temporarily to undertake a course of study in the country.           </p>
      <h5 className="evisa-title">Requirements</h5>
      <h6 className="text-left">The applicant is required to present the following:</h6>
      <ul>
        <li>
        Recent passport-sized photograph
        </li>
        <li>Entry stamp and Visa.</li>
        <li>
        	A passport which is valid for at least six (6) months from the intended entry date
        </li>
        <li>
        A letter of acceptance must be provided demonstrating that an applicant has been offered a place on a full-time course of study at a recognized educational institution as well as confirmation of Residency.            </li>
        <li>A letter of acceptance from the Institution where the study will take place. </li>
        <li>A copy of the SV Student visa, whether recently extended or last extended. </li>
      </ul>
      <h5 className="evisa-title">Duration and Validity</h5>
      <p className="text-left">
      The validity of the visa starts from the date of intended entry. Applicants can apply for one of the following two SVs:        </p>
      <ul>
      <li> Single entry, 30-day SV        </li>
      </ul>
      <h5 className="evisa-title">Fee</h5>
      <div className="striped bordered text-center">
        <Table bordered responsive>
          <thead className="evisa-color">
            <tr>
              <th>Entry Type</th>
              <th>Maximum Duration</th>
              <th>Application Visa Fee (in USD)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Single Entry</td>
              <td>30 Days</td>
              <td>62</td>
            </tr>

          </tbody>
        </Table>
        <h5 className="evisa-title text-left">Extension</h5>
        <p className="text-left">
        Student visa can’t be extended</p>
      </div>
      <Link to="/visa">
        <Button className="mt-5 evisa-button">
          Apply for Student Visa Now
        </Button>
      </Link>
    </div>
  );
}
