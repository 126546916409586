import axios from "axios";
import HttpService from "../shared/HttpService";

export default class CompanyService {

    signInCompany = (payload) => {
        let relativePath = "companyservice/api/v1/CompanyProfile/SignInCompany";
        return HttpService.postService(payload, relativePath,"CompanyProfile-SignInCompany");
    }

    getCompanyCategory = () => {
        let relativePath = "companyservice/api/v1/CompanyCategory/GetAll?recordStatus=2";
        return HttpService.getService(relativePath)
    }
    getRegion = () => {
        let relativePath = "masterdataservice/api/v1/Region/GetAll?status=2";
        return HttpService.getService(relativePath)
    }
    createCompany = (payload) => {
        let relativePath = "companyservice/api/v1/CompanyProfile/SignupCompany";
        return HttpService.postService(payload, relativePath)
    }
    applyForCompanyProfile = (payload) => {
        let relativePath = "companyservice/api/v1/CompanyApplication/Apply";
        return HttpService.postService(payload, relativePath,"Client-CompanyApplication-Apply")
    }

    getCompanyRequiredAttachment = (companyCategoryId) => {
        let relativePath = "companyservice/api/v1/CompanyCategoryAttachment/GetAttachments?CompanyCategoryId=" + companyCategoryId;
        return HttpService.getService(relativePath)
    }

    updateCompany = (payload) => {
        let relativePath = "companyservice/api/v1/CompanyProfile/Update";
        return HttpService.putService(payload, relativePath,"Client-CompanyProfile-Update")
    }

    changeCompanyPassword = (payload) => {
        let relativePath = "companyservice/api/v1/CompanyProfile/Changepassword";
        return HttpService.postService(payload, relativePath,"Client-CompanyProfile-Changepassword");
    }
    getCompanyApplicationList = () => {
        let relativePath = "companyservice/api/v1/CompanyApplication/GetMyApplications";
        return HttpService.getService(relativePath,"Client-CompanyApplication-List");
    }
    getCompanyProfile = () => {
        let relativePath = "companyservice/api/v1/CompanyProfile/GetDataById";
        return HttpService.getService(relativePath,"Client-CompanyProfile-GetDataById");
    }
    forgetPassword = (payload) => {
        let relativePath = "Identity-server/api/v1/Password/ForgotPassword?userName=" + payload.userName + "&isCompany=" + payload.isCompany;
        return HttpService.getService(relativePath,"VisaRequest-GetPendingVisaRequest")
    } 
    resetPassword = (payload) => {
        let relativePath = "Identity-server/api/v1/Password/ForgotPassword";
        return HttpService.postService(payload, relativePath,"Password-ForgotPassword");
    }
}


