import React from "react";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import "./evisaStyle.css";
export default function SportVisa() {
  return (
    <div className="text-left">
      <h4 className="evisa-title">Sports Competition and Training Visa (SPV)</h4>
      <div className="divider"></div>
      <p className="text-left">
      Applicants who apply for an SPV are foreign nationals entering Ethiopia as athletes coming to the country to participate in sports events and/or training. 
      </p>
      <h5 className="evisa-title">Requirements</h5>
      <h6>The applicant is required to present the following:</h6>
      <ul>
        <li>
        	Recent passport-sized photograph
        </li>
        <li>
        A passport which is valid for at least six (6) months from the intended entry date.
        </li>
        <li>
        A copy of the SPV foreign investor employee visa, whether recently extended or last extended. 
        </li>
      </ul>

      <h5 className="evisa-title">Duration and Validity</h5>
      <p className="text-left">Single entry, 30-day SPV</p>
      <p className="text-left">Multiple entry 90-day SPV</p>
      <h5 className="evisa-title">Fee</h5>
      <div className="striped bordered text-center">
        <Table bordered responsive>
          <thead className="evisa-color">
            <tr>
              <th>Entry Type</th>
              <th>Maximum Duration</th>
              <th>Application Visa Fee (in USD)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Single Entry</td>
              <td>30 Days</td>
              <td>82</td>
            </tr>
            <tr>
              <td>Single Entry</td>
              <td>90 Days</td>
              <td>202</td>
            </tr>
          </tbody>
        </Table>
        <h5 className="evisa-title text-left">Extension</h5>
        <p className="text-left">
        Sports competition and training visa can't be extended.


        </p>
  
      </div>
      <Link to="/visa">
        <Button className="mt-5 evisa-button">
          Apply for Sports Competition and Training Visa Now
        </Button>
      </Link>
    </div>
  );
}
