import React from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import {  Link } from "react-router-dom"

const PaymentSuccess = () => {
    return (
        <div>
<br/>
<div class="container">
                <h4>
                    <div aria-live="polite" className="p-inline-message p-component p-inline-message-info" role="alert">
                        <span className="p-inline-message-icon pi pi-info-circle"></span>
                        <span>If you haven't received information about your visa status, feel free to revisit this website to check your visa status and download your visa documents.
                            <Link to="/status"  target="_blank"  rel="noreferrer" style={{ textDecoration: 'underline' }}>Link to check your visa status.</Link>
                        </span>
                    </div>
                </h4>
                {/* <div>
          <h5>
        <FaExclamationTriangle style={{ color: 'red', marginRight: '8px' }} />
        <span style={{ color: 'red' }}>
          We are temporarily unable to accept MasterCard due to technical issues.
        </span>
      </h5>
    </div> */}
    <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-4 paymentstatusbody">
            <div class="login-box-body text-Color">
                <br />
                <div class="evisa-status-succes">
                    <br />
                    <center><span class="text-white fa fa-check fa-3x"></span></center>
                    <center>
                        <h3 class="text-white">Payment Authorized</h3>
                    </center>
                </div>

                <div class="form-group">
                    <h6>
                        <br /> Your payment has been authorized. <br /><br />We will notify you of the status of your visa request via the email you provided.<br />
                        <br />You can also follow up the status of your request online <a data-sf-ec-immutable="" href="/status" sfref="[f669d9a7-009d-4d83-ddaa-000000000002]EC0B4961-F3D5-43AE-9B6D-D9122204288C" target="_blank"  rel="noreferrer">here</a>.
                    </h6>
                </div>
            </div>
        </div>
        <div class="col-md-4"></div>
    </div>

</div>
<br/>

        </div>
    );
};

export default PaymentSuccess;