import { ActionTypes } from "../types/ActionTypes";


export const visaCategoryReducer = (state = [], { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_VISA_CATEGORY:
      return { ...state, visaCategoryPayload: payload };
    default:
      return state;
  }
};
export const arrivalInformationReducer = (state = [], { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_ARRIVAL_INFORMATION:
      return { ...state, arrivalPayload: payload };
    default:
      return state;
  }
};
export const personalInformationReducer = (state = [], { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_PERSONAL_INFORMATION:
      return { ...state, personalPayload: payload };
    default:
      return state;
  }
};
export const passportInformationReducer = (state = [], { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_PASSPORT_INFORMATION:
      return { ...state, passportPayload: payload };
    default:
      return state;
  }
};
export const supportingDocReducer = (state = [], { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_SUPPORTING_DOCUMENT:
      return { ...state, documentPayload: payload };
    default:
      return state;
  }
};


