import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import VisaService from "../../service/visa/VisaService";
import { StatusView } from "./../status/StatusView";
import SnipperModal from "../../components/common/modal/snipperModal";
import { useNavigate } from "react-router-dom";
import CAPTCHA from "react-google-recaptcha";
import { Viewer } from '@react-pdf-viewer/core';

export const DownloadVisa = () => {
  const [showMessage, setShowMessage] = useState(false);
  const [visaDetail, setVisaDetail] = useState({});
  const [isgetSucces, setIsgetSucces] = useState(false);
  let [loading, setLoading] = useState(false);
  const [errorResponse, setErrorResponse] = useState("");
  const [submitError, setSubmitError] = useState(false);
  const [base64String,setBase64String]=useState("");
  const [recaptchaSelected, setRecaptchaSelected] = useState(true);
  const captchaRef = useRef(null)
  useEffect(() => {}, []);
  const formik = useFormik({
    initialValues: {
      passportNumber: "",
      referenceNumber: "",
    },
    validate: (data) => {
      let errors = {};
      if (!data.passportNumber) {
        errors.passportNumber = "Passport number is required.";
      }
      if (!data.referenceNumber) {
        errors.referenceNumber = "Reference number is required.";
      }
      return errors;
    },
    onSubmit: (data) => {
      // const token = captchaRef.current.getValue();
      // if(token!==""){
      //   setRecaptchaSelected(true);
      setLoading(true);
      const visaDataService = new VisaService();
      visaDataService
        .downloadVisa(data)
        .then((res) => {
          
        //   setBase64String(res.data.payload);
        //   setIsgetSucces(true);
        downloadPDF(res?.data.payload);
          formik.resetForm();
          setLoading(false);
        })
        .catch((err) => {
          
          setErrorResponse(err.response.data?.errors[0]);
          setSubmitError(true);
          setLoading(false);
        });
    //   }else{
    //     setRecaptchaSelected(false);

    // }
    },
  });
  const base64toBlob = (data) => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);

    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
        out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: 'application/pdf' });
};

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const convertToPdf=()=>{
    var obj = document.createele('object');
obj.style.width = '100%';
// obj.style.height = '842pt';
obj.type = 'application/pdf';
obj.data = 'data:application/pdf;base64,' + base64String;
document.body.appendChild(obj);
  }
  const blob = base64toBlob(base64String);
const url = URL.createObjectURL(blob);
  const dialogFooter = (
    <div className="flex justify-content-center">
      <Button
        label="OK"
        className="p-button-text"
        autoFocus
        onClick={() => setShowMessage(false)}
      />
    </div>
  );
  function downloadPDF(pdf) {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = "Visa.pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
}
  return (
    <div className="m-3">
      {loading ? <SnipperModal /> : <> {null}</>}

      {isgetSucces ? (
                //   <a href={url} title='Download pdf document'>ssss</a>
                // <Viewer fileUrl={url} />
                <button onClick={()=>{downloadPDF(base64String)}}>download</button>
                

      ) : (
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4">
              <div className="flex justify-content-center">
                <div className="card">
                  <div
                    className="p-4 text-white"
                    style={{ backgroundColor: "#004d99" }}
                  >
                    <h5 className="text-center mt-2">
                      <i
                        className="pi pi-download mx-2"
                        style={{ fontSize: "1em", fontWeight: "bold" }}
                      ></i>
                      <b>Download Visa</b>
                    </h5>
                  </div>
                  <h5 className="text-danger">
                    {" "}
                    {submitError ? errorResponse : null}
                  </h5>
                  <form onSubmit={formik.handleSubmit} className="p-fluid">
                    <div className="field mb-4 mx-2">
                      <span className="p-float-label">
                        <InputText
                          id="passportNumber"
                          name="passportNumber"
                          value={formik.values.passportNumber}
                          onChange={formik.handleChange}
                          autoFocus
                          className={classNames({
                            "p-invalid": isFormFieldValid("passportNumber"),
                          })}
                        />
                        <label
                          htmlFor="passportNumber"
                          className={classNames({
                            "p-error": isFormFieldValid("passportNumber"),
                          })}
                        >
                          Passport Number*
                        </label>
                      </span>
                      {getFormErrorMessage("passportNumber")}
                    </div>
                    <div className="field mb-4 mx-2">
                      <span className="p-float-label">
                        <InputText
                          id="referenceNumber"
                          name="referenceNumber"
                          value={formik.values.referenceNumber}
                          onChange={formik.handleChange}
                          autoFocus
                          className={classNames({
                            "p-invalid": isFormFieldValid("referenceNumber"),
                          })}
                        />
                        <label
                          htmlFor="referenceNumber"
                          className={classNames({
                            "p-error": isFormFieldValid("referenceNumber"),
                          })}
                        >
                          Reference Code*
                        </label>
                      </span>
                      {getFormErrorMessage("referenceNumber")}
                    </div>
                    {/* <div className='mx-2 mb-2'>
                       <CAPTCHA
                    sitekey={window['env']['EVISA_GOOGLERC_SITE_KEY']} 
                    ref={captchaRef}
                    
                    />        {recaptchaSelected?null:<span className='text-danger'>Please select the recaptcha!</span>} 
                       </div> */}
                    <div className="field mb-4 mx-2">
                      <Button
                        type="submit"
                        label="Submit"
                        className="p-button-info"
                      />
                    </div>
                  </form>
                </div>
              </div>
          </div>
          <div className="col-md-4">
          {/* {url?<Viewer fileUrl={url} />:null} */}

          </div>
        </div>
      )}
    </div>
  );
};
