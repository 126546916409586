import React, {
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import VisaCategory from "../visa/stepComponents/visaCategory";
import ArrivalInfo from "../visa/stepComponents/arrivalInformation";
import PersonalInfo from "../visa/stepComponents/personalInformation";
import PassportInformation from "./stepComponents/PassportInformation";
import VisaRequiredAttachment from "./stepComponents/VisaRequiredAttachment";
import Summary from "./stepComponents/Summary";
import { Tab, Nav, Button, Card } from "react-bootstrap";
import { MDBCol, MDBRow } from "mdbreact";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {
  BsCheck,
  BsFillFileEarmarkFill,
  BsArrowRightShort,
  BsArrowLeftShort,
  BsHouseFill,
  BsCaretRightFill,
  BsPersonLinesFill,
  BsFileEarmarkPdfFill,
  BsCardChecklist,
  BsUpload,
} from "react-icons/bs";
import VisaService from "../../service/visa/VisaService";

const RequestStepper = forwardRef((props, ref) => {
  const [indexValue, setIndexValue] = useState(0);
  const [formCompleted, setFormCompleted] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const activeKey = ["first", "second", "third", "fourth", "Fivth", "sixth"];
  const visaCategoryRef = useRef();
  const arrivalInfoRef = useRef();
  const personalInfoRef = useRef();
  const passportRef = useRef();
  const supportingDocRef = useRef();
  const summaryRef = useRef();



  useEffect(()=>{
  
  },[])
  function handelNext() {
    if (indexValue === 0) {
      visaCategoryRef.current.saveData();
      let isValid = visaCategoryRef.current.Validate();
      if (isValid) {
        setIndexValue((prevActiveStep) => prevActiveStep + 1);
        formCompleted[indexValue] = true;
      }
    } else if (indexValue === 1) {
      arrivalInfoRef.current.saveData();
      let isValid = arrivalInfoRef.current.Validate();
      console.log("valid???", isValid)
      if (isValid) {
        setIndexValue((prevActiveStep) => prevActiveStep + 1);
        formCompleted[indexValue] = true;
      }
    } else if (indexValue === 2) {
      personalInfoRef.current.saveData();
      if (personalInfoRef.current.Validate() === true) {

        setIndexValue((prevActiveStep) => prevActiveStep + 1);
        formCompleted[indexValue] = true;
      }
    } else if (indexValue === 3) {
      passportRef.current.saveData();
      if (passportRef.current.Validate() === true) {
        setIndexValue((prevActiveStep) => prevActiveStep + 1);
        formCompleted[indexValue] = true;
      }
    } else if (indexValue === 4) {
      setIndexValue((prevActiveStep) => prevActiveStep + 1);
      formCompleted[indexValue] = true;
      // }
    } else if (indexValue === 5) {
      supportingDocRef.current.saveData();
    }
  }
  function handelPrevious() {
    setIndexValue(indexValue - 1);
  }
  function handelVisaCategory() {
    setIndexValue(0);
  }
  function handelArrivalInfo() {
    setIndexValue(1);
  }
  function handelPersonalInfo() {
    setIndexValue(2);
  }
  function handelPassportInf() {
    setIndexValue(3);
  }
  function handelSummary() {
    setIndexValue(4);
  }
  function handelAttachment() {
    setIndexValue(5);
  }

  
  return (
    <Tab.Container defaultActiveKey="first" activeKey={activeKey[indexValue]}>
      <div style={{ margin: "2rem", textAlign: "left" }}>
        <Row>
        <Col variant="secondary" className="mb-4" xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>

          {/* <Col variant="secondary" lg={3} className="mb-4"> */}
            <Card>
              <Card.Header>Application Steps</Card.Header>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link
                    eventKey={activeKey[0]}
                    onClick={handelVisaCategory}
                    disabled={formCompleted[0] === true ? false : true}
                  >
                    {" "}
                    <BsCaretRightFill /> Visa Category{" "}
                    {formCompleted[0] ? <BsCheck /> : null}{" "}
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link
                    eventKey={activeKey[1]}
                    onClick={handelArrivalInfo}
                    disabled={formCompleted[1] === true ? false : true}
                  >
                    {" "}
                    <BsHouseFill /> Arrival Information{" "}
                    {formCompleted[1] ? <BsCheck /> : null}{" "}
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link
                    eventKey={activeKey[2]}
                    onClick={handelPersonalInfo}
                    disabled={formCompleted[2] === true ? false : true}
                  >
                    {" "}
                    <BsPersonLinesFill /> Personal Information{" "}
                    {formCompleted[2] ? <BsCheck /> : null}{" "}
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link
                    eventKey={activeKey[3]}
                    onClick={handelPassportInf}
                    disabled={formCompleted[3] === true ? false : true}
                  >
                    {" "}
                    <BsFillFileEarmarkFill /> Passport Information{" "}
                    {formCompleted[3] ? <BsCheck /> : null}{" "}
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link
                    eventKey={activeKey[4]}
                    onClick={handelSummary}
                    disabled={formCompleted[4] === true ? false : true}
                  >
                    {" "}
                    <BsCardChecklist /> Review {" "}
                    {formCompleted[5] ? <BsCheck /> : null}{" "}

                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link
                    eventKey={activeKey[5]}
                    onClick={handelAttachment}
                    disabled={formCompleted[5] === true ? false : true}
                  >
                    {" "}
                    <BsFileEarmarkPdfFill /> Attachments{" "}
                    {formCompleted[4] ? <BsCheck /> : null}{" "}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Card>
          </Col>
          <Col xs={12} sm={12} md={12} lg={9} xl={9} xxl={9}>
            <Tab.Content>
              <Tab.Pane eventKey={activeKey[0]}>
                <VisaCategory ref={visaCategoryRef} />
              </Tab.Pane>

              <Tab.Pane eventKey={activeKey[1]}>
                <ArrivalInfo ref={arrivalInfoRef} />
              </Tab.Pane>
              <Tab.Pane eventKey={activeKey[2]}>
                <PersonalInfo ref={personalInfoRef} />
              </Tab.Pane>

              <Tab.Pane eventKey={activeKey[3]}>
                <PassportInformation ref={passportRef} />
              </Tab.Pane>

              <Tab.Pane eventKey={activeKey[4]}>
                <Summary ref={summaryRef} />
              </Tab.Pane>

              <Tab.Pane eventKey={activeKey[5]}>
                < VisaRequiredAttachment ref={supportingDocRef} />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
        {indexValue === 6 ? null : (
          <MDBRow className="mt-3">
            <Col xs={0} sm={0} md={0} lg={3} xl={3} xxl={3}></Col>
            <Col>
              <Row>
                <Col>
                  <Button
                    variant="outline-primary"
                    onClick={handelPrevious}
                    disabled={indexValue === 0 ? true : false}
                    className="align-left"
                  >
                    <BsArrowLeftShort /> previous
                  </Button>{" "}
                </Col>
                <Col className=" next-button">
                  {indexValue === 5 ? (
                    <Button variant="primary" onClick={handelNext} className="align-right">
                      <span>Submit</span>
                      {/* <BsUpload /> */}
                    </Button>
                  ) : (
                    <Button variant="primary" className="align-right" onClick={handelNext}>
                      &nbsp; &nbsp;&nbsp; Next
                      <BsArrowRightShort />&nbsp;&nbsp;&nbsp;
                    </Button>
                  )}
                </Col>
              </Row>
            </Col>
          </MDBRow>
        )}
      </div>
    </Tab.Container>
  );
});
export default RequestStepper;
