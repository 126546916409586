import { Button } from '@mui/material';
import { Descriptions } from 'antd';
import { Toast } from 'primereact/toast';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import CompanyService from '../../../service/company/CompanyService';
import SnipperModal from '../../common/modal/snipperModal';

export default function DashboardComponent({ setTitle }) {
    useEffect(() => {
        setTitle("Dashboard")
    })
    const [errorMessage, seterrorMessage] = useState([]);

    const companySerivce = new CompanyService();
    const [data, setData] = useState();
    const [loadData, setLoadData] = useState(0);

    const companyId = JSON.parse(localStorage.getItem("companyLoginResponse"))?.companyProfileId
    localStorage.setItem("selectedmenu", 1)
    const userId = JSON.parse(localStorage.getItem("userLoginRes"))?.id
    const hasApplcation = JSON.parse(localStorage.getItem("companyLoginResponse"))?.hasApplication

    const attachements = [];
    data?.companyAttachmentsDTO?.map((value) => {
        attachements.push(value)
    })

    const [loading, setLoading] = useState(false);
    const toast = useRef(null);

    const companyService = new CompanyService();

    useEffect(() => {
        setLoading(true);
        companyService.getCompanyProfile()
            .then((res) => {
                setData(res.data?.payload?.companyProfileRes)
                localStorage.setItem("companyProfileRes", JSON.stringify(res.data?.payload?.companyProfileRes))
                localStorage.setItem("companyLoginResponse", JSON.stringify(res.data?.payload));
                setLoading(false);

            }).catch((error) => {
               
                console.log("data ="+error.title);
                setLoading(false);
                seterrorMessage(error.title);
            })
    }, [loadData])

    const applyForCompany = () => {
        setLoading(true);
        const id = JSON.parse(localStorage.getItem("companyLoginResponse"))?.companyProfileRes?.id
        const requestBody = {
            companyProfileId: parseInt(id),
        };

        companySerivce
            .applyForCompanyProfile(requestBody)
            .then((res) => {
                setLoading(false);
                setLoadData(1);
                toast.current.show({
                    severity: "success",
                    summary: "Successful",
                    detail: "Successfully Applied for Company",
                    life: 3000,
                });
            })
            .catch((err) => {
                setLoading(false);
                // console.log(err)
                toast.current.show({
                    severity: "error",
                    summary: "Error occured",
                    detail: err?.response.data.errors[0],
                    life: 3000,
                });
            });
    };

    return (
        <div>
            <Toast ref={toast} />
            {loading ? <SnipperModal /> : <> {null}</>}
            <Descriptions
                title={<h5 style={{ paddingLeft: "1rem", paddingTop: "1rem" }}>Company Profile</h5>}
                labelStyle={{ fontFamily: "", fontWeight: "bold", fontSize: "0.8rem", marginLeft: "2px" }}
                contentStyle={{ maxWidth: "10rem", fontSize: "1rem", marginLeft: "2px" }}
                bordered column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                style={{ maxWidth: "60rem", background: "white", borderRadius: "15px" }}
            >
                <Descriptions.Item label="Company Name">{data?.name}</Descriptions.Item>
                <Descriptions.Item label="Telephone">{data?.telephone}</Descriptions.Item>
                <Descriptions.Item label="Company Manager Email Address">{data?.companyManagerEmailAddress}</Descriptions.Item>
                <Descriptions.Item label="Company Category">{data?.companyCategory?.category}</Descriptions.Item>
                <Descriptions.Item label="TIN Certificate Issued Date">{String(data?.tinCertificateIssuedDate).split('T')[0]}</Descriptions.Item>
                <Descriptions.Item label="TIN Certificate Expiry Date">{String(data?.tinCertificateExpiryDate).split('T')[0]}</Descriptions.Item>
                <Descriptions.Item label="Business License Issued Date">{String(data?.businessLicenseIssuedDate).split('T')[0]}</Descriptions.Item>
                <Descriptions.Item label="Business License Expiry Date">{String(data?.businessLicenseExpiryDate).split('T')[0]}</Descriptions.Item>
                <Descriptions.Item label="Passport Expiry Date">{String(data?.passportExpiryDate).split('T')[0]}</Descriptions.Item>
                <Descriptions.Item label="Tax Payer Identification Number">{data?.taxPayerIdentificationNumber}</Descriptions.Item>
            </Descriptions>

            <Descriptions
                title={<h5 style={{ paddingLeft: "1rem", paddingTop: "1rem" }}>Address</h5>}
                labelStyle={{ fontFamily: "", fontWeight: "bold", fontSize: "0.8rem", marginLeft: "2px" }}
                contentStyle={{ maxWidth: "10rem", fontSize: "1rem", marginLeft: "2px" }}
                bordered column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                style={{ marginTop: "3rem", maxWidth: "50rem", background: "white", borderRadius: "15px" }}
            >
                <Descriptions.Item label="Region">{data?.region}</Descriptions.Item>
                <Descriptions.Item label="Zone">{data?.zone}</Descriptions.Item>
                <Descriptions.Item label="City">{data?.city}</Descriptions.Item>
                <Descriptions.Item label="Sub-City">{data?.subcity}</Descriptions.Item>
                <Descriptions.Item label="Woreda">{data?.woreda}</Descriptions.Item>
                <Descriptions.Item label="Kebele">{data?.kebele}</Descriptions.Item>
            </Descriptions>

            {attachements.length > 0 ?
                <Descriptions
                    title={<h5 style={{ paddingLeft: "1rem", paddingTop: "1rem" }}>Attachements</h5>}
                    labelStyle={{ fontFamily: "", fontWeight: "bold", fontSize: "0.8rem", marginLeft: "2px" }}
                    contentStyle={{ maxWidth: "10rem", fontSize: "1rem", marginLeft: "2px" }}
                    bordered column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                    style={{ marginTop: "3rem", maxWidth: "50rem", background: "white", borderRadius: "15px" }}
                >
                    {attachements.map((value) => {
                        return <Descriptions.Item label={value?.attachmentTypename} key={value?.attachmentpath}><a href={value?.attachmentpath}>Download</a></Descriptions.Item>
                    })}
                </Descriptions>
                : null}
            <div style={{ marginTop: "2rem" }}></div>
            {!hasApplcation ?
                <Button variant='outlined' onClick={applyForCompany} style={{ background: "#0e2a47" }}>
                    <b className="text-white">Apply for Company</b>
                </Button>
                : null}
        </div>
    )
}