
import React, { useEffect, useState, useRef } from 'react';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import VisaService from "../../../service/visa/VisaService";
import { VisaExtensionApply } from './VisaExtensionApply';
import SnipperModal from "../../../components/common/modal/snipperModal";
import "../../../components/InfoDetails/evisaStyle.css"
import { Toast } from 'primereact/toast';
import CAPTCHA from "react-google-recaptcha";
import { VerifyGoogleCatpcha } from '../../../utils/VerifyGoogleCatpcha';
import { fas } from '@fortawesome/free-solid-svg-icons';
export const VisaExtensionIndex = () => {
    const toast = useRef(null);
    const [showMessage, setShowMessage] = useState(false);
    const [formData, setFormData] = useState({});
    const [visaDetail, setVisaDetail] = useState({});
    const [isgetSucces, setIsgetSucces] = useState(false);
    let [loading, setLoading] = useState(false);
    const [errorResponse, setErrorResponse] = useState('');
    const [submitError, setSubmitError] = useState(false);
    const [recaptchaSelected, setRecaptchaSelected] = useState(true);
    const [files, setfiles] = useState({});
    const captchaRef = useRef(null)

    useEffect(() => {
    }, []);
    const formik = useFormik({
        initialValues: {
            passportNumber: '',
            referenceNumber: '',
        },
        validate: (data) => {
            let errors = {};

            if (!data.passportNumber) {
                errors.passportNumber = 'Passport number is required.';
            }
            if (!data.referenceNumber) {
                errors.referenceNumber = 'Reference number is required.';
            }
            return errors;
        },
        onSubmit: (data) => {
            // const token = captchaRef.current.getValue();
            // if(token!==""){
            // setRecaptchaSelected(true)
            setLoading(true)
            const visaDataService = new VisaService();
            visaDataService
                .getVisa(data)
                .then((res) => {
                    setVisaDetail(res.data);
                    setIsgetSucces(true);
                    formik.resetForm();
                    setLoading(false)
                })
                .catch((err) => {
                    console.log(err.response.data)
                    setErrorResponse(err.response.data?.errors[0]);
                    setSubmitError(true);
                    setLoading(false)
                })
            // }else{
            //     setRecaptchaSelected(false);
                //   let valid_token =  VerifyGoogleCatpcha(token);
                // if(valid_token.success){
                //     setRecaptchaSelected(true)

                // }else{
                //     setRecaptchaSelected(false)
                // }
            // }
            
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    const dialogFooter = <div className="flex justify-content-center"><Button label="OK" className="p-button-text"
        autoFocus onClick={() => setShowMessage(false)} /></div>;
    return (
        <div className='m-2'>
            {loading ? <SnipperModal /> : <> {null}</>}

            {isgetSucces ? (<VisaExtensionApply visaDetail={visaDetail} />) : <div className='row'>
                <div className='col-md-4'></div>
                <div className='col-md-4' style={{ marginBottom: "2rem" }}>
                        <div className="flex justify-content-center">
                            <div className="card mt-3">
                                <div className='evisa-color div-height '> <h5 className="text-center mt-4 ">VISA EXTENSION</h5></div>
                                <h5 className='text-danger'> {submitError ? errorResponse : null}</h5>
                                <br/>
                                <form onSubmit={formik.handleSubmit} className="p-fluid">
                                    <div className="field mb-4 mx-2">
                                        <span className="p-float-label">
                                            <InputText id="passportNumber" name="passportNumber" value={formik.values.passportNumber} onChange={formik.handleChange}
                                                autoFocus className={classNames({ 'p-invalid': isFormFieldValid('passportNumber') })} />
                                            <label htmlFor="passportNumber" className={classNames({ 'p-error': isFormFieldValid('passportNumber') })}>Passport Number*</label>
                                        </span>
                                        {getFormErrorMessage('passportNumber')}
                                    </div>
                                    <div className="field mb-4 mx-2">
                                        <span className="p-float-label">
                                            <InputText id="referenceNumber" name="referenceNumber" value={formik.values.referenceNumber} onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('referenceNumber') })} />
                                            <label htmlFor="referenceNumber" className={classNames({ 'p-error': isFormFieldValid('referenceNumber') })}>Reference Code*</label>
                                        </span>
                                        {getFormErrorMessage('referenceNumber')}
                                    </div>
                                    
                  {/* <div className="field col-md-6">
                       <div className='mx-2 mb-2'>
                       <CAPTCHA
                    sitekey={window['env']['EVISA_GOOGLERC_SITE_KEY']} 
                    ref={captchaRef}
                    
                    />        {recaptchaSelected?null:<span className='text-danger'>Please select the recaptcha!</span>} 
                       </div>
                      </div> */}
                                    <div className="field mb-4 mx-2">
                                        <Button type="submit" label="Submit" className='evisa-button' />
                                    </div>
                                </form>
                            </div>
                            <div className='col-md-4'></div>
                        </div>

                    </div>
            </div>}
        </div>
    );
}
