import React from "react";

import "./style.css";

import data from "./data.json";
import {  useState } from "react";
import { Button } from "antd";

import { Divider } from "antd";

const VisitEthiopia = () => {
  const [showMore, setShowMore] = useState(false);
  const [dataId,setdataId]=useState();
 

  return (
    <div className="visit-container  mt-5 ">
      
     
          
     
      <h3 className="evisa-title">About Ethiopia</h3>
          <div  className=" mt-3 container-text ">
            
            <p align="justify" className=" border-bottom pb-2" >
            Ethiopia, in the Horn of Africa, is a rugged, landlocked country
            split by the Great Rift Valley. With archaeological finds dating
            back more than 3 million years, it’s a place of ancient culture.
            Among its important sites are Lalibela with its rock-cut Christian
            churches from the 12th–13th centuries. Aksum is the ruins of an
            ancient city with obelisks, tombs, castles and Our Lady Mary of Zion
            church.
          </p>
          </div>
          
     
          <div className="row parent-container gap-3 justify-content-center"  >
            {data.visitData.map((data) =>
              data.type === "historical" ? (
                <div id="visit-container" className="shadow col-lg-3 col-sm-12 p-0 rounded-4" key={data.id} width="80px">
                  <img
                    className="visit-image"
                    src={data?.image}
                    alt="demo"
                    width="100%"
                    height="200px"
                    
                  />
                   <h6 className="mt-2 evisa-title ">{data.title}</h6>
                   <Divider/>
                 <p align="justify" className="" style={{paddingLeft:20,paddingRight:20}} >{showMore && dataId===data.id ? data.description : `${data.description.substring(0, 245)}`}</p>
                  <Button
                    key={data.id}
                    type="primary"
                    className="text-center mb-4 mt-3 evisa-button"
                    style={{ width: 150 }}
                    onClick={() =>{ setShowMore(!showMore);
                      setdataId(data.id);
                    }}
                  >
                    {" "}
                    {showMore && dataId===data.id ? <>See Less</>:<>See More</>}
                  </Button>
                </div>
              ) : (
                <></>
              )
            )}
          </div>
     
         
       
     
    </div>
  );
};

export default VisitEthiopia;
