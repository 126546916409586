import React from 'react';

const PaymentError = () => {
    return (
        <div>
            <br/>

<div class="container">

    <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-4 paymentstatusbody">
            <div class="login-box-body text-Color">
                <br />
                <div class="evisa-paymenterror">
                    <br />
                    <center><span class=" text-white fa-exclamation-triangle fa-3x"></span></center>
                    <center>
                        <h3 class="text-white">Payment Order Cancelled</h3>
                    </center>
                </div>

                <div class="form-group">
                    <h6>
                        <br /> Something went wrong while processing your payment order. Please try again later. <br /><br />Note: your application will not be processed until you complete the payment successfully.
                    </h6>
                </div>
            </div>
        </div>
        <div class="col-md-4"></div>
    </div>


</div>

<br/><br/>
        </div>
    );
};

export default PaymentError;