import React from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import "./evisaStyle.css";
import { Link } from "react-router-dom";
export default function ConferenceVisa() {
  return (
    <div className="text-left">
      <h4 className=" evisa-title text-left">Workshop/Conference Visa - CV</h4>
      <div className="divider"></div>
      <p className="text-left">
        A conference visa is issued to those who wish to visit Ethiopia for
        International conferences provided that they have an invitation to the
        conference, seminar or workshop being organized in Ethiopia by a
        Ministry or Department of the Government of Federal Democratic Republic
        of Ethiopia, Regional State Governments, Educational Institutions and
        Universities or organizations owned by the private sector or by the
        Government of Ethiopia, United Nations or its specialized agencies,
        African Union or reputed NGOs.
      </p>
      <h5 className=" evisa-title text-left">Requirements</h5>
      <p className="text-left">
        <ul>
          <li>Recent passport-size photo, copy of passport of the applicant</li>
          <li>
            Passport of the visa applicant which is valid for at least 6 months
            from the intended date of entry to Ethiopia.
          </li>
          <li>Invitation letter</li>
        </ul>
        valid for at least 6 months from the intended entry date to Ethiopia and
        invitation letter are required.
      </p>
      <p className="text-left">
        Please refer to the below table for additional requirements.
      </p>
      <Table responsive bordered>
        <thead className="thead-light evisa-color">
          <tr>
            <th>Inviting Company Category </th>
            <th>Description </th>
            <th>Required Documents</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <b>Private</b>
            </td>
            <td>Any legal private company registered and based in Ethiopia</td>
            <td>
              <ul>
                <li>
                  Application letter written by the inviting company to the
                  Immigration And Citizenship Service of Ethiopia.
                </li>
                <li>
                  Business License(renewed for the fiscal year) of the inviting
                  company.
                </li>
                <li>
                  Taxpayer Identification Number(TIN) certificate(renewed for
                  the fiscal year) of the inviting company.
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <b>Governmental(Ethiopian)</b>
            </td>
            <td>
              Any governmental organization, ministry, department or educational
              institution of the Government of Ethiopia.
            </td>
            <td>
              <ul>
                <li>
                  Formal application letter written by the inviting company to
                  the Immigration And Citizenship Service of Ethiopia.
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <b>NGO </b>
            </td>
            <td>
              Non-Governmental organizations(NGOs) which are REGISTERED in
              Ethiopia
            </td>
            <td>
              <ul>
                <li>
                  Formal application letter written by the inviting organization
                  to the Immigration And Citizenship Service of Ethiopia.
                </li>
                <li>Registration license of the inviting organization.</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              {" "}
              <b>International Organizations </b>
            </td>
            <td>
              Any International or Inter-Governmental Organizations such as
              United Nations and Africa Union
            </td>
            <td>
              <ul>
                <li>
                  Formal application letter written by the inviting organization
                  to the Ministry of Foreign Affairs of Ethiopia.
                </li>
                <li>
                  Note Verbal(support letter) from the Ministry of Foreign
                  Affairs of Ethiopia.
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </Table>
      <h5>Note:</h5>

      <ul>
        <li>
          <strong>Invitation Letter</strong> issued by the inviting
          company/organization to the participant should describe the conference
          briefly such as conference name, start date, end date and venue.
        </li>
        <li>
          <strong>Note verbal </strong> is a letter from the Ministry of Foreign
          Affairs of Ethiopia to the Immigration And Citizenship Service stating
          that an organization/company has officially requested for visa.
        </li>
      </ul>
      <h5 className="evisa-title">Processing Time</h5>
      <p className="text-left">
        Under normal circumstances, the processing time for{" "}
        <em>Workshop/Conference Visa - CV</em> is 3 days. So, travelers should
        submit their application at least 3 days before their estimated date of
        arrival
      </p>
      <h5 className="primary-color text-left evisa-title">
        Duration and Validity
      </h5>
      <p className="text-left">
        The Validity of the Visa starts counting from the date intended to enter
        Ethiopia.
      </p>
      <h5 className=" evisa-title text-left">Fee</h5>
      <p className="text-left">
        For single entry 30-days conference visa, the application fee is 52 USD.
      </p>
      <h5 className=" evisa-title text-left">Extension</h5>
      <p className="text-left">Conference Visa can not be extended.</p>

      <Link to="/visa">
        <Button className="mt-5 evisa-button">
          Apply for Conference Visa Now
        </Button>
      </Link>
    </div>
  );
}
