import React, { useContext, createContext } from "react";
import { BrowserRouter as Router, Routes, Route,Navigate } from "react-router-dom";
import Home from "../../home/Index";
import Company from "../../company/Create";
import Container from "react-bootstrap/Container";
import { StatusIndex } from "../../status/StatusIndex";
import Header from "./Header";
import Footer from "../Footer";
import Login from "../../company/Login";
import Stepper from "../../visa/stepper";
import Visa from "../../visa/visa";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Edit from "../../company/Edit";
import ApplicationList from "../../company/applicationList";
import Dashbpard from "../../company/dashboard";
import { VisaPayment } from "../../visa/VisaPayment";
import PaymentSuccess from "../../visa/return-page/PaymentSuccess";
import PaymentCancel from "../../visa/return-page/PaymentCancel";
import PaymentError from "../../visa/return-page/PaymentError";
import PaymentFailure from "../../visa/return-page/PaymentFailure";
import PaymentPending from "../../visa/return-page/PaymentPending";
import VisaEdit from "../../visa/VisaEdit";
import ExtensionEdit from "../../visa/extension/ExtensionEdit";
import { VisaExtensionIndex } from "../../visa/extension/VisaExtensionIndex";
import InfoIndex from "../../Information/InfoIndex";
import FAQ from "../../faq/FAQ";
import Festival from "../../visit/Festival";
import Historical from "../../visit/Historical"
import { ContinuePayment } from "../../visa/payment/ContinuePayment";
import { DownloadVisa } from "../../visa/DownloadVisa";
import TouristVisa from "../../InfoDetails/TouristVisa";
import InvestmentVisa from "../../InfoDetails/InvestmentVisa";
import GIVisa from "../../InfoDetails/GIVisa";
import TouristVisaOnArrival from "../../InfoDetails/TouristVisaOnArrival";
import VisasatusInformation from "../../InfoDetails/VisaStatusinfo";
import JournalistVisa from "../../InfoDetails/JournalistVisa";
import RIVisa from "../../InfoDetails/RIVisa";
import NGOVisa from "../../InfoDetails/NGOVisa";
import ConferenceVisa from "../../InfoDetails/ConferenceVisa";
import EGovVisa from "../../InfoDetails/EGovVisa";
import FBusinessVisa from "../../InfoDetails/FBusinessVisa";
import SportVisa from "../../InfoDetails/SportVisa";
import ResidenceVisa from "../../InfoDetails/ResidenceVisa";
import StudentVisa from "../../InfoDetails/StudentVisa";
import MedicalTreatmentVisa from "../../InfoDetails/MedicalTreatmentVisa";
import ReligionVisa from "../../InfoDetails/ReligionVisa";
import BusinessRelatedStudies from "../../InfoDetails/BusinessRelatedStudies";
import EntertainmentIndustry from "../../InfoDetails/EntertainmentIndustry";
import PrivateWorkVisa from "../../InfoDetails/PrivateWVisa";
import Requirement from "../../InfoDetails/Requirement";
import Terms from "../../TermsAndCondition/index";
import GuardedRoute from "./route_guard";
import {ForgotPassword} from "../../company/ForgotPassword";
import { ResetPassword } from "../../company/ResetPassword";
import CompleteVisa from "../../visa/CompleteVisa";
const userContext = createContext({ isLogedIn: false });
const isloginn = localStorage.getItem("isUserLogedIn");

const EvisaRouter = () => {
  const { isLogedIn } = useContext(userContext);
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route exact path="/visa" element={<Stepper />}></Route>
          <Route exact path="/Visa-CompleteVisa" element={<CompleteVisa />}></Route>
          <Route exact path="/visa-landing" element={<Visa />}></Route>
          <Route exact path="/Company" element={<Company />}></Route>
          <Route exact path="/Status" element={<StatusIndex />}></Route>
          <Route exact path="/login" element={<Login />}></Route>
          <Route exact path="/edit-profile" element={isloginn==="true"?<Edit />:<Navigate  to="/login" />}></Route>
          <Route exact path="/application-list" element={isloginn==="true"?<ApplicationList />:<Navigate  to="/login" />}></Route>
          <Route exact path="/dashboard" element={isloginn==="true"?<Dashbpard />:<Navigate  to="/login" />}></Route>
          <Route exact path="/visa-payment" element={<VisaPayment/>}></Route>
          <Route exact path="/payment-success"  element={<PaymentSuccess/>}></Route>
          <Route exact path="/payment-cancel" element={<PaymentCancel/>}></Route>
          <Route exact path="/payment-error" element={<PaymentError/>}></Route>
          <Route exact path="/payment-failure" element={<PaymentFailure/>}></Route>
          <Route exact path="/payment-pending" element={<PaymentPending/>}></Route>
          <Route exact path="/visa-update" element={<VisaEdit />}></Route>
          <Route exact path="/visaextension-update" element={<ExtensionEdit/>}></Route>
          <Route exact path="/termsandconditions" element={<Terms/>}></Route>
          <Route exact path="/visa-extension" element={<VisaExtensionIndex />}></Route>
          <Route exact path="/information" element={<InfoIndex/>}>
            <Route index element={<Requirement/>} />
            <Route exact path="investment" element={<InvestmentVisa />} />
            <Route exact path="tourist" element={<TouristVisa />} />
            <Route exact path="touristOnArrivalVisa" element={<TouristVisaOnArrival />} />
            <Route exact path="visasatusInformation" element={<VisasatusInformation />} />
            <Route exact path="journalistVisa" element={<JournalistVisa />} />
            <Route exact path="sport-visa" element={<SportVisa />} />
            <Route exact path="entertainment-visa" element={<EntertainmentIndustry />} />
            <Route exact path="student-visa" element={<StudentVisa />} />
            <Route exact path="religion-visa" element={<ReligionVisa />} />
            <Route exact path="medical-visa" element={<MedicalTreatmentVisa />} />
            <Route exact path="business-studies-visa" element={<BusinessRelatedStudies />} />
            
            <Route exact path="residence-visa" element={<ResidenceVisa />} />
            <Route exact path="embassiesWorkVisa" element={<RIVisa />} />
            <Route exact path="NGOWorkVisa" element={<NGOVisa />} />
            <Route exact path="governmentInstitutionsVisa" element={<GIVisa />}/>
            <Route exact path="conferenceVisa" element={<ConferenceVisa />} />
            <Route exact path="EGovVisa" element={<EGovVisa />} />
            <Route exact path="FBusinessVisa" element={<FBusinessVisa />} />
            <Route exact path="InvestmentVisa" element={<InvestmentVisa />} />
            <Route exact path="PrivateWorkVisa" element={<PrivateWorkVisa />} />
          </Route>
          <Route exact path="/faq" element={<FAQ />}></Route>
          <Route exact path="/ethiopianfestival" element={<Festival />}></Route>
          <Route exact path ="historicalplace" element={<Historical/>}></Route>
          <Route exact path="/continue-payment" element={<ContinuePayment />}></Route>
          <Route exact path="/download-visa" element={<DownloadVisa />}></Route>
          <Route exact path="/forgot-password" element={<ForgotPassword />}></Route>
          <Route exact path="/reset-password" element={<ResetPassword />}></Route>

        </Routes>
        <Footer />
      </Router>
    </>
  );
};

export default EvisaRouter;
