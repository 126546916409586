import React from 'react';
import { useState, useEffect,useRef } from "react";
import "./style.css"

import {AiOutlinePlus,AiOutlineMinus} from "react-icons/ai"
import VisaService from "./../../service/visa/VisaService";
import { Toast } from "primereact/toast";

import { Collapse } from 'antd';
import { FcDown, FcUp } from "react-icons/fc";
const { Panel } = Collapse;
function Accordion() {
  
  const [faqData,setFaqData]=useState();
  const toast = useRef(null);
  
 
 
  useEffect(() => {
    const visaservice= new VisaService();
    visaservice.getFaq()
    .then((res)=>{
      setFaqData(res.data);
      console.log(res.data);
    })
    .catch((error)=>{toast.current.show({
      severity: "error",
      summary: "Error occured",
      detail: error?.response.data.errors[0],
      life: 3000,
    });})
  }, []);
  return (
    <>
    <Toast ref={toast} />
      <div >
        <div className='mt-5'>
          
          <h3 className='evisa-title'>FREQUENTLY ASKED QUESTIONS  </h3>
          <h4 className='evisa-title '>Find the answer to all of our most frequently asked questions.</h4> 
        </div>
        <div >
          <div >
         
         
          </div>
          <div >
          <Collapse defaultActiveKey={['1']} accordion="true"   expandIcon={({ isActive }) => isActive ? <FcUp />:<FcDown />  } expandIconPosition="end" className='parentAccord shadow-1 mb-4 mt-4 custom-panel'  >
          
          {
            faqData?.map((item) =>
           
            <Panel header={item?.faqTitle} key={item?.id}  className="">
              <p>{item?.faqDescription}</p>
              </Panel>
            )
          }
       </Collapse>
          </div>
        </div>
     
      
      </div>
    </>
  );
}

export default Accordion;