import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { VisaonArrivalService } from '../Information/VisaonArrivalService';
import Button from "react-bootstrap/Button";
import "./evisaStyle.css";
import { Link } from "react-router-dom";

// Functional component definition
export default function PaginatorVisaonarrival() {
  // State variables for storing visaonarrival data and global filter text
  const [visaonarrival, setVisaonarrival] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');

  // Fetch data on component mount
  useEffect(() => {
    const fetchData = async () => {
      const data = VisaonArrivalService.getData();
      setVisaonarrival(data);
    }
    fetchData();
  }, []); 
  
  const onFilterInputChange = (event) => {
    setGlobalFilter(event.target.value);
  };


  return (
    <div>
      {/* Header section */}
      <div className="text-left">
        <h4 className="evisa-title">Tourist Visa On Arrival</h4>
        <div className="divider"></div>
        <h5 className="evisa-title" ><strong>Visa on arrival for Countries</strong></h5>
        <p>Ethiopia has officially launched Visa-On-Arrival service for tourists from the various  <strong> Countries</strong> shown below.</p>
      </div>

      {/* DataTable component for displaying visaonarrival data */}
      <div className="card">
      <input type="text" className="p-inputtext" placeholder="Search by Country" value={globalFilter} onChange={onFilterInputChange} />

        <DataTable value={visaonarrival} paginator rows={5} rowsPerPageOptions={[10, 25, 50]} tableStyle={{ minWidth: '100%' }} globalFilter={globalFilter}>
          {/* Columns for Country and Name */}
          <Column field="country.name" header="Country"  filterField="country.name" style={{ width: '40%' }}></Column>
          <Column field="name" header="Tourist Visa Category" style={{ width: '60%' }}></Column>

        </DataTable>
        
      
      </div>

   
      <br />
      <h5><strong>Visa Exemptions</strong></h5>
      <p>Citizens of Kenya and Djibouti who have a valid passport can enter Ethiopia without a visa.</p>
      
      {/* Link to the visa application page */}
      <Link to="/visa">
        <Button className="mt-5 evisa-button">Apply for Tourist Now</Button>
      </Link>
    </div>
  );
}
