import React from "react";
import TouristVisa from "../InfoDetails/TouristVisa";
import Container from "react-bootstrap/Container";
import JournalistVisa from "../InfoDetails/JournalistVisa";
import RIVisa from "../InfoDetails/RIVisa";
import InvestmentVisa from "../InfoDetails/InvestmentVisa";
import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import  "../InfoDetails/evisaStyle.css"
import "./Catstyle.css"
import {
  BrowserRouter,
  NavLink,
  Link,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
const InfoIndex = () => {
  return (
    <div>
      <Container fluid className="mt-5 mb-4  ">
        <Row className="justify-content-md-center ">
          <Col xs={7} md="7" sm="12" xs="12" lg="7" className="ml-5 ">
            
            <Outlet />
          </Col>
          <Col className="ml-8  " md="auto">
            
            <div className="mt-4 ">
              <Card className="evisa-color">
              <ListGroup className="shadow">
                <ListGroup.Item className="list-group-item evisa-color ">
                 <b>VISA STATUS INFORMATION</b>
                </ListGroup.Item>
                <ListGroup.Item  >
                    <NavLink to="/information/visasatusInformation" style={({ isActive }) => ({
                      color: isActive ? '#004d99' : '#0b5099',
                      fontWeight: isActive ? '700' : '500'
                    })}>
                    Check Visa Status
                  </NavLink>
                </ListGroup.Item>
                
              </ListGroup>
              </Card>
              <br></br>
              <Card className="evisa-color">
              <ListGroup className="shadow">
                <ListGroup.Item className="list-group-item evisa-color ">
                 <b> TOURIST VISA(VT)</b>
                </ListGroup.Item>
                <ListGroup.Item  >
                    <NavLink to="/information/tourist" style={({ isActive }) => ({
                      color: isActive ? '#004d99' : '#0b5099',
                      fontWeight: isActive ? '700' : '500'
                    })}>
                    Tourist Visa(Online)
                  </NavLink>
                </ListGroup.Item>
                  <ListGroup.Item >
                    <NavLink to="/information/touristOnArrivalVisa" style={({ isActive }) => ({
                      color: isActive ? '#004d99' : '#0b5099',
                      fontWeight: isActive ? '700' : '500'

  })}>
                    Tourist Visa(OnArrival)
                  </NavLink>
                </ListGroup.Item>
               
              </ListGroup>
              </Card>
              <br></br>
              <Card  className="evisa-color">
              <ListGroup className="list-group ">
                <ListGroup.Item className="list-group-item evisa-color">
                  <b>BUSINESS VISA</b>
                </ListGroup.Item>
                <ListGroup.Item className="list-group-item">

                    <NavLink to="/information/journalistVisa" style={({ isActive }) => ({
                      color: isActive ? '#004d99' : '#0b5099',
                      fontWeight: isActive ? '700' : '500'
    
  })}>Journalist Visa- JV</NavLink>
                </ListGroup.Item>

                
                <ListGroup.Item className="list-group-item">
                  <NavLink to="/information/InvestmentVisa" style={({ isActive }) => ({
    color: isActive ? '#004d99' : '#0b5099',
    fontWeight:isActive ? '700' : '500'
    
  })}>
                    Investment Visa - IV
                  </NavLink>
                </ListGroup.Item>
                <ListGroup.Item className="list-group-item">
                <NavLink to="/information/governmentInstitutionsVisa" style={({ isActive }) => ({
    color: isActive ? '#004d99' : '#0b5099',
    fontWeight:isActive ? '700' : '500'
    
  })}>
                    Government Institutions Short Task Visa - GIV
                    </NavLink>
                </ListGroup.Item>
                <ListGroup.Item className="list-group-item">
                <NavLink to="/information/NGOWorkVisa" style={({ isActive }) => ({
    color: isActive ? '#004d99' : '#0b5099',
    fontWeight:isActive ? '700' : '500'
    
  })}>
                    NGO Work Visa - NV
                    </NavLink>
                </ListGroup.Item>
                <ListGroup.Item className="list-group-item">
                <NavLink to="/information/conferenceVisa" style={({ isActive }) => ({
    color: isActive ? '#004d99' : '#0b5099',
    fontWeight:isActive ? '700' : '500'
    
  })}>
                    Workshop/Conference Visa - CV
                    </NavLink>
                </ListGroup.Item>
                
                <ListGroup.Item className="list-group-item">
                <NavLink to="/information/FBusinessVisa" style={({ isActive }) => ({
    color: isActive ? '#004d99' : '#0b5099',
    fontWeight:isActive ? '700' : '500'
  })}>
                    Foreign Business Firm Employment Visa - WV
                    </NavLink>
                </ListGroup.Item>

                <ListGroup.Item className="list-group-item">
                <NavLink to="/information/student-visa" style={({ isActive }) => ({
    color: isActive ? '#004d99' : '#0b5099',
    fontWeight:isActive ? '700' : '500'
  })}>
                    Student Visa (SV)
                    </NavLink>
                </ListGroup.Item>
                <ListGroup.Item className="list-group-item">
                <NavLink to="/information/religion-visa" style={({ isActive }) => ({
    color: isActive ? '#004d99' : '#0b5099',
    fontWeight:isActive ? '700' : '500'
  })}>
                   Religion Visa (RV)
                    </NavLink>
                </ListGroup.Item>


                <ListGroup.Item className="list-group-item">
                <NavLink to="/information/medical-visa" style={({ isActive }) => ({
    color: isActive ? '#004d99' : '#0b5099',
    fontWeight:isActive ? '700' : '500'
  })}>
                    Medical Treatment Visa (MDV)
                    </NavLink>
                </ListGroup.Item>


                <ListGroup.Item className="list-group-item">
                <NavLink to="/information/business-studies-visa" style={({ isActive }) => ({
    color: isActive ? '#004d99' : '#0b5099',
    fontWeight:isActive ? '700' : '500'
  })}>
                    Business and Related Studies Visa (BRV)
                    </NavLink>
                </ListGroup.Item>


                <ListGroup.Item className="list-group-item">
                <NavLink to="/information/entertainment-visa" style={({ isActive }) => ({
    color: isActive ? '#004d99' : '#0b5099',
    fontWeight:isActive ? '700' : '500'
  })}>
                    Entertainment Industry Visa (EIV)
                    </NavLink>
                </ListGroup.Item>
                <ListGroup.Item className="list-group-item">
                <NavLink to="/information/residence-visa" style={({ isActive }) => ({
    color: isActive ? '#004d99' : '#0b5099',
    fontWeight:isActive ? '700' : '500'
  })}>
                   Residence Visa (IMV)
                    </NavLink>
                </ListGroup.Item>



                <ListGroup.Item className="list-group-item">
                <NavLink to="/information/sport-visa" style={({ isActive }) => ({
    color: isActive ? '#004d99' : '#0b5099',
    fontWeight:isActive ? '700' : '500'
  })}>
                    Sports Competition and Training Visa (SPV)
                    </NavLink>
                </ListGroup.Item>

               
              </ListGroup>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default InfoIndex;
