import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { classNames } from "primereact/utils";
import CompanyService from "../../service/company/CompanyService";
import { Select } from 'antd';
import SnipperModal from "../../components/common/modal/snipperModal";
import { MDBCol, MDBRow, MDBBadge, MDBTypography } from "mdbreact";
import "./Company.css";
import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import { useNavigate } from "react-router-dom";

const Edit = () => {
  const [companyCategories, setCompanyCategories] = useState([]);
  const [regions, setRegions] = useState([]);
  const [redirectTAttachmentPage, setRedirectTAttachmentPage] = useState(false);
  const [confirmSubmission, setConfirmSubmission] = useState(false);
  const [formData, setFormData] = useState({});
  const companyService = new CompanyService();
  const [regionValid, setRegionValid] = useState(true);
  const [compCategoryValid, setCompCategoryValid] = useState(true);
  const [visaValid, setVisaValid] = useState(true);
  const [passportValid, setPassportValid] = useState(true);
  const [businessLicIssueDateValid, setBusinessLicIssueDateValid] = useState(true);
  const [businessLicExpiryDateValid, setBusinessLicExpiryDateValid] = useState(true);
  const [tinIssueDateValid, setTinIssueDateValid] = useState(true);
  const [files, setfiles] = useState({});
  const [loading, setLoading] = useState(false);
  const inputs = [];
  const [requiredAttachements, setRequiredAttachements] = useState([]);
  const [attachmentNames, setattachmentNames] = useState([]);
  const [requiredFile, setrequiredFile] = useState("");
  const navigate = useNavigate();

  let fileError = [];
  const [errorMessage, seterrorMessage] = useState([]);
  const companyData = localStorage.getItem("companyLoginResponse");
  const companyDataParsed = JSON.parse(companyData);
  const [selectedTinIssueDate, setSelectedTinIssueDate] = useState(formatDate(companyDataParsed?.tinCertificateIssuedDate));
  const [selectedBusLicIssuedDate, setSelectedBusLicIssuedDate] = useState(formatDate(companyDataParsed?.businessLicenseIssuedDate));
  const [selectedBusLicExpiryDate, setSelectedBusLicExpiryDate] = useState(formatDate(companyDataParsed?.businessLicenseExpiryDate));
  const [selectedPassportExpDate, setSelectedPassportExpDate] = useState(formatDate(companyDataParsed?.passportExpiryDate));
  const [selectedRegionId, setSelectedRegionId] = useState(companyDataParsed?.regionId);
  const [selectedCompCategory, setSelectedCompCategory] = useState(companyDataParsed?.companyCategoryId);

  const [isTinDateValid, setIsTinDateValid] = useState(true);
  const [isBusIssueDateValid, setIsBusIssueDateValid] = useState(true);
  const [isBusExpiryDateValid, setIsBusExpiryDateValid] = useState(true);
  const [isPassportDateValid, setIsPassportDateValid] = useState(true);
  const [isRegionValid, setIsRegionValid] = useState(true);
  const [isCompanyCategoryValid, setIsCompanyCategoryValid] = useState(true);
  const [registErrorMessage, setRegistErrorMessage] = useState("");
  const [errorPopUp, setErrorPopUp] = useState(false);

  useEffect(() => {
    // console.log(companyData);
    getRegionList();
    getCompanyCategoryList();
    getRequiredAttachment(companyDataParsed.companyCategoryId);
  }, []);

  const getRequiredAttachment = (id) => {
    companyService
      .getCompanyRequiredAttachment(id)
      .then((response) => {
        if (response.data.length > 0) {
          setRequiredAttachements(response.data);
        }
        else setRequiredAttachements([]);
      })
      .catch((error) => {
        // console.log("error" + error.message);
        setRequiredAttachements([])
      });
  };

  const getCompanyCategoryList = async () => {
    await companyService
      .getCompanyCategory()
      .then((resp) => {
        setCompanyCategories(resp.data);
        // console.log(resp.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const getRegionList = async () => {
    await companyService
      .getRegion()
      .then((resp) => {
        setRegions(resp.data);
        // console.log(resp.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const confirmation = () => {

    // setIsConfirmed(true);
    // isConfirmed=true
    setConfirmSubmission(false)
  }
  function formatDateFunction(input) {

    var datePart = input.match(/\d+/g),
      year = datePart[0].substring(4), // get only two digits
      month = datePart[1],
      day = datePart[2];

    return day + '/' + month + '/' + year;
  }

  const handleTinIssueDateChange = (date, dateString) => {
    setSelectedTinIssueDate(dateString)
  };
  const handleBusIssueDateChange = (date, dateString) => {
    setSelectedBusLicIssuedDate(dateString)
  };
  const handleBusExpiryDateChange = (date, dateString) => {
    setSelectedBusLicExpiryDate(dateString)
  };
  const handlePasspExpiryDateChange = (date, dateString) => {
    setSelectedPassportExpDate(dateString)
  };
  const handleRegionChange = (value) => {
    setSelectedRegionId(value)
  };
  const handleCompCategoryChange = (value) => {
    setSelectedCompCategory(value)
    getRequiredAttachment(value)
  };

  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
  const onChange = (e) => {
    const { id, value } = e.target;

    const fileValue = e.target.files[0];
    setfiles((prevState) => ({
      ...prevState,
      [id]: fileValue,
    }));
  };

  for (let i = 0; i < requiredAttachements.length; i++) {
    inputs.push(
      <div class="col-md-6 my-1" id="attachmentmargin">
        <div class="col-md-12 passport-text-right">
          <MDBBadge color="primary smallPadding ">
            {" "}
            {attachmentNames[i]}{" "}
          </MDBBadge>
        </div>
        <div class="col-md-12">
          <div className="input-group">
            <div className="custom-file row">
              <label
                className="custom-file-label col-md-12"
                htmlFor="inputGroupFile01"
              >
                {requiredAttachements[i] ? (<strong>{
                  requiredAttachements[i].attachementType?.name} </strong>
                ) : (
                  <div class="smallFont"> <strong>Choose file </strong></div>
                )}
              </label>

              <input
                name={requiredAttachements[i].attachementType?.name}
                type="file"
                id={requiredAttachements[i].attachementType?.id}
                className="custom-file-input form-control col-md-12"
                aria-describedby="inputGroupFileAddon01"
                accept="image/png,image/gif,image/jpeg,image/jpg,application/pdf" //application/pdf
                onChange={(e) => onChange(e)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  const validateAttachment = (files) => {
    var fileCount = 0;
    var i;

    for (i in files) {
      if (files.hasOwnProperty(i)) {
        fileCount++;
      }
    }
    if (fileCount < requiredFile) {
      fileError.push(`You Should have to Choose All`);
    } else {
      for (var key in files) {
        if (
          !files[key].name.match(
            /\.(jpg|jpeg|png|gif||pdf|JPG|JPEG|PNG|GIF||PDF)$/
          )
        ) {
          fileError.push(`  
            ${files[key].name ? (
              files[key].name +
              " is Invalid format, Please upload correct file type!"
            ) : (
              <div class="smallFont">
                Invalid format, Please upload correct file type!
              </div>
            )
            }`);
        }

        if (files[key].size > 2000000) {
          fileError.push(`  
        ${files[key].name ? (
              files[key].name +
              " is Invalid size, Please upload correct file size!"
            ) : (
              <div class="smallFont">
                Invalid size, Please upload correct file size!
              </div>
            )
            }`);
        }
      }
    }

    seterrorMessage(fileError);

    if (fileError.length > 0) {
      return false;
    }

    return true;
  };

  const formik = useFormik({
    initialValues: {
      id: companyDataParsed?.id,
      name: companyDataParsed?.name,
      telephone: companyDataParsed?.telephone,
      city: companyDataParsed?.city,
      regionId: companyDataParsed?.regionId,
      zone: companyDataParsed?.zone,
      subcity: companyDataParsed?.subcity,
      woreda: companyDataParsed?.woreda,
      kebele: companyDataParsed?.kebele,
      companyManagerEmailAddress: companyDataParsed?.companyManagerEmailAddress,
      taxPayerIdentificationNumber: "string",
      businessLicenseIssuedDate: "2022-10-12T14:10:52.790Z",
      businessLicenseExpiryDate: "2022-10-12T14:10:52.790Z",
      tinCertificateIssuedDate: "2022-10-12T14:10:52.790Z",
      // tinCertificateIssuedDate: companyDataParsed.tinCertificateIssuedDate,
      tinCertificateExpiryDate: "2022-10-12T14:10:52.790Z",
      passportExpiryDate: "2022-10-12T14:10:52.790Z",
      // visaExpiryDate: "2022-10-12T14:10:52.790Z",
      companyCategoryId: companyDataParsed?.companyCategoryId
    },

    validate: (data) => {
      let errors = {};

      if (!data.telephone) {
        errors.telephone = 'Telephone is required.';
      }

      if (data.regionId === 2147483647) {
        errors.regionId = 'Please Select region';
        setRegionValid(false);
      }
      else setRegionValid(true);
      if (!data.zone) {
        errors.zone = 'Zone is required.';
      }
      if (!data.city) {
        errors.city = 'City is required.';
      }
      if (!data.woreda) {
        errors.woreda = 'Woreda is required.';
      }

      if (!data.name) {
        errors.name = 'Company Name is required.';
      }
      if (!data.companyManagerEmailAddress) {
        errors.companyManagerEmailAddress = 'company Manager Email Address is required.';
      }
      else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.companyManagerEmailAddress)) {
        errors.companyManagerEmailAddress = 'Invalid email address. Please enter valid email address';
      }

      if (data.businessLicenseIssuedDate === null) {
        setBusinessLicIssueDateValid(false);
        errors.businessLicenseIssuedDate = 'Select business License issued date';
      }
      else setBusinessLicIssueDateValid(true);

      if (data.businessLicenseExpiryDate === null) {
        setBusinessLicExpiryDateValid(false);
        errors.businessLicenseExpiryDate = 'Select business License expired date';
      }
      else setBusinessLicExpiryDateValid(true);

      if (data.tinCertificateIssuedDate === null) {
        setTinIssueDateValid(false);
        errors.tinCertificateIssuedDate = 'Select tin certificate issued date';
      }
      else setTinIssueDateValid(true);

      if (data.passportExpiryDate === null) {
        setPassportValid(false);
        errors.passportExpiryDate = 'Select passport expired date';
      }
      else setPassportValid(true);

      if (data.companyCategoryId === 0) {
        errors.companyCategoryId = 'Please Select company category';
        setCompCategoryValid(false);
      }
      else setCompCategoryValid(true);

      return errors;
    },
    onSubmit: (data) => {

      if (selectedBusLicIssuedDate === null || selectedBusLicIssuedDate === "") {
        setIsBusIssueDateValid(false)
      } else setIsBusIssueDateValid(true)
      if (selectedBusLicExpiryDate === null || selectedBusLicExpiryDate === "") {
        setIsBusExpiryDateValid(false)
      } else setIsBusExpiryDateValid(true)
      if (selectedTinIssueDate === null || selectedTinIssueDate === "") {
        setIsTinDateValid(false)
      }
      if (selectedPassportExpDate === null || selectedPassportExpDate === "") {
        setIsPassportDateValid(false)
      } else setIsPassportDateValid(true)
      if (selectedRegionId === null || selectedRegionId === "") {
        setIsRegionValid(false)
      } else setIsRegionValid(true)
      if (selectedCompCategory === null || selectedCompCategory === "") {
        setIsCompanyCategoryValid(false)
      } else setIsCompanyCategoryValid(true)
      if (isBusIssueDateValid && isBusExpiryDateValid && isTinDateValid && isPassportDateValid & isRegionValid & isCompanyCategoryValid) {
        setLoading(true)
        let formData = new FormData();

        for (var key in files) {
          formData.append(key, files[key]);
        }
        formData.append("id", data.id);
        formData.append("name", data.name);
        formData.append("telephone", data.telephone);
        formData.append("city", data.city);
        formData.append("regionId", selectedRegionId);
        formData.append("zone", data.zone);
        formData.append("subcity", data.subcity);
        formData.append("woreda", data.woreda);
        formData.append("kebele", data.kebele);
        formData.append("companyManagerEmailAddress", data.companyManagerEmailAddress);
        formData.append("taxPayerIdentificationNumber", data.taxPayerIdentificationNumber);
        formData.append("companyCategoryId", data.companyCategoryId);
        formData.append("businessLicenseIssuedDate", selectedBusLicIssuedDate);
        formData.append("businessLicenseExpiryDate", selectedPassportExpDate);
        formData.append("tinCertificateIssuedDate", selectedTinIssueDate);
        formData.append("tinCertificateExpiryDate", data.tinCertificateExpiryDate);
        formData.append("passportExpiryDate", selectedPassportExpDate);
        // formData.append("visaExpiryDate", data.visaExpiryDate);
        companyService
          .updateCompany(formData)
          .then((res) => {

            // console.log(res);
            // localStorage.removeItem("companyLoginResponse");
            localStorage.setItem(
              "companyLoginResponse", JSON.stringify(res.data)
            );
            setFormData(data);
            setLoading(false)
            setRedirectTAttachmentPage(true);
          })
          .catch((err) => {

            // console.log(err);
            setRegistErrorMessage(err.response.data.title);
            setLoading(false);
            setErrorPopUp(true);

          });

        formik.resetForm();
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  const handleErrorPopUp = () => {
    setErrorPopUp(false);
  }
  const handleRedirectToAttachmentPage = () => {
    // window.location.reload();
    navigate("/dashboard")
  }

  const seccussDialogFooter = (
    <div className="flex justify-content-center text-center">
      <Button
        label="OK"
        className="p-button-success p-button-sm"
        autoFocus
        onClick={() => handleRedirectToAttachmentPage()}
      />
    </div>
  );
  const errorPopUpFooter = (
    <div className="flex justify-content-center text-center">
      <Button
        label="Ok"
        className="p-button-primary p-button-sm"
        autoFocus
        onClick={() => handleErrorPopUp()}
      />
    </div>
  );
  const confirmDialogFooter = (
    <div className="flex justify-content-center text-center">
      <Button
        label="Confirm"
        className="p-button-success p-button-sm"
        autoFocus
        onClick={() => confirmation()}
      />
      <Button
        label="Cancel"
        className="p-button-warning p-button-sm"
        autoFocus
        onClick={() => setConfirmSubmission(false)}
      />
    </div>
  );
  return (
    <div>
      {loading ? <SnipperModal /> : <> {null}</>}


      <div className="row">
        <div className="col-md-12 row mt-4">
          <div className="col-md-2"></div>
          <div
            className="col-md-8"
            style={{ float: "left", textAlign: "left" }}
          >
            <h2 className="m-2 mb-3">Edit Company Profile</h2>
            <div className="col-md-2"></div>
          </div>
        </div>
        <div className="col-md-2"></div>
        <div className="col-md-8">

          <div className="form-demo mt-2 mb-5 mx-5">
            <Dialog
              visible={redirectTAttachmentPage}
              onHide={() => setRedirectTAttachmentPage(false)}
              position="top"
              footer={seccussDialogFooter}
              showHeader={false}
              breakpoints={{ "960px": "80vw" }}
              style={{ width: "30vw" }}
            >
              <div className="flex align-items-center flex-column text-center pt-6 px-3 mt-2">
                <i
                  className="pi pi-check-circle"
                  style={{ fontSize: "4rem", color: "var(--green-500)" }}
                ></i>
                <h5 className='text-center text-dark'><b>Update Successful!</b></h5>
                <p style={{ lineHeight: 1.5, textIndent: "1rem", textAlign: "left" }}>
                  Your company information is registered under company name <b>{formData.name}</b>.
                  If you have updated the attachment, the approval process will take place by INVEA officers.
                </p>
              </div>
            </Dialog>
            <Dialog
              visible={errorPopUp}
              onHide={() => setErrorPopUp(false)}
              position="top"
              footer={errorPopUpFooter}
              showHeader={false}
              breakpoints={{ "960px": "80vw" }}
              style={{ width: "30vw" }}
            >
              <div className="flex align-items-center flex-column text-center pt-6 px-3 mt-2">
                <i
                  className="pi pi-times-circle"
                  style={{ fontSize: "4rem", color: "var(--red-500)" }}
                ></i>
                <h5 className='text-center text-danger'><b>Error!</b></h5>
                <p style={{ lineHeight: 1.5, textIndent: "1rem", textAlign: "center", color: 'red' }}>
                  {registErrorMessage ? registErrorMessage : "Something went wrong."}
                </p>
              </div>
            </Dialog>
            <Dialog
              visible={confirmSubmission}
              onHide={() => setConfirmSubmission(false)}
              position="top"
              footer={confirmDialogFooter}
              showHeader={false}
              breakpoints={{ "960px": "80vw" }}
              style={{ width: "30vw" }}
            >
              <div className="flex align-items-center text-center flex-column pt-6 px-3">
                <i
                  className="pi pi-exclamation-triangle"
                  style={{ fontSize: "4rem", color: "var(--yellow-500)" }}
                ></i>
                <h5 className='text-center text-dark'><b>Update Confirmation!</b></h5>
                <p style={{ lineHeight: 1.5, textIndent: "1rem" }}>
                  are you sure the information you entered about <b>{formData.name}</b> is correct?
                </p>
              </div>
            </Dialog>

            <div className="flex justify-content-center">
              <div className="card">
                <form onSubmit={formik.handleSubmit} className="p-fluid m-2 row">

                  <h4 className="m-3 mb-4" style={{ float: "left", textAlign: "left" }}>Address</h4>

                  <div className="field col-md-6">
                    <label className="align-left">Region</label>
                    <Select
                      labelInValue
                      defaultValue={{ value: companyDataParsed.regionId, label: companyDataParsed.region }}
                      style={{ width: "100%" }}
                      onChange={(e) => handleRegionChange(e.value)}
                      options={regions.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                    />
                    {!isRegionValid ? <span className="text-danger">please select region</span> : null}
                  </div>
                  <div className="field col-md-6">
                    <span className="p-float-label p-input-icon-right">
                      <InputText
                        id="zone"
                        name="zone"
                        value={formik.values.zone}
                        onChange={formik.handleChange}
                        className={classNames({
                          "p-invalid": isFormFieldValid("zone"),
                        })}
                      />
                      <label
                        htmlFor="zone"
                        className={classNames({
                          "p-error": isFormFieldValid("zone"),
                        })}
                      >
                        Zone
                      </label>
                    </span>
                    {getFormErrorMessage("zone")}
                  </div>
                  <div className="field col-md-6">
                    <span className="p-float-label p-input-icon-right">
                      <InputText
                        id="city"
                        name="city"
                        value={formik.values.city}
                        onChange={formik.handleChange}
                        className={classNames({
                          "p-invalid": isFormFieldValid("city"),
                        })}
                      />
                      <label
                        htmlFor="city"
                        className={classNames({
                          "p-error": isFormFieldValid("city"),
                        })}
                      >
                        City*
                      </label>
                    </span>
                    {getFormErrorMessage("city")}
                  </div>
                  <div className="field col-md-6">
                    <span className="p-float-label p-input-icon-right">
                      <InputText
                        id="subcity"
                        name="subcity"
                        value={formik.values.subcity}
                        onChange={formik.handleChange}
                        className={classNames({
                          "p-invalid": isFormFieldValid("subcity"),
                        })}
                      />
                      <label
                        htmlFor="subcity"
                        className={classNames({
                          "p-error": isFormFieldValid("subcity"),
                        })}
                      >
                        Sub City
                      </label>
                    </span>
                    {getFormErrorMessage("subcity")}
                  </div>
                  <div className="field col-md-6">
                    <span className="p-float-label p-input-icon-right">
                      <InputText
                        id="woreda"
                        name="woreda"
                        value={formik.values.woreda}
                        onChange={formik.handleChange}
                        className={classNames({
                          "p-invalid": isFormFieldValid("woreda"),
                        })}
                      />
                      <label
                        htmlFor="woreda"
                        className={classNames({
                          "p-error": isFormFieldValid("woreda"),
                        })}
                      >
                        Woreda*
                      </label>
                    </span>
                    {getFormErrorMessage("woreda")}
                  </div>
                  <div className="field col-md-6">
                    <span className="p-float-label p-input-icon-right">
                      <InputText
                        id="kebele"
                        name="kebele"
                        value={formik.values.kebele}
                        onChange={formik.handleChange}
                        className={classNames({
                          "p-invalid": isFormFieldValid("kebele"),
                        })}
                      />
                      <label
                        htmlFor="kebele"
                        className={classNames({
                          "p-error": isFormFieldValid("kebele"),
                        })}
                      >
                        Kebele
                      </label>
                    </span>
                    {getFormErrorMessage("kebele")}
                  </div>

                  <h4 className="m-3 mb-4" style={{ float: "left", textAlign: "left" }}>Company Detail</h4>

                  <div className="field col-md-6">
                    <span className="p-float-label">
                      <InputText
                        id="name"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        autoFocus
                        className={classNames({
                          "p-invalid": isFormFieldValid("name"),
                        })}
                      />
                      <label
                        htmlFor="name"
                        className={classNames({
                          "p-error": isFormFieldValid("name"),
                        })}
                      >
                        Company Name*
                      </label>
                    </span>
                    {getFormErrorMessage("name")}
                  </div>

                  <div className="field col-md-6">
                    <span className="p-float-label p-input-icon-right">
                      <InputText
                        id="telephone"
                        name="telephone"
                        value={formik.values.telephone}
                        onChange={formik.handleChange}
                        className={classNames({
                          "p-invalid": isFormFieldValid("telephone"),
                        })}
                      />
                      <label
                        htmlFor="telephone"
                        className={classNames({
                          "p-error": isFormFieldValid("telephone"),
                        })}
                      >
                        Telephone*
                      </label>
                    </span>
                    {getFormErrorMessage("telephone")}
                  </div>

                  <div className="field col-md-6">
                    <span className="p-float-label p-input-icon-right">
                      <InputText
                        id="companyManagerEmailAddress"
                        name="companyManagerEmailAddress"
                        value={
                          formik.values.companyManagerEmailAddress

                        }
                        onChange={formik.handleChange}
                        className={classNames({
                          "p-invalid": isFormFieldValid(
                            "companyManagerEmailAddress"
                          ),
                        })}
                      />
                      <label
                        htmlFor="companyManagerEmailAddress"
                        className={classNames({
                          "p-error": isFormFieldValid(
                            "companyManagerEmailAddress"
                          ),
                        })}
                      >
                        Company Manager Email Address *
                      </label>
                    </span>
                    {getFormErrorMessage("companyManagerEmailAddress")}
                  </div>
                  <div className="field col-md-6">
                    <label className="align-left">Company Category</label>
                    <Select
                      labelInValue
                      defaultValue={{ value: companyDataParsed.companyCategoryId, label: companyDataParsed.companyCategory.category }}
                      style={{ width: "100%", height: "40px" }}
                      onChange={(e) => handleCompCategoryChange(e.value)}
                      options={companyCategories.map((item) => ({
                        value: item.id,
                        label: item.category,
                      }))}
                    />
                    {!isCompanyCategoryValid ? <span className="text-danger">please select company category</span> : null}
                  </div>
                  <div className="field col-md-6">
                    <label className="align-left">TIN Certificate Issued Date</label>
                    <DatePicker defaultValue={dayjs(selectedTinIssueDate, 'YYYY-MM-DD')}
                      onChange={handleTinIssueDateChange}
                      style={{ width: '100%', height: "40px" }}
                    />
                    {!isTinDateValid ? <span className="text-danger">please select TIN Certificate Issued Date</span> : null}
                  </div>

                  <div className="field col-md-6">
                    <label className="align-left">Business License Issued Date</label>
                    <DatePicker defaultValue={dayjs(selectedBusLicIssuedDate, 'YYYY-MM-DD')}
                      onChange={handleBusIssueDateChange}
                      style={{ width: '100%', height: "40px" }}
                    />
                    {!isBusIssueDateValid ? <span className="text-danger">please select Business License Issued Date</span> : null}
                  </div>
                  <div className="field col-md-6">
                    <label className="align-left">Business License Expiry Date</label>
                    <DatePicker defaultValue={dayjs(selectedBusLicExpiryDate, 'YYYY-MM-DD')}
                      onChange={handleBusExpiryDateChange}
                      style={{ width: '100%', height: "40px" }}
                    />
                    {!isBusExpiryDateValid ? <span className="text-danger">please select Business License Expiry Date</span> : null}
                  </div>
                  <div className="field col-md-6">
                    <label className="align-left">Passport Expiry Date</label>
                    <DatePicker defaultValue={dayjs(selectedPassportExpDate, 'YYYY-MM-DD')}
                      onChange={handlePasspExpiryDateChange}
                      style={{ width: '100%', height: "40px" }}
                    />
                    {!isPassportDateValid ? <span className="text-danger">please select Passport Expiry Date</span> : null}
                  </div>
                  <div className="row">
                    {inputs}
                  </div>
                  <Button id="submit" type="submit" label="Update" className="mt-2" />
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-2"></div>
      </div>


    </div>
  );
};

export default Edit;
