import React from "react";
import Button from "react-bootstrap/Button";
import  "./evisaStyle.css"
import { Link } from "react-router-dom";
export default function () {
  return (
    <div  className="text-left">
      <h4 className="evisa-title">
        International Organizations/Embassies Work Visa - RI
      </h4>
      <div className="divider">
        
      </div>
      <p className="text-left">
        International organizations/embassies work visa is issued to foreign
        nationals with ordinary passports who are invited by international
        organizations or embassies resident in Ethiopian for different work
        purposes.
      </p>
      <h5 className="evisa-title">Requirements</h5>
      <p className="text-left">Copy of the following documents is mandatory.</p>
      <ul>
        <li>Recent passport-size photo of the applicant.</li>
        <li>
          Passport of the visa applicant which is valid for at least 6 months
          from the intended date of entry to Ethiopia.
        </li>
        <li>
          Formal application letter by the organization/embassies to the
          Ministry of Foreign Affairs of Ethiopia.
        </li>
        <li>
          Note verbale or support letter from the Ministry of Foreign Affairs of
          Ethiopia.
        </li>
      </ul>
      <h5 className="evisa-title">Processing Time</h5>
      <p className="text-left">
        Under normal circumstances, the processing time for international
        organizations/embassies work visa is 3 days. So, travelers should submit
        their application at least 3 days before their estimated date of arrival
      </p>
      <h5 className="evisa-title">Duration and Validity</h5>
      <p className="text-left">
        The Validity of the Visa starts counting from the date intended to enter
        Ethiopia.
      </p>
      <h5 className="evisa-title">Extension</h5>
      <p className="text-left">
        Travelers can request for extension before the visa expires in which
        expired date 1-15 days extend by online and visa expired date above 15
        extended by going in person to the Head Office of the Immigration And
        Citizenship Service (Addis Ababa, Ethiopia). Travelers who stay beyond
        the validity period without extending are subject to fines and legal
        penalties.
      </p>
      <h5 className="evisa-title">Fee</h5>
     <p>For Single Entry Visa for one month, the fee is USD 52.</p>
     <Link to="/visa">
        <Button className="mt-8 evisa-button">
          Apply for International Organizations/Embassies Work Visa Now
        </Button>
        </Link>
    </div>
  );
}
