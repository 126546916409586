import React, {
  useEffect,
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
} from "react";
import { FaExclamationTriangle } from 'react-icons/fa';
import { MDBCol, MDBRow, MDBBadge, MDBTypography } from "mdbreact";
import VisaService from "../../../service/visa/VisaService";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import SnipperModal from "../../../components/common/modal/snipperModal";
import photosample from './Sample/photosample.jpg';
import { useDispatch } from "react-redux";
import { Toast } from "primereact/toast";
import { useSelector } from "react-redux";
import Cookies from "universal-cookie";
import { Card } from "antd";
import { Label } from "@mui/icons-material";
import { red } from "@mui/material/colors";
import { Link } from "react-router-dom"



const VisaRequiredAttachment = forwardRef((props, ref) => {
  const cookies = new Cookies();
  const formData = new FormData();
  const [files, setfiles] = useState({});
  const [errorWhileSubmit, setErrorWhileSubmit] = useState(
    "Something went wrong please try again"
  );
  const [isSubmitSeccuss, setIsSubmitSeccuss] = useState(true);

  const [attachmentNames, setattachmentNames] = useState([]);
  const [requiredAttachements, setRequiredAttachements] = useState([]);
  const [prevVisaType, setPrevVisaType] = useState(null);

  const inputs = [];
  const [errorMessage, seterrorMessage] = useState([]);
  const [serverErrorMessage, setserverErrorMessage] = useState("");
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  let fileError = [];
  const visaService = new VisaService();
  const [loading, setLoading] = useState(false);
  const [redirectToLoginPage, setRedirectToLoginPage] = useState(false);
  const visaCategoryState = useSelector(
    (state) => state.visaCategoryInformation.visaCategoryPayload
  );
  const arrivalState = useSelector(
    (state) => state.arrivalInformation.arrivalPayload
  );
  const personalState = useSelector(
    (state) => state.personalInformation.personalPayload
  );
  const passportState = useSelector(
    (state) => state.passportInformation.passportPayload
  );
  let visaCatId = visaCategoryState && visaCategoryState.visaType?.id;
  const toast = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useImperativeHandle(ref, () => ({
    saveData() {
      const requestBody = {
        FirstName: personalState ? personalState.firstName : "",
        SurName: personalState ? personalState.surname : "",
        Gender: personalState ? personalState.gender : "",
        BirthDate: personalState ? personalState.dateOfBirth : "",
        BirthPlace: personalState ? personalState.placeOfBirth : "",
        BirthCountry: personalState ? personalState.countryOfBirth?.id : "",
        Email: personalState ? personalState.emailAddress : "",
        PhoneNumber: personalState ? personalState.phoneNumber : "",
        Occupation: personalState ? personalState.occupation : "",
        StreetAddress: personalState ? personalState.streetAddress : "",
        CityAddress: personalState ? personalState.addressCity : "",
        CountryAdress: personalState ? personalState.addressCountry?.id : 0,
        Citizenship: personalState ? personalState.citizenship?.id : 0,
        PassportType: passportState ? passportState.passportType?.id : 0,
        PassportNumber: passportState ? passportState.passportNumber : "",
        PassportIssueDate: passportState ? passportState.passportIssueDate : "",
        PassportExpireDate: passportState
          ? passportState.passportExpireDate
          : "",
        IssuingCountry: passportState ? passportState.issuingCountry?.id : 0,
        IssuingAuthority: passportState
          ? passportState.passportIssuingAuthority
          : "",
        VisaType: visaCategoryState ? visaCategoryState.visaType?.id : 0,
        VisaValidity: visaCategoryState
          ? visaCategoryState.visaValidity?.id
          : 0,
        ArrivalDate: arrivalState ? arrivalState.arrivalDate : "",
        VisaTypeValidityId: visaCategoryState
          ? visaCategoryState.visaValidity?.visaTypeVisaValidityId
          : 0,
        DepartureCountry: arrivalState ? arrivalState.departureCountry?.id : 0,
        DepartureCity: arrivalState ? arrivalState.departureCity : "",
        Airlines: arrivalState ? arrivalState.airline : "",
        FlightNumber: arrivalState ? arrivalState.flightNumber : "",
        AccommodationType: arrivalState
          ? arrivalState.accommodationType?.id
          : 0,
        AccomodationName: arrivalState ? arrivalState.accommodationName : "",
        AccomodationCity: arrivalState ? arrivalState.accommodationCity : "",
        AccomodationPhonenumber: arrivalState
          ? arrivalState.accommodationTelephone
          : "",
        AccomodationAdress: arrivalState
          ? arrivalState.accommodationStreetAddress
          : "",
        PermanentAddress: "string",
        CompanyReferenceNumber: visaCategoryState
          ? visaCategoryState.companyReferenceNumber
          : "",
      };
      var formData = new FormData();
      for (var key in requestBody) {
        formData.append(key, requestBody[key]);
      }
      const isValid = validate(files);
      if (isValid) {
        for (var k in files) {
          formData.append(k, files[k]);
        }
      } else {
        return;
      }
      var visaService = new VisaService();
      setLoading(true);
      visaService
        .applyForVisa(formData)
        .then((res) => {
          setLoading(false);

          if (res?.data.orderCode !== null && res?.data.visaFee !== null) {
            toast.current.show({
              severity: "success",
              summary: "Successful",
              detail: res.message,
              life: 3000,
            });
            navigate(
              "/visa-payment?order=" +
              res?.data.orderCode +
              "&val=" +
              res?.data.visaFee
            );
          } else {
            console.log(res);
            setIsSubmitSeccuss(false);
          }
        })
        .catch((err) => {
          console.log("file error " + err);
          setLoading(false);
          if (err?.response?.data?.errors[0]?.message != null) {
            toast.current.show({
              severity: "error",
              summary: "Error occured",

              detail: err?.response?.data?.errors[0]?.message,
              life: 6000,
            });
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error occured",

              detail: err?.response?.data?.errors[0],
              life: 6000,
            });
          }
        });
    },
  }));

  var selectedVisaType = useSelector((state) => state.visaCategoryInformation);

  if (!isDataLoaded) {
    if (Object.keys(selectedVisaType).length > 0) {
      setPrevVisaType(selectedVisaType.visaCategoryPayload?.visaType);
      setIsDataLoaded(true);
      setLoading(true);
      visaService
        .getVisaRequiredAttachment(
          selectedVisaType.visaCategoryPayload?.visaType?.id
        )
        .then((response) => {
          setRequiredAttachements(response.data);
          setLoading(false);
          setIsDataLoaded(true);
        })
        .catch((error) => {
          setLoading(false);
          setIsDataLoaded(true);
        });
    }
  } else {
    if (prevVisaType !== null && prevVisaType !== "undefined") {
      if (
        selectedVisaType.visaCategoryPayload.visaType !== null &&
        selectedVisaType.visaCategoryPayload.visaType !== "undefined"
      ) {
        if (
          prevVisaType?.id !== selectedVisaType.visaCategoryPayload?.visaType.id
        ) {
          setPrevVisaType(selectedVisaType.visaCategoryPayload?.visaType);
          setIsDataLoaded(true);
          setLoading(true);
          visaService
            .getVisaRequiredAttachment(
              selectedVisaType.visaCategoryPayload?.visaType?.id
            )
            .then((response) => {
              setRequiredAttachements(response.data);
              setLoading(false);
              setIsDataLoaded(true);
            })
            .catch((error) => {
              setLoading(false);
              setIsDataLoaded(true);
            });
        }
      }
    }
  }

  useEffect(() => { }, []);

  const handleRedirectToLoginPage = () => {
    navigate("/login");
  };
  const validate = (files) => {
    console.log(files);
    var fileCount = 0;
    var i;

    for (i in files) {
      if (files.hasOwnProperty(i)) {
        fileCount++;
      }
    }
    if (fileCount < requiredAttachements.length) {
      fileError.push(`You Should have to Choose All`);
    } else {
      for (var key in files) {
        for (var attachement in requiredAttachements) {
          if (parseInt(key) === requiredAttachements[attachement].id) {
            if (requiredAttachements[attachement].isImage) {
              if (!files[key].type.match(/\/(jpg|jpeg|png||JPG|JPEG|PNG)$/)) {
                fileError.push(`  
                      ${files[key].name ? (
                    files[key].name +
                    " is Invalid format, Please upload correct file type!"
                  ) : (
                    <div class="smallFont">
                      Invalid format, Please upload correct file type!
                    </div>
                  )
                  }`);
              }
            } else {
              if (
                !files[key].type.match(
                  /\/(jpg|jpeg|png||pdf|JPG|JPEG|PNG||PDF)$/
                )
              ) {
                fileError.push(`  
                      ${files[key].name ? (
                    files[key].name +
                    " is Invalid format, Please upload correct file type!"
                  ) : (
                    <div class="smallFont">
                      Invalid format, Please upload correct file type!
                    </div>
                  )
                  }`);
              }
            }
          }
        }

        if (files[key].size > 2000000) {
          fileError.push(`  
            ${files[key].name ? (
              files[key].name +
              " is Invalid size, Please upload file size of maximum 2MB!"
            ) : (
              <div class="smallFont">
                Invalid size, Please upload file size of maximum 2M!
              </div>
            )
            }`);
        }
      }
    }

    seterrorMessage(fileError);

    if (fileError.length > 0) {
      return false;
    }
    return true;
  };

  const submit = async (e) => {
    e.preventDefault();
    fileError = [];

    const isValid = validate(files);
    if (isValid) {
      for (var key in files) {
        formData.append(key, files[key]);
      }
      formData.append("companyProfileId", props.param.companyProfileId);
    }
  };
  const onChange = (e) => {
    const { id, value } = e.target;
    const fileValue = e.target.files[0];

    setfiles((prevState) => ({
      ...prevState,
      [id]: fileValue,
    }));
  };
  for (let i = 0; i < requiredAttachements.length; i++) {
    inputs.push(
      <div class="row col-md-6" id="attachmentmargin" key={attachmentNames[i]}>
        <div class="col-md-4 passport-text-left">
          <MDBBadge color="primary smallPadding ">
            {" "}
            {attachmentNames[i]}{" "}
          </MDBBadge>
        </div>
        <div class="col-md-8">
          <div className="input-group">
            <div className="custom-file row">
              <label
                className="custom-file-label col-md-12 ml-3"
                htmlFor="inputGroupFile01"
              >
                {requiredAttachements[i] ? (
                  <strong>{requiredAttachements[i].name} </strong>
                ) : (
                  <div class="smallFont">
                    {" "}
                    <strong>Choose file </strong>
                  </div>
                )}
              </label>

              <input
                name={requiredAttachements[i].name}
                type="file"
                id={requiredAttachements[i].id}
                className="custom-file-input form-control col-md-12"
                aria-describedby="inputGroupFileAddon01"
                accept="image/png,image/gif,image/jpeg,image/jpg,application/pdf" //application/pdf
                onChange={(e) => onChange(e)}
                required
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
  const seccussDialogFooter = (
    <div className="flex justify-content-center text-center">
      <Button
        label="Login"
        className="p-button-success p-button-sm"
        autoFocus
        onClick={() => handleRedirectToLoginPage()}
      />
    </div>
  );

  return (
    <div>
      {loading ? <SnipperModal /> : <> {null}</>}
      <Toast ref={toast} />

      <Dialog
        visible={redirectToLoginPage}
        onHide={() => setRedirectToLoginPage(false)}
        position="top"
        footer={seccussDialogFooter}
        showHeader={false}
        breakpoints={{ "960px": "80vw" }}
        style={{ width: "30vw" }}
      >
        <div className="flex align-items-center flex-column text-center pt-6 px-3 mt-2">
          <i
            className="pi pi-check-circle"
            style={{ fontSize: "4rem", color: "var(--green-500)" }}
          ></i>
          <h5 className="text-center text-dark">
            <b>Congratulation!</b>
          </h5>
          <p style={{ lineHeight: 1.5, textIndent: "1rem", textAlign: "left" }}>
            You have successfully registered your company.
            <br /> Click <b>Login </b>button to login to your dashboard.
            <br /> After successfully login, you can edit your company related
            information as well as apply for company legalization.
          </p>
        </div>
      </Dialog>

      <div class="container">
        <h4>
          <div aria-live="polite" className="p-inline-message p-component p-inline-message-info" role="alert">
            <span className="p-inline-message-icon pi pi-info-circle"></span>
            <span>If you haven't received information about your visa status, feel free to revisit this website to check your visa status and download your visa documents.
              <Link to="/status" target="_blank" rel="noreferrer" style={{ textDecoration: 'underline' }}>Link to check your visa status.</Link>
            </span>
          </div>
        </h4>
        {/* <div>
      <h5>
        <FaExclamationTriangle style={{ color: 'red', marginRight: '8px' }} />
        <span style={{ color: 'red' }}>
          We are temporarily unable to accept MasterCard due to technical issues.
        </span>
      </h5>
    </div> */}
        <Card title="Attachements" style={{ textAlign: "center" }}>
          <form onSubmit={(e) => submit(e)}>
            <div class="row ">
              <div class="col-md-7" id="attachmentmargin">
                <div className="text-left m-0">
                  <ul style={{ listStyleType: "square", textAlign: "left" }}>
                    <h4 className="m-2 text-left">Note info:</h4>

                    <li>Size of the image/document should be less than 2MB.</li>
                    <li>
                      Photo and Passport Copy should be only in Image file type
                      of JPEG, JPG, PNG format.
                    </li>
                    <li>
                      Allowed Images/documents file type extensions are JPEG,
                      JPG, PNG and PDF format only.
                    </li>
                    <li>The image/document should be clear and readable.</li>
                    <li>
                      An image/document with blurred or unclean background is
                      not acceptable.
                    </li>
                    <li >
                      Please use the below Screenshot for Photograph Tips.
                    </li>

                  </ul>
                  <div >

                    <img src={photosample} style={{ textAlign: "Left", paddingRight: "300px" }} alt={''} />
                    <br />
                    <br />
                  </div>
                  {!isSubmitSeccuss ? (
                    <p className="text-danger">{errorWhileSubmit}</p>
                  ) : null}
                </div>

                {errorMessage.length
                  ? errorMessage.map((error) => (
                    <div class="alert alert-danger text-center" role="alert">
                      {error}
                    </div>
                  ))
                  : null}




                {serverErrorMessage ? (
                  <h5 class="text-danger text-center">{serverErrorMessage}</h5>
                ) : null}
              </div>

            </div>

            {inputs}
          </form>
        </Card>
      </div>
    </div>
  );
});

export default VisaRequiredAttachment;
