import React from "react";
import { TabView, TabPanel } from "primereact/tabview";
import "./style.css";
import data from "./data.json";
import {  useState } from "react";
import { Button } from "antd";

import { Divider } from "antd";

const VisitEthiopia = () => {
  const [showMore1, setShowMore1] = useState(false);
  const [dataId1,setdataId1]=useState();

  return (
    <div className="visit-container  mt-5">
      
   
       
      <h3 className="evisa-title mt-3">Ethiopian Festival</h3>
          <div className=" mt-3 container-text ">
          <p align="justify"  className=" border-bottom mb-3">
            Ethiopian festival Celebrations are great and colorful events,
            mostly religious, and frequently take place over several days.
            Important Christian holidays include Meskel, Christmas, Timkat,
            Kiddus Yohannes and Easter. And other widely celebrated festivals
            are Irreechaa and ashenda.
          </p>
          </div>
          <div className="row parent-container gap-3 justify-content-center" >
            {data.visitData.map((data) =>
              data.type === "festival" ? (
                <div id="visit-container" className="shadow col-lg-3 col-sm-12 p-0 rounded-4 ml-2" key={data.id} width="80px">
                  <img
                    className="visit-image"
                    src={data?.image}
                    alt="demo"
                    width="100%"
                    height="200px"
                    
                  />
                   <h6 className="mt-2 evisa-title ">{data.title}</h6>
                   <Divider/>
                 <p align="justify" className="border-bottom" style={{paddingLeft:20,paddingRight:20}} >{showMore1 && dataId1===data.id ? data.description : `${data.description.substring(0, 240)}`}</p>
                  <Button
                    key={data.id}
                    type="primary"
                    className="text-center mb-4 mt-3 evisa-button"
                    style={{ width: 150 }}
                    onClick={() =>{ setShowMore1(!showMore1);
                      setdataId1(data.id);
                    }}
                  >
                    {" "}
                    {showMore1 && dataId1===data.id ? <>See Less</>:<>See More</>}
                  </Button>
                </div>
              ) : (
                <></>
              )
            )}
          </div>
       
       
        
       
     
    </div>
  );
};

export default VisitEthiopia;
