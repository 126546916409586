import React from "react";
import Button from "react-bootstrap/Button";
import  "./evisaStyle.css"
import { Link } from "react-router-dom";
export default function PrivateWVisa() {
  return (
    <div  className="text-left">
      <h4 className="evisa-title">
        Ethiopian Private Business Firm Work Visa - PE
      </h4>
      <div className="divider">
        
      </div>
      <p className="text-left">
        Private Business Firm Visa is issued to foreign nationals coming for
        employment or other short term assignments with Ethiopian private
        business firms.
      </p>
      <h5 className="evisa-title">Requirements</h5>
      <ul>
        <p className="text-left">Copy of the following documents is mandatory.</p>
        <li>Recent passport-size photo of the applicant.</li>

        <li>
          Passport of the visa applicant which is valid for at least 6 months
          from the intended date of entry to Ethiopia.
        </li>

        <li>
          Official application letter written by the inviting organization and
          addressed to the Immigration And Citizenship Service of Ethiopia.
        </li>
        <li>Business license of the inviting company.</li>
        <li>
          Taxpayer Identification Number(TIN) Certificate of the inviting
          company
        </li>
        <li>
          Contractual agreement between the inviting company and the visa
          applicant.
        </li>
      </ul>
      <h5 className="evisa-title">Processing Time</h5>
      <p className="text-left">
        Under normal circumstances, the processing time for tourist visa is 3
        days. So, travelers should submit their application at least 3 days
        before their estimated date of arrival.
      </p>
      <h5 className="evisa-title">Duration and Validity</h5>
      <p className="text-left">
        The Validity of the Visa starts counting from the date intended to enter
        Ethiopia.
      </p>
      <h5 className="evisa-title">Fee</h5>
      <p className="text-left">For single entry 30-days visa, the application fee is USD 32.</p>
      <h5 className="evisa-title">Extension</h5>
      <p className="text-left">
        Travelers can request for extension before the visa expires in which
        expired date 1-15 days extend by online and visa expired date above 15
        extended by going in person to the Head Office of the Immigration And
        Citizenship Service (Addis Ababa, Ethiopia). Travelers who stay beyond
        the validity period without extending are subject to fines and legal
        penalties.
      </p>
      <Link to="/visa">
        <Button className="mt-5 evisa-button">
          Apply for Private Business Firm Work Visa now
        </Button>
        </Link>
    </div>
  );
}
