import React from "react";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import "./evisaStyle.css";
export default function InvestmentVisa() {
  return (
    <div className="text-left">
      <h4 className="evisa-title">Investment Visa-IV</h4>
      <div className="divider"></div>
      <p className="text-left">
        This is a visa issued to foreign Nationals who are engaged in investment
        activities. It can also be issued to potential investors who wish to
        come and invest in Ethiopia.
      </p>
      <h5 className="evisa-title">Requirements</h5>
      <ul>
        <li>
          Recent passport-size photo and passport copy of the traveler which has
         6 months validity from the date of entry are mandatory.
        </li>
        <li>
          A support letter( a proof that you are traveling for investment
          purpose) from the nearest Embassy of Ethiopia.
        </li>
        <li>
          Official application letter written by the inviting organization and
          addressed to the Immigration And Citizenship Service of Ethiopia.
        </li>
        <li>Business License of the inviting company.</li>
        <li>Support Letter from Investment Commission of Ethiopia.</li>
      </ul>
      <h5 className="evisa-title">Processing Time</h5>
      <p className="text-left">
        Under normal circumstances, the processing time for{" "}
        <em>Investment Visa - IV</em> is 3 days. So, travelers should submit
        their application at least 3 days before their estimated date of arrival
      </p>
      <h5 className="evisa-title">Duration and Validity</h5>
      <p className="text-left">
        The Validity of the Visa starts counting from the date intended to enter
        Ethiopia.
      </p>
      <h5 className="evisa-title">Fee</h5>
      <div className="striped bordered text-center">
        <Table bordered responsive>
          <thead className="evisa-color">
            <tr>
              <th>Entry Type</th>
              <th>Maximum Duration</th>
              <th>Application Visa Fee (in USD)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Single Entry</td>
              <td>30 Days</td>
              <td>62</td>
            </tr>
            <tr>
              <td>Multiple Entry</td>
              <td>90 Days</td>
              <td>152</td>
            </tr>
            <tr>
              <td>Multiple Entry</td>
              <td>6 month</td>
              <td>252</td>
            </tr>
            <tr>
              <td>Multiple Entry</td>
              <td>12 month</td>
              <td>502</td>
            </tr>
          </tbody>
        </Table>
        <h5 className="evisa-title text-left">Extension</h5>
        <p className="text-left">
          Travelers can request for extension before the visa expires in which
          expired date 1-15 days extend by online and visa expired date above 15
          extended by going in person to the Head Office of the Immigration And
          Citizenship Service (Addis Ababa, Ethiopia). Travelers who stay beyond
          the validity period without extending are subject to fines and legal
          penalties.
        </p>
        <Table bordered responsive>
          <thead className="evisa-color">
            <tr>
              <th>Entry Type</th>
              <th>Maximum Duration</th>
              <th>Extension Visa Fee (in USD)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Single Entry</td>
              <td>30 Days</td>
              <td>102</td>
            </tr>

            <tr>
              <td>Multiple Entry</td>
              <td>90 Days</td>
              <td>302</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <Link to="/visa">
        <Button className="mt-5 evisa-button">
          Apply for Investment Visa Now
        </Button>
      </Link>
    </div>
  );
}
