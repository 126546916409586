import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import { FaExclamationTriangle } from 'react-icons/fa';
import {  Link } from "react-router-dom"
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import "./arrivalInformation.css";
import { Calendar } from "primereact/calendar";
import VisaService from "../../../service/visa/VisaService";
import { useDispatch, useSelector } from "react-redux";
import { setArrivalInformation } from "../../../redux/actions/VisaActions";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import Input from "react-phone-number-input/input";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { json } from "react-router-dom";

const ArrivalInformation = forwardRef((props, ref) => {
  const [countries, setCountries] = useState([]);
  const [accomodationTypes, setAccomodationTypes] = useState([]);
  const digitPattern = new RegExp(/^\+?[0-9][0-9]{9,15}$/);
  const  [phoneIsValid, setPhoneIsValid] = useState(true);
  const [phoneValue, setPhoneValue] = useState();

  let minDate = new Date();
  let maxDate = new Date();
  const disabledDate = (current) => {
    return current && current < dayjs().startOf("day");
  };

  const [arrivalInfo, setArrivalInfo] = useState({
    arrivalDate: null,
    departureCountry: "",
    departureCity:"",
    airline: "",
    flightNumber: "",
    accommodationType: "",
    accommodationName: "",
    accommodationCity: "",
    accommodationStreetAddress: "",
    accommodationTelephone: "",
    dataSaved: false,
    formCompleted: false,
  });
  const [notCompleted, setNotCompleted] = useState({
    arrivalDate: true,
    departureCountry: true,
    departureCity: true,
    airline: true,
    flightNumber: true,
    accommodationType: true,
    accommodationName: true,
    accommodationCity: true,
    accommodationStreetAddress: true,
    accommodationTelephone: true,
  });
  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    saveData() {
      if (arrivalInfo.accommodationTelephone === "") {
        setPhoneIsValid(false);
      } else {
        setPhoneIsValid(true);
      }
      if (phoneValue !== "" || phoneValue !== null) {
      } else {
      }
      setArrivalInfo((prevState) => ({
        ...prevState,
        dataSaved: true,
      }));
      dispatch(setArrivalInformation(arrivalInfo));
    },
    Validate() {
      if (arrivalInfo.arrivalDate === null) {
        notCompleted.arrivalDate = true;
      } else notCompleted.arrivalDate = false;
      if (
        notCompleted.arrivalDate === true ||
        notCompleted.departureCountry === true ||
        notCompleted.departureCity === true ||
        notCompleted.accommodationType === true ||
        notCompleted.accommodationName === true ||
        notCompleted.accommodationCity === true ||
        notCompleted.accommodationStreetAddress === true ||
        notCompleted.accommodationTelephone === true||
        phoneIsValid === false
      )
        return false;
      else return true;
    },
  }));
   const isRequired = "is required!";
  const changePhone = (e) => {
    setPhoneValue(e);
    setArrivalInfo((prevState) => ({
      ...prevState,
      "accommodationTelephone": e,
    }));
    if (e !== ""||e !== null) {
      setNotCompleted((prevState) => ({
        ...prevState,

        accommodationTelephone: false,
      }));
    } else {
      setNotCompleted((prevState) => ({
        ...prevState,

        accommodationTelephone: true,
      }));
    }
  };
  
  useEffect(() => {

    let masterDataObj=localStorage.getItem("mDataObgj");
    if(masterDataObj){
      let masterDataParsed= JSON.parse(masterDataObj)
      setAccomodationTypes(masterDataParsed?.accommodationTypes);
      setCountries(masterDataParsed?.countries);
    }
    else{
      // window.location.reload();
    }
    setNotCompleted({
      departureCountry: arrivalInfo.departureCountry === "" ? true : false,
      departureCity: arrivalInfo.departureCity === "" ? true : false,
      accommodationType: arrivalInfo.accommodationType === "" ? true : false,
      accommodationName: arrivalInfo.accommodationName === "" ? true : false,
      accommodationCity: arrivalInfo.accommodationCity === "" ? true : false,
      accommodationStreetAddress:
        arrivalInfo.accommodationStreetAddress === "" ? true : false,
      accommodationTelephone:
        arrivalInfo.accommodationTelephone === "" ? true : false,
    });
  }, []);
  const dateTemplate = (date) => {
    if (date.day > 10 && date.day < 15) {
      return (
        <strong style={{ textDecoration: "line-through" }}>{date.day}</strong>
      );
    }

    return date.day;
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setArrivalInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (value !== "") {
      setNotCompleted((prevState) => ({
        ...prevState,

        [name]: false,
      }));
    } else {
      setNotCompleted((prevState) => ({
        ...prevState,

        [name]: true,
      }));
    }
  };

  const handleDateChange = (date, dateString) => {
    setArrivalInfo((prevState) => ({
      ...prevState,
      arrivalDate: dateString,
    }));
  };
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  return (
    <div className="flex justify-content-center">
      <h4>
        <div aria-live="polite" className="p-inline-message p-component p-inline-message-info" role="alert">
          <span className="p-inline-message-icon pi pi-info-circle"></span>
          <span>If you haven't received information about your visa status, feel free to revisit this website to check your visa status and download your visa documents.
            <Link to="/status" target="_blank"  rel="noreferrer" style={{ textDecoration: 'underline' }}>Link to check your visa status.</Link>
          </span>
        </div>
      </h4>
      {/* <div>
      <h5>
        <FaExclamationTriangle style={{ color: 'red', marginRight: '8px' }} />
        <span style={{ color: 'red' }}>
          We are temporarily unable to accept MasterCard due to technical issues.
        </span>
      </h5>
    </div> */}
      <div className="grid">
        <div className="col-12">
          <div className="card p-5">
            <form className="p-fluid row">   
              <h5 className="align-left">ARRIVAL INFORMATION</h5>
              <hr />
              <div className="field col-md-6 mb-4">
                <label htmlFor="icon" className="align-left">
                <span style={{color:"#737373",fontSize:"13px"}}>      Arrival Date<span className="text-danger">*</span></span>
                </label>
                <DatePicker
                  name="arrivalDate"
                  disabledDate={disabledDate}
                  onChange={handleDateChange}
                  style={{ width: "100%", height: "40px" }}
                  autofocus 
                />
                {/* </span> */}
                <span style={{ color: "red" }}>
                  {" "}
                  {notCompleted.arrivalDate === true &&
                  arrivalInfo.dataSaved === true
                    ? "Arrival Date " + isRequired
                    : null}
                </span>{" "}
              </div>
              <div className="field col-md-6 mb-4">
                <br />
                <span className="p-float-label">
                  <Dropdown
                    onChange={handleChange}
                    options={countries}
                    name="departureCountry"
                    value={arrivalInfo.departureCountry}
                    className="p-inputtext-sm block"
                    optionLabel="name"
                  />
                  <label className="align-left" htmlFor="country">
                    Departure Country<span className="text-danger">*</span>
                  </label>
                </span>
                <span style={{ color: "red" }}>
                  {" "}
                  {notCompleted.departureCountry === true &&
                  arrivalInfo.dataSaved === true
                    ? "Departure Country " + isRequired
                    : null}
                </span>{" "}
              </div>


              <div className="field col-md-6 mb-4">
                <span className="p-float-label">
                  <InputText
                    onBlur={handleChange}
                    name="departureCity"
                    className="p-inputtext-sm block"
                    id="inputtext"
                  />
                  <label htmlFor="inputtext">
                  Departure City<span className="text-danger">*</span>
                  </label>
                </span>
                <span style={{ color: "red" }}>
                  {" "}
                  {notCompleted.departureCity === true &&
                  arrivalInfo.dataSaved === true
                    ? "Departure City " + isRequired
                    : null}
                </span>{" "}
              </div>


              <div className="field col-md-6 mb-4">
                <span className="p-float-label">
                  <InputText
                    onBlur={handleChange}
                    type="text"
                    name="airline"
                    className="p-inputtext-sm block"
                  />
                  <label htmlFor="icon" style={{ float: "left" }}>
                    Airline
                  </label>
                </span>
              </div>

              <div className="field col-md-6 mb-4">
                <span className="p-float-label">
                  <InputText
                    onBlur={handleChange}
                    type="text"
                    name="flightNumber"
                    className="p-inputtext-sm block"
                  />
                  <label htmlFor="icon" className="align-left">
                    Flight Number
                  </label>
                </span>
              </div>

              <h5 className="text-left align-left">ADDRESS IN ETHIOPIA</h5>
              <hr />
              <div className="field col-md-6 mb-4 mt-2">
                <span className="p-float-label">
                  <Dropdown
                    name="accommodationType"
                    onChange={handleChange}
                    options={accomodationTypes}
                    value={arrivalInfo.accommodationType}
                    optionLabel="name"
                    className="p-inputtext-sm block"
                  />
                  <label htmlFor="country">
                    Accommodation Type<span className="text-danger">*</span>
                  </label>
                </span>
                <span style={{ color: "red" }}>
                  {" "}
                  {notCompleted.accommodationType === true &&
                  arrivalInfo.dataSaved === true
                    ? "Accommodation Type " + isRequired
                    : null}
                </span>{" "}
              </div>
              <div className="field col-md-6 mb-4 mt-2">
                <span className="p-float-label">
                  <InputText
                    onBlur={handleChange}
                    name="accommodationName"
                    className="p-inputtext-sm block"
                    id="inputtext"
                  />
                  <label htmlFor="country">
                    Accommodation Name<span className="text-danger">*</span>
                  </label>
                </span>
                <span style={{ color: "red" }}>
                  {" "}
                  {notCompleted.accommodationName === true &&
                  arrivalInfo.dataSaved === true
                    ? "Accommodation Name " + isRequired
                    : null}
                </span>{" "}
              </div>
              <div className="field col-md-6 mb-4">
                <span className="p-float-label">
                  <InputText
                    onBlur={handleChange}
                    name="accommodationCity"
                    className="p-inputtext-sm block"
                    id="inputtext"
                  />
                  <label htmlFor="inputtext">
                    Accommodation City<span className="text-danger">*</span>
                  </label>
                </span>
                <span style={{ color: "red" }}>
                  {" "}
                  {notCompleted.accommodationCity === true &&
                  arrivalInfo.dataSaved === true
                    ? "Accommodation City " + isRequired
                    : null}
                </span>{" "}
              </div>
              <div className="field col-md-6 mb-4">
                <span className="p-float-label">
                  <InputText
                    onBlur={handleChange}
                    name="accommodationStreetAddress"
                    className="p-inputtext-sm block"
                    id="inputtext"
                  />
                  <label htmlFor="inputtext">
                    Accommodation Street Address
                    <span className="text-danger">*</span>
                  </label>
                </span>
                <span style={{ color: "red" }}>
                  {" "}
                  {notCompleted.accommodationStreetAddress === true &&
                  arrivalInfo.dataSaved === true
                    ? "Accommodation Street Address " + isRequired
                    : null}
                </span>{" "}
              </div>
              <div className="field col-md-6 mb-4">
                <label htmlFor="inputtext">
                <span style={{color:"#737373",fontSize:"13px"}}> Accommodation Telephone</span>
                  <span className="text-danger">*</span>
                </label>{" "}
                <Input
                  country="ET"
                  international
                  withCountryCallingCode
                  value={phoneValue}
                  className="form-control"
                  maxLength={17}
                  onChange={(e) => {
                    changePhone(e);
                  }}
                style={{color:"#595959",fontSize:"14px"}}
                />
                <span style={{ color: "red" }}>
                  {" "}
                  {(phoneIsValid === false)
                    ? "Accommodation Telephone is required"
                    : null}
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
});
export default ArrivalInformation;
