import React, { useState, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import headerImage from "./../.././../assets/Images/evisa-logo.png";
import headerMobileImage from "./../.././../assets/Images/evisa-logo-mobile.png";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import ListIcon from "@mui/icons-material/List";
import Divider from "@mui/material/Divider";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import AppsIcon from "@mui/icons-material/Apps";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PasswordIcon from "@mui/icons-material/Password";
import LogoutIcon from "@mui/icons-material/Logout";
import { ConfirmDialog } from "primereact/confirmdialog";
import CompanyService from "../../../service/company/CompanyService";
import SnipperModal from "../../common/modal/snipperModal";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import "./../../../assets/css/header.css";

const companyData = localStorage.getItem("companyLoginResponse");

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [visible, setVisible] = useState(false);
  let [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useRef(null);
  const companyService = new CompanyService();

  const accept = () => {
    let profile = JSON.parse(localStorage.companyLoginResponse);
    setVisible(false);
    const requestBody = {
      companyProfileId: parseInt(profile.id),
    };
    setLoading(true);
    companyService
      .applyForCompanyProfile(requestBody)
      .then((res) => {
        setLoading(false);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.message,
          life: 3000,
        });
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        toast.current.show({
          severity: "error",
          summary: "Error occured",
          detail: err?.response.data.errors[0],
          life: 3000,
        });
      });
    // toast.current.show({
    //   severity: "info",
    //   summary: "Confirmed",
    //   detail: "You have accepted",
    //   life: 3000,
    // });
  };
  const reject = () => {
    toast.current.show({
      severity: "warn",
      summary: "Rejected",
      detail: "You have rejected",
      life: 3000,
    });
  };
//   const showError = () => {
//     toast.current.show({severity:'error', summary: 'Error', detail:'Message Content', life: 3000});
// }
// if(!visible)
// showError()

  const confirmPosition = (position) => {
    setVisible(true);
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLogout = () => {
    localStorage.removeItem("companyLoginResponse");
    localStorage.removeItem("isUserLogedIn");
    localStorage.removeItem("userLoginRes");
    localStorage.removeItem("companyProfileRes");
    localStorage.removeItem("selectedmenu");
    navigate("/");
    window.location.reload();
  };
  return (
    <>
    <Toast ref={toast} />
    <div className="container-fluid bg-light px-5">
    <nav class="navbar navbar-expand-lg navbar-light bg-light row">

<div className=" col-md-12 col-lg-4 col-xl-4 col-xxl-3 m-0 p-0">
<Link className="navbar-brand evisa-immigration-logo align-left" to="/">
            <img className="mx-5 desktop-image" src={headerImage} alt="Immigration logo" width="299px" />
            <img className="mobile-image d-none" src={headerMobileImage} alt="logo" width="190px" />
          </Link>
 <button className="navbar-toggler mt-2 evisa-nav-button" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation" 
 style={{float:"right"}}>
  <span class="navbar-toggler-icon"></span>
</button>
</div>
<div className="col-lg-6 col-xl-6 col-xxl-7">
<div class="collapse navbar-collapse" id="navbarNavDropdown">
  <ul class="navbar-nav">

  <li className="nav-item align-left" style={{marginRight:"8px"}}>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active nav-link" : "nav-link"
                  }
                  to="/"
                >
                  <span className="text-dark visa-nav-item">Home</span>
                </NavLink>
              </li>
              <li
                className="evisa-nav-dropdown-visa nav-item align-left"
                style={{ position: "relative",marginRight:"20px" }}
              >
                <p className="text-dark nav-link dropdown-toggle mb-2 mt-2 p-0 "> Visa  </p>
                <ul class="dropdown">
                  <li>
                    <NavLink to="/visa"><span className="text-dark">Apply Visa</span></NavLink>
                  </li>
                  <li>
                    <NavLink to="/visa-extension"><span className="text-dark">Extend Visa</span></NavLink>
                  </li>
                </ul>
              </li>

                <li className="nav-item align-left">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active nav-link" : "nav-link"
                  }
                  to="/status"
                >
                  <span className="text-dark visa-nav-item">Status</span>
                </NavLink>
              </li>

              <li className="nav-item align-left" style={{marginRight:"3px"}}>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active nav-link" : "nav-link"
                  }
                  to="/information"
                >
                  <span className="text-dark visa-nav-item">
                    Information
                  </span>
                </NavLink>
              </li>
              <li
                    className="evisa-nav-dropdown"
                    style={{ position: "relative" }}
                  >
                    <p className="text-dark dropdown-toggle mb-2 mt-2 p-0">Visit Ethiopia</p>
                    <ul class="dropdown">
                      <li>
                        <NavLink to="/historicalplace"><span className="text-dark">Historical Place</span></NavLink>
                      </li>
                      <li>
                        <NavLink to="/ethiopianfestival"><span className="text-dark">Ethiopian Festivals</span></NavLink>
                      </li>
                    </ul>
</li>

            {companyData === null ? (
              <li className="nav-item align-left mobile-link-disp d-none">
              <Link className="nav-link" to="/login">
                      <span className="text-dark"> Partner Login</span>
                    </Link>
                  </li>
                ) : (
                  <>
              <li className="nav-item align-left mobile-link-disp d-none">
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? "active nav-link" : "nav-link"
                        }
                        to="/dashboard"
                      >
                        <span className="text-dark visa-nav-item">
                          Dashboard
                        </span>
                      </NavLink>
                    </li>
                    <li className="nav-item align-left mobile-link-disp d-none">
                      <Button
                        id="demo-customized-button"
                        variant="outlined"
                        onClick={handleLogout}
                      >
                        <span className="text-dark">Logout</span>
                      </Button>
                    </li>
                  </>
                )}

  </ul>
</div>
</div>
<div className="col-md-2 col-xl-2 col-xxl-2">
<ul class="navbar-nav mobile-link-comp">
  {companyData === null ? (
              <li className="nav-item align-left">
              <Link className="nav-link" to="/login">
                      <span className="text-dark"> Partner Login</span>
                    </Link>
                  </li>
                ) : (
                  <>
              <li className="nav-item align-left">
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? "active nav-link" : "nav-link"
                        }
                        to="/dashboard"
                      >
                        <span className="text-dark visa-nav-item">
                          Dashboard
                        </span>
                      </NavLink>
                    </li>
                    <li className="nav-item align-left">
                      <Button
                        id="demo-customized-button"
                        variant="outlined"
                        onClick={handleLogout}
                      >
                        <span className="text-dark">Logout</span>
                      </Button>
                    </li>
                  </>
                )}
  </ul>
</div>
</nav>
    </div>

      {loading ? <SnipperModal /> : <> {null}</>}
      <Toast ref={toast} />

    </>
  );
};

export default Header;
