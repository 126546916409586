import React from "react";

import "./ErrorBoundary.css";

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, errorInfo);
        // console.log(error, errorInfo)
    }

    logout() {
        // console.log("If the error persist remove the cache and the logout the user")
        localStorage.removeItem("selectedmenu");
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <div id="error-page">
                <div class="error-page">
                    <div class="error-page-404">
                        <h1>Oops!</h1>
                        <h2>Something went Wrong</h2>
                    </div>
                    <a href="/" onClick={() => this.logout()}><a>Go TO Homepage</a></a>
                </div>
            </div>;
        }

        return this.props.children;
    }
}