
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import VisaService from "../../../service/visa/VisaService";
import { InputNumber } from 'primereact/inputnumber';
import { VisaPayment } from '../VisaPayment';
import SnipperModal from "../../../components/common/modal/snipperModal";
import { MDBCol, MDBRow, MDBBadge, MDBTypography } from "mdbreact";
import { useNavigate } from "react-router-dom";
import "../../../components/InfoDetails/evisaStyle.css"
export const VisaExtensionApply = (props) => {
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [extensionResponse, setExtensionResponse] = useState({});
  const [errorResponse, setErrorResponse] = useState({});
  const [files, setfiles] = useState({});
  const [loading, setLoading] = useState(false);
  const inputs = [];
  const [requiredAttachements, setRequiredAttachements] = useState([]);
  const [attachmentNames, setattachmentNames] = useState([]);
  const [requiredFile, setrequiredFile] = useState("");
  const navigate = useNavigate();
  let fileError = [];
  const [errorMessage, seterrorMessage] = useState([]);
  useEffect(() => {
    if (props?.visaDetail?.attachementType && props?.visaDetail?.attachementType.length > 0) {
      setRequiredAttachements(props?.visaDetail?.attachementType);
    }
    else setRequiredAttachements([]);
  }, []);
  const onChange = (e) => {
    const { id, value } = e.target;

    const fileValue = e.target.files[0];
    setfiles((prevState) => ({
      ...prevState,
      [id]: fileValue,
    }));
  };
  for (let i = 0; i < requiredAttachements.length; i++) {
    inputs.push(
      <div class="col-md-5 mx-4 my-2" id="attachmentmargin">
        <div class="col-md-12 passport-text-right">
          <MDBBadge color="primary smallPadding ">
            {" "}
            {attachmentNames[i]}{" "}
          </MDBBadge>
        </div>
        <div class="col-md-12">
          <div className="input-group">
            <div className="custom-file row">
              <label
                className="custom-file-label col-md-12 text-left"
                htmlFor="inputGroupFile01"
              >
                {requiredAttachements[i] ? (<strong>{
                  requiredAttachements[i].name} </strong>
                ) : (
                  <div class="smallFont"> <strong>Choose file </strong></div>
                )}
              </label>

              <input
                name={requiredAttachements[i].name}
                type="file"
                id={requiredAttachements[i].id}
                className="custom-file-input form-control col-md-12"
                aria-describedby="inputGroupFileAddon01"
                accept="image/png,image/gif,image/jpeg,image/jpg,application/pdf" //application/pdf
                onChange={(e) => onChange(e)}
                required
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  const validateAttachments = (files) => {
    console.log(files);
    var fileCount = 0;
    var i;

    for (i in files) {
      if (files.hasOwnProperty(i)) {
        fileCount++;
      }
    }
    if (fileCount < requiredAttachements.length) {
      fileError.push(`You Should have to Choose All`);
    } else {
      for (var key in files) {
        if (
          !files[key].name.match(
            /\.(jpg|jpeg|png||pdf|JPG|JPEG|PNG||PDF)$/
          )
        ) {
          fileError.push(`  
                ${files[key].name ? (
              files[key].name +
              " is Invalid format, Please upload correct file type!"
            ) : (
              <div class="smallFont">
                Invalid format, Please upload correct file type!
              </div>
            )
            }`);
        }

        if (files[key].size > 2000000) {
          fileError.push(`  
            ${
              files[key].name ? (
                files[key].name +
                " is Invalid size, Please upload file size of maximum 2MB!"
              ) : (
                <div class="smallFont">
                  Invalid size, Please upload file size of maximum 2M!
                </div>
              )
            }`);
        }
      }
    }

    seterrorMessage(fileError);

    if (fileError.length > 0) {
      return false;
    }
    return true;
  };
  const formik = useFormik({
    initialValues: {
      noOfExtendedDays: null,
      VisaRequestId: props?.visaDetail.visaRequest.id
    },
    validate: (data) => {
      let errors = {};

      if (data.noOfExtendedDays === 0) {
        errors.noOfExtendedDays = 'Number Of Extended Days is required.';
      }
      if (data.noOfExtendedDays === '') {
        errors.noOfExtendedDays = 'Number Of Extended Days is required.';
      }
      if (data.noOfExtendedDays === null) {
        errors.noOfExtendedDays = 'Number Of Extended Days is required.';
      }
      // if (data.noOfExtendedDays > 0) {
      //   if (props?.visaDetail.numberOfExtensions === 0) {
      //     if (data.noOfExtendedDays > 30 || data.noOfExtendedDays < 1) {
      //       errors.noOfExtendedDays = 'The number Of Extended Days should be at least 1 and a maximum of 30 days.';
      //     }
      //   }
      //   if (props?.visaDetail.numberOfExtensions === 1) {
      //     if (data.noOfExtendedDays > 15 || data.noOfExtendedDays < 1) {
      //       errors.noOfExtendedDays = 'The number Of Extended Days should be at least 1 and a maximum of 15 days.';
      //     }
      //   }
      //   if (props?.visaDetail.numberOfExtensions === 2) {
      //     if (data.noOfExtendedDays > 10 || data.noOfExtendedDays < 1) {
      //       errors.noOfExtendedDays = 'The number Of Extended Days should be at least 1 and a maximum of 10 days.';
      //     }
      //   }
      // }
      return errors;
    },
    onSubmit: (data) => {

      setLoading(true)
      const formData = new FormData();

      const isValid = validateAttachments(files);
      if (isValid) {
        for (var key in files) {
          formData.append(key, files[key]);
        }
      }
      else {
        setLoading(false);
        return
      }

      formData.append("VisaRequestId", data.VisaRequestId);
      formData.append("noOfExtendedDays", data.noOfExtendedDays);


      const visaDataService = new VisaService();
      visaDataService
        .createVisaExtension(formData)
        .then((res) => {

          console.log(res.data)
          // setExtensionResponse(res.data);
          setLoading(false)
          navigate('/visa-payment?order=' + res?.data.orderCode + "&val=" + res?.data.visaExtensionFee);
          // setSubmitSuccess(true);
          formik.resetForm();

        })
        .catch((err) => {

          console.log(err.response.data)
          setErrorResponse(err.response.data.errors[0]);
          setSubmitError(true);
          setLoading(false)
        })
    }
  });

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };

  return (
    <div>
      {loading ? <SnipperModal /> : <> {null}</>}

      {!submitSuccess ? <div className='row'>
        <div className='col-md-3'></div>
        <div className='col-md-6' style={{ marginBottom: "3rem" }}>

            <div className="flex justify-content-center">
              <div className="card shadow">
                <div className='evisa-color div-height text-center'> <h5 className="text-center mt-4 ">VISA EXTENSION APPLICATION</h5>  </div>

                <h5 className='text-danger'> {submitError ? errorResponse : null}</h5>
                <form onSubmit={formik.handleSubmit} className="p-fluid row p-1">
                  <div className="field  col-md-6 mb-4">
                    <label htmlFor="firstName" className="align-left">FirstName</label>
                    <span className="p-float-label">
                      <InputText className='p-inputtext-sm block' value={props.visaDetail?.visaRequest.firstName} readOnly />
                    </span>
                  </div>
                  <div className="field  col-md-6 mb-4">
                    <label htmlFor="surName" className="align-left">Surname</label>
                    <span className="p-float-label">
                      <InputText className='p-inputtext-sm block' value={props.visaDetail?.visaRequest.surName} readOnly />
                    </span>
                  </div>
                  <div className="field  col-md-6 mb-4">
                    <label htmlFor="passportNumber" className="align-left">Passport Number</label>
                    <span className="p-float-label">
                      <InputText className='p-inputtext-sm block' value={props.visaDetail?.visaRequest.passportNumber} readOnly />
                    </span>
                  </div>
                  <div className="field  col-md-6 mb-4">
                    <label htmlFor="passportExpireDate" className="align-left">Passport Expire Date</label>
                    <span className="p-float-label">
                      <InputText className='p-inputtext-sm block' value={new Date(props.visaDetail?.visaRequest.passportExpireDate).toDateString()} readOnly />
                    </span>
                  </div>
                  <div className="field  col-md-6 mb-4">
                    <label htmlFor="visaType" className="align-left">Visa Type</label>
                    <span className="p-float-label">
                      <InputText className='p-inputtext-sm block' value={props.visaDetail?.visaRequest.visaTypeValidity.visaType?.name} readOnly />
                    </span>
                  </div>
                  <div className="field  col-md-6 mb-4">
                    <label htmlFor="visaValidity" className="align-left">Visa Validity</label>
                    <span className="p-float-label">
                      <InputText className='p-inputtext-sm block' value={props.visaDetail?.visaRequest.visaTypeValidity.visaValidity?.name} readOnly />
                    </span>
                  </div>
                  <div className="field mb-4">
                    <label htmlFor="noOfExtendedDays" className='align-left'>Number of Days to Extend*</label>

                    <span className="p-float-label">
                      <InputNumber id="noOfExtendedDays" name="noOfExtendedDays" value={formik.values.noOfExtendedDays} onValueChange={formik.handleChange}
                        autoFocus className={classNames({ 'p-inputtext-sm block p-invalid': isFormFieldValid('p-inputtext-sm block noOfExtendedDays') })} useGrouping={false} />
                    </span>
                    {getFormErrorMessage('noOfExtendedDays')}
                  </div>
                  <div className="row">
                    {inputs.length > 0 ? <h4>Attachements</h4> : <></>}
                    {inputs}
                  </div>

                  <div className="field mb-4">
                    <Button type="submit" label="Submit" className='evisa-button' />
                  </div>
                </form>
            </div>
          </div>
        </div>
        <div className='col-md-3 mb-4'></div>
      </div> : <VisaPayment paymentDetail={extensionResponse} />}
    </div>
  );
}
