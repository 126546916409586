
import { combineReducers } from "redux";
import { visaCategoryReducer,arrivalInformationReducer,personalInformationReducer,passportInformationReducer,supportingDocReducer } from "./VisaReducer";

const Reducers = combineReducers({
    visaCategoryInformation:visaCategoryReducer,
    arrivalInformation:arrivalInformationReducer,
    personalInformation:personalInformationReducer,
    passportInformation:passportInformationReducer,
    supportingDocInformation:supportingDocReducer,
});


export default Reducers