import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import VisaService from "./../../../service/visa/VisaService";
import { useParams } from "react-router-dom";
import { MDBCol, MDBRow, MDBBadge, MDBTypography } from "mdbreact";
import SnipperModal from "../../../components/common/modal/snipperModal";
import { useNavigate } from "react-router-dom";
import { Descriptions } from "antd";

const ExtensionEdit = () => {
  const [showMessage, setShowMessage] = useState(false);
  const [isError, setIsError] = useState(false);
  const [updateErrorMessage, setUpdateErrorMessage] = useState("");
  const [extensionData, setExtensionData] = useState({});
  const [extensionId, setExtensionId] = useState(0);
  const [requiredAttachements, setRequiredAttachements] = useState([]);
  const [existingAttachemnts, setExistingAttachemnts] = useState([]);
  const inputs = [];
  const [files, setfiles] = useState({});
  const [errorMessage, seterrorMessage] = useState([]);
  const [serverErrorMessage, setserverErrorMessage] = useState("");
  let fileError = [];
  const [loading, setloading] = useState(true);
  const visaService = new VisaService();
  const navigate = useNavigate();
  const searchParams = useParams();
  const params = new URLSearchParams(window.location.search);
  const [visaGetParam, setVisaGetParam] = useState({
    referenceNumber: params.get("rfn"),
    token: params.get("tkn"),
  });

  const getExtension = async () => {
    setloading(true);
    await visaService
      .getExtensionDetailForUpdate(visaGetParam)
      .then((resp) => {
        console.log(resp);
        setRequiredAttachements(resp.data?.attachementType);
        setExistingAttachemnts(resp.data?.visaExtension.attachments);
        setExtensionData(resp.data?.visaExtension);
        setExtensionId(resp.data?.visaExtensionRequestId);
        setloading(false);
      })
      .catch((error) => {
        setloading(false);
      });
  };
  useEffect(() => {
    if (searchParams && searchParams !== null) {
      getExtension();
    }
  }, [searchParams]);

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  const validate = (files) => {
    var fileCount = 0;
    var i;
    for (i in files) {
      if (files.hasOwnProperty(i)) {
        fileCount++;
      }
    }
    if (fileCount === 0) {
      fileError.push(`You Should have to Choose All`);
    } else {
      for (var key in files) {
        for (var attachement in requiredAttachements) {
          if (parseInt(key) === requiredAttachements[attachement].id) {
            if (requiredAttachements[attachement].isImage) {
              if (!files[key].type.match(/\/(jpg|jpeg|png||JPG|JPEG|PNG)$/)) {
                fileError.push(`  
                      ${
                        files[key].name ? (
                          files[key].name +
                          " is Invalid format, Please upload correct file type!"
                        ) : (
                          <div class="smallFont">
                            Invalid format, Please upload correct file type!
                          </div>
                        )
                      }`);
              }
            } else {
              if (
                !files[key].type.match(
                  /\/(jpg|jpeg|png||pdf|JPG|JPEG|PNG||PDF)$/
                )
              ) {
                fileError.push(`  
                      ${
                        files[key].name ? (
                          files[key].name +
                          " is Invalid format, Please upload correct file type!"
                        ) : (
                          <div class="smallFont">
                            Invalid format, Please upload correct file type!
                          </div>
                        )
                      }`);
              }
            }
          }
        }

        if (files[key].size > 2000000) {
          fileError.push(`  
            ${
              files[key].name ? (
                files[key].name +
                " is Invalid size, Please upload file size of maximum 2MB!"
              ) : (
                <div class="smallFont">
                  Invalid size, Please upload file size of maximum 2M!
                </div>
              )
            }`);
        }
      }
    }

    seterrorMessage(fileError);
    if (fileError.length > 0) {
      return false;
    }
    return true;
  };

  const redirectToHome = () => {
    navigate("/");
  };

  const dialogFooter = (
    <div className="flex justify-content-center">
      <Button
        label="OK"
        className="p-button-text"
        autoFocus
        onClick={redirectToHome}
      />
    </div>
  );
  const onChange = (e) => {
    const { id, value } = e.target;
    const fileValue = e.target.files[0];
    setfiles((prevState) => ({
      ...prevState,
      [id]: fileValue,
    }));
  };
  for (let i = 0; i < requiredAttachements?.length; i++) {
    inputs.push(
      <div class="row p-2" id="attachmentmargin">
        <div class="col-md-2 text-left">
          
        <label
                className="custom-file-label"
                htmlFor="inputGroupFile01"
              >
                {requiredAttachements[i] ? (
                  <strong>{requiredAttachements[i].name} </strong>
                ) : (
                  <div class="smallFont">
                    {" "}
                    <strong>Choose file </strong>
                  </div>
                )}
              </label>
        </div>
          <div className="col-md-2">
            
          {existingAttachemnts.length > 0 ? (
                <Descriptions>
                  {existingAttachemnts.map((value) => {
                    return (
                      <>
                        {value?.attachmentTypeId ===
                        requiredAttachements[i].id ? (
                          <Descriptions.Item key={value?.attachmentTypeId}>
                            <a href={value?.attachmentPath}>Download</a>
                          </Descriptions.Item>
                        ) : null}
                      </>
                    );
                  })}
                </Descriptions>
              ) : null}
          </div>
        <div class="col-md-8">
          <div className="input-group ml-5">
            <div className="custom-file row">

              <input
                name={requiredAttachements[i].name}
                type="file"
                id={requiredAttachements[i].id}
                className="custom-file-input col-md-12"
                aria-describedby="inputGroupFileAddon01"
                accept="image/png,image/gif,image/jpeg,image/jpg,application/pdf" //application/pdf
                onChange={(e) => onChange(e)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
  const submit = async (e) => {
    e.preventDefault();
    fileError = [];
    const isValid = validate(files);
    if (isValid) {
      setloading(true);
      let formData = new FormData();

      formData.append("VisaRequestId", extensionId);
      for (var key in files) {
        formData.append(key, files[key]);
      }
      //return post(url, formData, config);
      visaService
        .updateExtensionRequiredAttachment(formData)
        .then((res) => {

          setloading(false);
          setShowMessage(true);
        })
        .catch((err) => {
          setUpdateErrorMessage(err.response.data.errors[0]);
          setIsError(true);
          setloading(false);
        });
    }
  };
  return (
    <div className="form-demo m-2">
      {loading ? <SnipperModal /> : <> {null}</>}

      <Dialog
        visible={showMessage}
        onHide={() => setShowMessage(false)}
        position="top"
        footer={dialogFooter}
        showHeader={false}
        breakpoints={{ "960px": "80vw" }}
        style={{ width: "30vw" }}
      >
        <div className="flex justify-content-center flex-column pt-6 px-3">
          <i
            className="pi pi-check-circle"
            style={{ fontSize: "5rem", color: "var(--green-500)" }}
          ></i>
          <h5>Vise attachments is updated Successfully!</h5>

          <p style={{ lineHeight: 1.5, textIndent: "1rem" }}>
            Your visa request is updated; we will continue the visa approval
            process.
          </p>
        </div>
      </Dialog>

      <div className="row"> 
<div className="col-md-3">
  
</div>
<div className="col-md-6">
<div className="card my-3">
          <br/>
          <h5 className="text-left ml-5" style={{marginLeft:"30px"}}>UPDATE VISA EXTENSION REQUEST</h5>
          <hr style={{maxWidth:"95%", marginLeft:"15px",marginTop:"0px"}} />
        
              <div className="align-left m-3">
              <p className="m-2 p-0">
                <b>Visa Type: </b>
                <span>
                  {extensionData?.visaRequest?.visaTypeValidity?.name}{" "}
                </span>
                </p>
                <p className="m-2 p-0">
                <b>Request Date: </b>
                <span>
                  {extensionData?.visaRequest?.surName
                    ? formatDate(extensionData?.startDate)
                    : ""}{" "}
                </span>
                </p>
                <p className="m-2 p-0">
                                <b>Reference Number: </b>
                <span> {extensionData?.referenceNumber} </span>
                </p>
                <p className="m-2 p-0">
              <b>Passport Number: </b>
                <span> {extensionData?.visaRequest?.passportNumber} </span>
                </p>
                <p className="m-2 p-0">
                <b>First Name: </b>
                <span> {extensionData?.visaRequest?.firstName} </span>
                </p>
                <p className="m-2 p-0">
                <b> Surname: </b>
                <span>{extensionData?.visaRequest?.surName}</span>
                </p>  

                <p className="m-2 p-0">
                <b>Correction Message: </b>
                <span>
                 <b className="text-danger"> {extensionData?.approvalRemark}{" "}</b>
                </span>
                </p>

            <div className="col-md-3"></div>
          </div>
          <div className="text-left m-0">
            {isError ? (
              <p className="text-danger">
                <b>Error:&nbsp;</b>
                {updateErrorMessage}
              </p>
            ) : null}

            <ul style={{ listStyleType: "square", textAlign: "left" }}>
              <h4 className="m-3 text-left">Note info:</h4>
              <li>Size of the image/document should be less than 2MB.</li>
              <li>
                Photo and Passport Copy should be only in Image file type of
                JPEG, JPG, PNG format.
              </li>
              <li>
                Allowed Images/documents file type extensions are JPEG, JPG, PNG
                and PDF format only.
              </li>
              <li>The image/document should be clear and readable.</li>
              <li>
                An image/document with blurred or unclean background is not
                acceptable.
              </li>
            </ul>
          </div>

          <div class="container">
            <form onSubmit={(e) => submit(e)}>
              <div class="row ">
                <div class="col-md-2"></div>
                <div class="col-md-10" id="attachmentmargin">
                  {errorMessage.length
                    ? errorMessage.map((error) => (
                        <div
                          class="alert alert-danger text-center"
                          role="alert"
                        >
                          {error}
                        </div>
                      ))
                    : null}

                  {serverErrorMessage ? (
                    <h5 class="text-danger text-center">
                      {serverErrorMessage}
                    </h5>
                  ) : null}
                </div>
              </div>

              {inputs}
              <MDBRow>
                <MDBCol md="12 m-3"></MDBCol>
                <MDBCol>
                  <button className="btn btn-primary text-right" type="submit">
                    Update
                  </button>
                </MDBCol>
              </MDBRow>
              <br/>
            </form>
          </div>
        </div>
</div>
<div className="col-md-3"></div>
      </div>
    </div>
  );
};
export default ExtensionEdit;
