import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import VisaService from "../../service/visa/VisaService";
import { StatusView } from "./StatusView";
import {VisaEdit} from "../visa/VisaEdit";
import CompleteVisa from "../visa/CompleteVisa";
import SnipperModal from "../../components/common/modal/snipperModal";
import CAPTCHA from "react-google-recaptcha";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

export const StatusIndex = () => {
  const [showMessage, setShowMessage] = useState(false);
  const [formData, setFormData] = useState({});
  const [visaDetail, setVisaDetail] = useState({});
  const [inCompleteVisa, setCompleteVisa] = useState({});

  const [isgetSucces, setIsgetSucces] = useState(false);
  const [isincomplete, setIsincomplete] = useState(false);
  let [loading, setLoading] = useState(false);
  const [errorResponse, setErrorResponse] = useState("");
  const [submitError, setSubmitError] = useState(false);
  const [files, setfiles] = useState({});
  const [recaptchaSelected, setRecaptchaSelected] = useState(true);
  const captchaRef = useRef(null)
  useEffect(() => {}, []);
  const formik = useFormik({
    initialValues: {
      passportNumber: "",
      referenceNumber: "",
    },
    validate: (data) => {
      let errors = {};

      if (!data.passportNumber) {
        errors.passportNumber = "Passport number is required.";
      }
      if (!data.referenceNumber) {
        errors.referenceNumber = "Reference number is required.";
      }
      return errors;
    },
    onSubmit: (data) => {
      // const token = captchaRef.current.getValue();
      // if(token!==""){
      //   setRecaptchaSelected(true);
      setLoading(true);
      const visaDataService = new VisaService();
      debugger;
      visaDataService
        .getVisaForStatus(data)
        .then((res) => {
          setVisaDetail(res.data);
          
          setIsgetSucces(true);
          console.log("Visa data="+ res.data);
          setCompleteVisa( res.data);

          if(res.data.visaStatusDescription=="Incompelete")
          {
            setIsincomplete(true);
          }
          formik.resetForm();
          setLoading(false);
        })
        .catch((err) => {
          
          setErrorResponse(err.response.data?.errors[0]);
          setSubmitError(true);
          setLoading(false);
        });
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  const dialogFooter = (
    <div className="flex justify-content-center">
      <Button
        label="OK"
        className="p-button-text"
        autoFocus
        onClick={() => setShowMessage(false)}
      />
    </div>
  );
  return (
    <div className="my-3">
      {loading ? <SnipperModal /> : <> {null}</>}

      {
      isgetSucces ? (
        <StatusView visaDetail={visaDetail} />
      ):  
      (
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4">
              <div className="flex justify-content-center">
                <div className="card">
                  <div
                    className="p-4 text-white"
                    style={{ backgroundColor: "#004d99" }}
                  >
                    <h5 className="text-center mt-2">
                      <i
                        className="pi pi-check-circle mx-4"
                        style={{ fontSize: "1em", fontWeight: "bold" }}
                      ></i>
                      <b>Check Your Visa status</b>
                    </h5>
                  </div>
                  <h5 className="text-danger">
                    {" "}
                    {submitError ? errorResponse : null}
                  </h5>
                  <br/>
                  <form onSubmit={formik.handleSubmit} className="p-fluid">
                    <div className="field mb-4 mx-2">
                      <span className="p-float-label">
                        <InputText
                          id="passportNumber"
                          name="passportNumber"
                          value={formik.values.passportNumber}
                          onChange={formik.handleChange}
                          autoFocus
                          className={classNames({
                            "p-invalid": isFormFieldValid("passportNumber"),
                          })}
                        />
                        <label
                          htmlFor="passportNumber"
                          className={classNames({
                            "p-error": isFormFieldValid("passportNumber"),
                          })}
                        >
                          Passport Number*
                        </label>
                      </span>
                      {getFormErrorMessage("passportNumber")}
                    </div>
                    <div className="field mb-4 mx-2">
                      <span className="p-float-label">
                        <InputText
                          id="referenceNumber"
                          name="referenceNumber"
                          value={formik.values.referenceNumber}
                          onChange={formik.handleChange}
                          autoFocus
                          className={classNames({
                            "p-invalid": isFormFieldValid("referenceNumber"),
                          })}
                        />
                        <label
                          htmlFor="referenceNumber"
                          className={classNames({
                            "p-error": isFormFieldValid("referenceNumber"),
                          })}
                        >
                          Reference Code*
                        </label>
                      </span>
                      {getFormErrorMessage("referenceNumber")}
                    </div>
                    {/* <div className='mx-2 mb-2'>
                       <CAPTCHA
                    sitekey={window['env']['EVISA_GOOGLERC_SITE_KEY']} 
                    ref={captchaRef}
                    
                    />        {recaptchaSelected?null:<span className='text-danger'>Please select the recaptcha!</span>} 
                       </div> */}
                    <div className="field mb-4 mx-2">
                      <Button
                        type="submit"
                        label="Submit"
                        className="p-button-info"
                      />
                    </div>
                  </form>
                </div>
            </div>
          </div>
          <div className="col-md-4"></div>
        </div>
      )}
    </div>
  );
};
