import React, { useEffect, useState,useRef } from "react";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import VisaService from "../../../service/visa/VisaService";
import { StatusView } from "./../../status/StatusView";
import SnipperModal from "../../../components/common/modal/snipperModal";
import { useNavigate } from "react-router-dom";
import "./style.css"
import { Card } from 'primereact/card';
import CAPTCHA from "react-google-recaptcha";

export const ContinuePayment = () => {
  const [showMessage, setShowMessage] = useState(false);
  const [formData, setFormData] = useState({});
  const [visaDetail, setVisaDetail] = useState({});
  const [isgetSucces, setIsgetSucces] = useState(false);
  let [loading, setLoading] = useState(false);
  const [errorResponse, setErrorResponse] = useState("");
  const [submitError, setSubmitError] = useState(false);
  const [files, setfiles] = useState({});
  const navigate = useNavigate();
  const [recaptchaSelected, setRecaptchaSelected] = useState(true);
  const captchaRef = useRef(null)

  useEffect(() => {}, []);
  const formik = useFormik({
    initialValues: {
      passportNumber: "",
      referenceNumber: "",
    },
    validate: (data) => {
      let errors = {};

      if (!data.passportNumber) {
        errors.passportNumber = "Passport number is required.";
      }
      if (!data.referenceNumber) {
        errors.referenceNumber = "Reference number is required.";
      }
      return errors;
    },
    onSubmit: (data) => {
      // const token = captchaRef.current.getValue();
      // if(token!==""){
      //   setRecaptchaSelected(true);

      setLoading(true);
      const visaDataService = new VisaService();
      visaDataService
        .getPaymentDetail(data)
        .then((res) => {
          
          setVisaDetail(res.data);
          navigate('/visa-payment?order='+res?.data.payload.orderCode+"&val="+res?.data.payload?.visaTotalFee  ) ;

          setIsgetSucces(true);
          formik.resetForm();
          setLoading(false);
        })
        .catch((err) => {
          
          setErrorResponse(err.response.data?.errors[0]);
          setSubmitError(true);
          setLoading(false);
        });
    //   }else{
    //     setRecaptchaSelected(false);

    // }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  const dialogFooter = (
    <div className="flex justify-content-center">
      <Button
        label="OK"
        className="p-button-text"
        autoFocus
        onClick={() => setShowMessage(false)}
      />
    </div>
  );
  return (
    <div className="m-3">
      {loading ? <SnipperModal /> : <> {null}</>}

      {isgetSucces ? (
        <StatusView visaDetail={visaDetail} />
      ) : (
        <div className="row">
          <div className="col-md-2 mt-4 pl-5"> 
          

          </div>
          <div className="col-md-2"></div>
          <div className="col-md-4">
              <div className="flex justify-content-center">
                <div className="card">
                  <div
                    className="p-4 text-white"
                    style={{ backgroundColor: "#004d99" }}
                  >
                    <h5 className="text-center mt-2">
                      <i
                        className="pi pi-dollar"
                        style={{ fontSize: "1em", fontWeight: "bold" }}
                      ></i>
                      <b>Continue to Payment</b>
                      
                    </h5>
                    <span > 
                  <p style={{fontSize:13, marginLeft:25}} className="text-left">
                  <i class="pi pi-info-circle" style={{fontSize:15}}></i> If you have submitted your application already but not finalized with your payment, you can continue the payment by entering passport and reference number
                  </p>
                  </span>
                  </div>
                  <h5 className="text-danger">
                    {" "}
                    {submitError ? errorResponse : null}
                  </h5>
                    
                  <form onSubmit={formik.handleSubmit} className="p-fluid">
                
                    <div className="field mb-4 mx-2">
                                       
                        
                         <span className="p-float-label">
                        <InputText
                          id="passportNumber"
                          name="passportNumber"
                          value={formik.values.passportNumber}
                          onChange={formik.handleChange}
                          autoFocus
                          className={classNames({
                            "p-invalid": isFormFieldValid("passportNumber"),
                          })}
                        />
                        <label
                          htmlFor="passportNumber"
                          className={classNames({
                            "p-error": isFormFieldValid("passportNumber"),
                          })}
                        >
                          Passport Number*
                        </label>
                      </span>
                      {getFormErrorMessage("passportNumber")}
                    </div>
                    <div className="field mb-4 mx-2">
                      <span className="p-float-label">
                        <InputText
                          id="referenceNumber"
                          name="referenceNumber"
                          value={formik.values.referenceNumber}
                          onChange={formik.handleChange}
                          autoFocus
                          className={classNames({
                            "p-invalid": isFormFieldValid("referenceNumber"),
                          })}
                        />
                        <label
                          htmlFor="referenceNumber"
                          className={classNames({
                            "p-error": isFormFieldValid("referenceNumber"),
                          })}
                        >
                          Reference Code*
                        </label>
                      </span>
                      {getFormErrorMessage("referenceNumber")}
                    </div>
                       {/* <div className='mx-2 mb-2'>
                       <CAPTCHA
                    sitekey={window['env']['EVISA_GOOGLERC_SITE_KEY']} 
                    ref={captchaRef}
                    
                    />        {recaptchaSelected?null:<span className='text-danger'>Please select the recaptcha!</span>} 
                       </div> */}
                    <div className="field mb-4 mx-2">
                      <Button
                        type="submit"
                        label="Submit"
                        className="p-button-info"
                         
                      />
                    </div>
                  </form>
                </div>
              </div>
          </div>
          <div className="col-md-4"></div>
        </div>
      )}
    </div>
  );
};
