import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import { FaExclamationTriangle } from 'react-icons/fa';
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import VisaService from "../../../service/visa/VisaService";
import { useDispatch, useSelector } from "react-redux";
import { setPassportInformation } from "../../../redux/actions/VisaActions";
import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import {  Link } from "react-router-dom"
const PassportInformation = forwardRef((props, ref) => {
  const [countries, setCountries] = useState([]);
  const [passportTypes, setPassportTypes] = useState([]);
  let minDate = new Date();
  let extendedDate = new Date(minDate.setMonth(minDate.getMonth() + 6));
  let maxDate = new Date();
  const disabledExpDate = (current) => {
    return current && current < dayjs().startOf("day").add(6,'month');
  };
  const disabledIssueDate = (current) => {
    return current && current > dayjs().startOf('day');
  };

  const [passportInfo, setPassportInfo] = useState({
    passportType: "",
    passportNumber: "",
    passportIssueDate: null,
    passportExpireDate: null,
    issuingCountry: "",
    passportIssuingAuthority: "",
    dataSaved: false,
    formCompleted: false,
  });
  const [notCompleted, setNotCompleted] = useState({
    passportType: true,
    passportNumber: true,
    passportIssueDate: true,
    passportExpireDate: true,
    issuingCountry: true,
    passportIssuingAuthority: true,
  });
  const dispatch = useDispatch();
  useImperativeHandle(ref, () => ({
    saveData() {
      setPassportInfo((prevState) => ({
        ...prevState,
        dataSaved: true,
      }));
      dispatch(setPassportInformation(passportInfo));
    },
    Validate() {
      if(passportInfo.passportIssueDate===null){
        notCompleted.passportIssueDate =true;
      }else notCompleted.passportIssueDate =false;
      if(passportInfo.passportExpireDate===null){
        notCompleted.passportExpireDate =true;
      }else notCompleted.passportExpireDate =false;
      if (
        notCompleted.passportType === true ||
        notCompleted.passportNumber === true ||
        notCompleted.passportIssueDate === true ||
        notCompleted.passportExpireDate === true ||
        notCompleted.issuingCountry === true ||
        notCompleted.passportIssuingAuthority === true
      )
        return false;
      else return true;
    },
  }));

  const isRequired = "is required!";
  useEffect(() => {
    setNotCompleted({
      passportType: passportInfo.passportType === "" ? true : false,
      passportNumber: passportInfo.passportNumber === "" ? true : false,
      issuingCountry: passportInfo.issuingCountry === "" ? true : false,
      passportIssuingAuthority:
        passportInfo.passportIssuingAuthority === "" ? true : false,
    });
    let masterDataObj=localStorage.getItem("mDataObgj");
    if(masterDataObj!==null||masterDataObj!==""){
      let masterDataParsed= JSON.parse(masterDataObj)
      setPassportTypes(masterDataParsed?.passportTypes);
      setCountries(masterDataParsed?.countries);
    }
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPassportInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (value !== "") {
      setNotCompleted((prevState) => ({
        ...prevState,

        [name]: false,
      }));
    } else {
      setNotCompleted((prevState) => ({
        ...prevState,

        [name]: true,
      }));
    }
  };
  const handleExpDateChange=(date, dateString)=>{
    setPassportInfo((prevState) => ({
      ...prevState,
      "passportExpireDate": dateString,
    }))
  }
  const handleIssueDateChange=(date, dateString)=>{
    setPassportInfo((prevState) => ({
      ...prevState,
      "passportIssueDate": dateString,
    }))
  }
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  return (
    <>
      <h4>
        <div aria-live="polite" className="p-inline-message p-component p-inline-message-info" role="alert">
          <span className="p-inline-message-icon pi pi-info-circle"></span>
          <span>If you haven't received information about your visa status, feel free to revisit this website to check your visa status and download your visa documents.
            <Link to="/status" target="_blank"  rel="noreferrer" style={{ textDecoration: 'underline' }}>Link to check your visa status.</Link>
          </span>
        </div>
      </h4>
      {/* <div>
      <h5>
        <FaExclamationTriangle style={{ color: 'red', marginRight: '8px' }} />
        <span style={{ color: 'red' }}>
          We are temporarily unable to accept MasterCard due to technical issues.
        </span>
      </h5>
    </div> */}
      <div className="card p-5">
        <div className="p-fluid row">
          <form className="p-fluid row">
          <h5 className="align-left">PASSPORT INFORMATION</h5>
              <hr />
            <div className="field col-md-6 mb-2">
              <label
                htmlFor="icon"
                style={{ float: "left" }}
                className="text-left float-left"
              >
         <span style={{color:"#737373",fontSize:"13px"}}>  Passport Type<span className="text-danger">*</span></span>
              </label>
              <br />
              <span className="p-float-label">
                <Dropdown
                  name="passportType"
                  onChange={handleChange}
                  value={passportInfo.passportType}
                  options={passportTypes}
                  className="p-inputtext-sm block"
                  optionLabel="name"
                />
              </span>
              <span style={{ color: "red" }}>
                {" "}
                {notCompleted.passportType === true &&
                passportInfo.dataSaved === true
                  ? "Passport Type " + isRequired
                  : null}
              </span>{" "}
            </div>

            <div className="field col-md-6 mb-2">
              <br/>
            <span className="p-float-label">
            <InputText
                type="text"
                name="passportNumber"
                onBlur={handleChange}
                className="p-inputtext-sm block"
              />
                  <label htmlFor="icon" className="align-left">
                  Passport Number<span className="text-danger">*</span>
                  </label>
                </span>
                <span style={{ color: "red" }}>
                {" "}
                {notCompleted.passportNumber === true &&
                passportInfo.dataSaved === true
                  ? "Passport Number " + isRequired
                  : null}
              </span>{" "}
            </div>

            <div className="field col-md-6 mb-2">
              <label htmlFor="icon" style={{ float: "left" }}>
              <span style={{color:"#737373",fontSize:"13px"}}> Passport Issue Date<span className="text-danger">*</span></span>
              </label>
              <br />
                   <DatePicker
                  name="passportExpireDate"
                  disabledDate={disabledIssueDate}
                onChange={handleIssueDateChange}
                 style={{ width: '100%',height:"40px" }}     
                />
              <span style={{ color: "red" }}>
                {" "}
                {notCompleted.passportIssueDate === true &&
                passportInfo.dataSaved === true
                  ? "Passport Issue Date " + isRequired
                  : null}
              </span>{" "}
            </div>

            <div className="field col-md-6 mb-2">
              <label htmlFor="icon" style={{ float: "left" }}>
              <span style={{color:"#737373",fontSize:"13px"}}> Passport Expiry Date<span className="text-danger">* </span></span>
                <span data-bs-toggle="tooltip" data-bs-placement="bottom" title="Select calendar after six months from now so that your passport expiry date must be after six months." style={{backgroundColor:"white",borderRadious:"10px"}}>   <i className="pi pi-info-circle text-primary" style={{'fontSize': '1.1em'}}></i></span>
              </label>
              <br />
                             
              <DatePicker
                  name="passportExpireDate"
                  disabledDate={disabledExpDate}
                onChange={handleExpDateChange}
                 style={{ width: '100%',height:"40px" }}     
                />
              <span style={{ color: "red" }}>
                {" "}
                {notCompleted.passportExpireDate === true &&
                passportInfo.dataSaved === true
                  ? "Passport Expire Date " + isRequired
                  : null}
              </span>{" "}
            </div>

            <div className="field col-md-6 mb-2">
              <label htmlFor="dropdown" style={{ float: "left" }}>
                {" "}
                <span style={{color:"#737373",fontSize:"13px"}}>Passport Issuing Country</span><span className="text-danger">*</span>
              </label>
              <br />
              <span className="p-float-label">
                <Dropdown
                  id="issuingCountry"
                  name="issuingCountry"
                  onChange={handleChange}
                  value={passportInfo.issuingCountry}
                  options={countries}
                  className="p-inputtext-sm block"
                  optionLabel="name"
                />{" "}
              </span>
              <span style={{ color: "red" }}>
                {" "}
                {notCompleted.issuingCountry === true &&
                passportInfo.dataSaved === true
                  ? "Issuing Country " + isRequired
                  : null}
              </span>{" "}
            </div>

            <div className="field col-md-6 mb-2">
              <br/>
            <span className="p-float-label">
            <InputText
                type="text"
                name="passportIssuingAuthority"
                onBlur={handleChange}
                className="p-inputtext-sm block"
              />
                  <label htmlFor="icon" className="align-left">
                  Passport Issuing Authority<span className="text-danger">*</span>
                  </label>
                </span>
                <span style={{ color: "red" }}>
                {" "}
                {notCompleted.passportIssuingAuthority === true &&
                passportInfo.dataSaved === true
                  ? "Passport Issuing Authority " + isRequired
                  : null}
              </span>{" "}
            </div>
          </form>
        </div>
      </div>
    </>
  );
});
export default PassportInformation;
